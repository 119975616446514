import { createTheme, /*adaptV4Theme*/ } from "@mui/material";

const adaptV4Theme = (v) => v;

export const colorPrimitives = {
	primary: {
		main: "#00719C",
		light: "#0F96CE",
		dark: "#17212D", 
	},
	secondary: {
		main: "#14506B",
		light: "#99D0EB",
		dark: "#12678E",
	},
	warning: {
		main: "#FFE983"
	},
	text: {
		primary: "#6B6B6B"
	}
};

export const theme = createTheme(adaptV4Theme({
  palette: {
    primary: colorPrimitives.primary,
    secondary: colorPrimitives.secondary,
		warning: colorPrimitives.warning,
		text: colorPrimitives.text,
  },
	typography: {
		fontFamily: 'NeutraText',
		h3: {
			color: colorPrimitives.primary.main,
			// NOTE: fontSize: 3rem or 48px
			fontWeight: "bold",
			marginBottom: "2.25rem",
		},
		h4: {
			color: colorPrimitives.primary.main,
			marginBottom: "0.25rem",
		},
		h5: {
			// NOTE: fontSize: 1.5rem or 24px
			color: colorPrimitives.primary.main,
			marginBottom: "1.75rem",
		},
		h6: {
			// NOTE: fontSize: 1.25rem or 20px
			color: colorPrimitives.primary.main,
			marginBottom: "1.5rem",
		},
		body1: {
			color: colorPrimitives.text.primary, 
			marginBottom: "1.5rem",
		},
		body2: {
			fontSize: "1.5rem",
			marginBottom: "1.75rem"
		}
	},
}));