import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));

const Modal = ({ showModal, closeModal, children, fullScreen, fullWidth, maxWidth }) => {
	const classes = useStyles();
	if (!maxWidth) maxWidth = "md"
	return (
		<div>
			<Dialog
				fullScreen={fullScreen}
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={showModal}
				onClose={closeModal}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle className={classes.root}>
					<IconButton
						aria-label="close"
						className={classes.closeButton}
						onClick={closeModal}
						size="large">
						<FontAwesomeIcon icon={faTimes} />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					{children}
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Modal;