import React, { useState, useContext } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { TableHead, Typography } from '@mui/material';
import FormModal from "../common/FormModal";
import useModal from "../../hooks/useModal";
import { AccountContext } from "../../AccountContext";

const useStyles = makeStyles({
	table: {
		width: "100%",
	},
	thead: {
		borderBottomColor: "#056791",
	},
	actionLink: {
		fontWeight: 600,
		fontSize: "14px",
		lineHeight: "48px",
		textTransform: "uppercase",
		textDecoration: "none",
		color: "#0071BC",
		'&:hover': {
			textDecoration: "underline",
		}
	},
});

const AccountPropertyDetailsTable = ({ rows, openEditPropertyModal }) => {
	const classes = useStyles();
	const { accountData, updateAccount } = useContext(AccountContext);

	const [currentIndex, setCurrentIndex] = useState(false);
	const [deleteHasReservations, setDeleteHasReservations] = useState(false);
	const { showModal: showDeleteModal, openModal, closeModal: closeDeleteModal } = useModal();

	const openDeleteConfirmationModal = (index) => {
		setCurrentIndex(index);
		setDeleteHasReservations(hasReservations(accountData.properties[index].uuid));
		openModal();
	}

	const deleteProperty = () => {
		const tmpAccountData = { ...accountData }
		// tmpAccountData["properties"].splice(currentIndex, 1)
		tmpAccountData.properties[currentIndex] = {
			...tmpAccountData.properties[currentIndex],
			deleted: true,
			deleteDate: (new Date()),
		}
		updateAccount(tmpAccountData);
		closeDeleteModal()
	}

	const hasReservations = (propertyID) => {
		for (let opportunity of accountData?.opportunities) {
			const components = opportunity.split('_');
			if (propertyID === components[2])return true;
		}
		return false;
	}

	return (
		<>
			<TableContainer >
				<Table aria-label="simple table" className={classes.table}>
					<TableHead scope="row">
						<TableCell className={classes.thead} align="left">Property Name</TableCell>
						<TableCell className={classes.thead} align="left">&nbsp;</TableCell>
						<TableCell className={classes.thead} align="left">&nbsp;</TableCell>
					</TableHead>
					<TableBody>
						{rows.map((row, index) => (
							<TableRow key={`${row.value}-${index}`}>
								<TableCell align="left">{row.value}</TableCell>
								<TableCell align="left"><Button size="small" onClick={() => { openEditPropertyModal(index) }} className={classes.actionLink}>Edit Property Info</Button></TableCell>
								<TableCell align="left"><Button size="small" onClick={() => { openDeleteConfirmationModal(index) }} disabled={rows.length === 1} className={classes.actionLink}>Delete Property</Button></TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<FormModal
				size={"sm"}
				title="Delete Property"
				showModal={showDeleteModal}
				closeModal={closeDeleteModal}
				submitModal={deleteProperty}
				isDisabled={deleteHasReservations}
				text={"Delete"}
			>
				<Typography variant='h4' className={classes.modalText}>Delete Property?</Typography>
				{ !deleteHasReservations && <Typography variant='body1' className={classes.modalText}>Are you sure you want to delete this property? All opportunities must be released before deleting, and this property will no longer appear in your account or be eligible for opportunity selections.</Typography> }
				{ deleteHasReservations && <Typography variant="body1" className={classes.modalText}>This property cannot be deleted because it has reserved opportunities. Please release all opportunities for this property before deleting.</Typography> }
			</FormModal>
		</>
	)
}

export default AccountPropertyDetailsTable;