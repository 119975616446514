import React from 'react';

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Image from 'material-ui-image';

import { BASE_CDN_URL } from "../../constants/api";

import formatCurrency from "../../helpers/formatCurrency";
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			// margin: theme.spacing(2),
		},
	},
	rowLabel: {
		marginBottom: 0,
	},
	detailRow: {
		marginBottom: "2rem",
	},
	iframeHolder: {
		position: "relative",
		overflow: "hidden",
		width: "100%",
		paddingTop: "56.25%",
		'& > *': {
			position: "absolute",
			top: "0",
			left: "0",
			bottom: "0",
			right: "0",
			width: "100%",
			height: "100%"
		}
	},
}));

const DetailRow = ({ label, value }) => {
	const classes = useStyles();
	// NOTE: not rendering these fields if they're falsy
	// (eg. delivery will be blank for opportunities that are not email driven)
	return (
		value ? (
			<div className={classes.detailRow}>
				<Typography variant="h6" className={classes.rowLabel}>
					{label}
				</Typography>
				<Typography>{value}</Typography>
			</div>
		) : null
	)
}

const OpportunityDetailPaper = ({
	opportunity,
	selected,
	closeModal,
	handleSelect,
	handleRemove,
	disabled,
	disableSelect,
	isMultiProperty,
	onManageClick,
	properties
}) => {
	const classes = useStyles();

	const onImgLoad = ({ target: img }) => {
		const { offsetHeight, offsetWidth } = img;
		if (offsetHeight > offsetWidth) {
			img.style.height = "80%"
		}
	}

	const cost = formatCurrency(opportunity.cost);

	return (
		<Container maxWidth="xl" className={classes.root}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={5}>
					<Typography variant="h6">Run Date: {opportunity.runDate}</Typography>
					<Typography variant="h3">{opportunity.name}</Typography>

					<DetailRow label="Audience Reach" value={opportunity.audienceReach} />
					<DetailRow label="Category" value={opportunity.Category[0].object.name} />
					<DetailRow label="Editorial Focus" value={opportunity.editorialFocus} />
					{opportunity.type !== "Supplemental" && <DetailRow label="Opportunity Seats" value={opportunity.availability} />}
					<DetailRow label="Delivery" value={opportunity.delivery} />
					<DetailRow label="Clicks" value={opportunity.clicks} />
					<DetailRow label="Impressions" value={opportunity.impressions} />
					<DetailRow label="Views" value={opportunity.views} />
					<DetailRow label="Cost" value={`${cost} per partner`} />
					<DetailRow label="Details" value={opportunity.description} />
					<Typography variant="h5">{cost}</Typography>
					{opportunity.type === "Supplemental" ? (
						opportunity?.latestReservedOpportunity?.length !== 0 || opportunity.properties !== undefined ? (
							(isMultiProperty ?
								(
									<>
										<Button
											style={{ marginTop: "17px" }}
											color="primary"
											variant="outlined"
											aria-label="complete an opportunity"
											onClick={() => {
												onManageClick(opportunity)
												closeModal();
											}}
										>
											Manage Reservations
										</Button>
									</>
								) : (
									<>
										<Button
											color="primary"
											variant="outlined"
											aria-label="remove an opportunity"
											onClick={() => {
												handleRemove()
											}}
										>
											Release Reservation
										</Button>
										<Button
											style={{ marginLeft: ".5rem" }}
											color="primary"
											variant="outlined"
											aria-label="remove an opportunity"
											onClick={() => {
												if (opportunity.latestReservedOpportunity.length > 0) {
													handleSelect(opportunity.latestReservedOpportunity[0].properties.uuid, opportunity.latestReservedOpportunity[0].properties.propertyUuid, true)
												} else {
													handleSelect(opportunity.properties.uuid, opportunity.latestReservedOpportunity[0].properties.propertyUuid, true)
												}
											}}
										>
											Complete Reservation
										</Button>
									</>
								)
							)
						) : (!disableSelect &&
							<Button
								color="primary"
								variant="outlined"
								size="large"
								disabled={disabled}
								onClick={() => {
									handleSelect(opportunity.id, properties[0].uuid)
									closeModal();
								}}
							>
								{"Select Opportunity"}
							</Button>
						)
					) : (!disableSelect &&
						<Button
							color="primary"
							variant="outlined"
							size="large"
							disabled={disabled}
							onClick={selected ? handleRemove : () => {
								onManageClick(opportunity)
								closeModal();
							}}
						>
							{selected ? "Remove Opportunity" : "Select Opportunity"}
						</Button>
					)}
				</Grid>
				{(opportunity.video || opportunity.image) &&
					<Grid item xs={12} md={7}>
						{!opportunity.video ?
							<img
								alt=""
								src={`${BASE_CDN_URL}${opportunity.image}`}
								onLoad={onImgLoad}
								style={{
									display: "block",
									verticalAlign: "middle",
									maxWidth: "100%",
									margin: "0 auto",
								}}
							/>
							:
							<div className={classes.iframeHolder}>
								{parse(opportunity.video)}
							</div>
						}
					</Grid>
				}
			</Grid>
		</Container>
	)
}

export default OpportunityDetailPaper;