import React from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
    background: 'inherit',
  },
});

const SignupStepper = ({
	activeStep, 
	handleNext, 
	handleBack, 
	handleSubmit, 
	steps
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <MobileStepper
      variant="progress"
      steps={5}
      position="static"
      activeStep={activeStep}
      className={classes.root}
      backButton={
        <Button 
          size="small" 
          onClick={handleBack} 
          disabled={activeStep === 0}
          startIcon={theme.direction === 'rtl' 
            ? <FontAwesomeIcon icon={faArrowRight} />
            : <FontAwesomeIcon icon={faArrowLeft} />
          }
        >
          Back
        </Button>
      }
      nextButton={
        <Button 
          size="small" 
          type="submit"
          onClick={activeStep === steps -1 
            ? handleSubmit 
            : handleNext
          }
          endIcon={theme.direction === 'rtl' 
            ? <FontAwesomeIcon icon={faArrowLeft} /> 
            : <FontAwesomeIcon icon={faArrowRight} />
          }
          >
            {activeStep === steps -1 
              ? "Done" 
              : "Next"
            } 

        </Button>
      }
    />
  );
}

export default SignupStepper;