import { useState } from "react";

const useModal = () => {
	let [showModal, setShowModal] = useState(false);
	let [showSuppModal, setShowSuppModal] = useState(false);

	let closeModal = () => {
		setShowModal(false);
	}

	let openModal = () => {
		setShowModal(true);
	};

	let closeSuppModal = () => {
		setShowSuppModal(false);
	}

	let openSuppModal = () => {
		setShowSuppModal(true);
	};

	return { showModal, closeModal, openModal, showSuppModal, closeSuppModal, openSuppModal };
};

export default useModal;
