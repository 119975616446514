export const MAX_BUDGET = 100000;
export const OPPORTUNITY_YEAR = 2024;
export const LIMITED_OPPORTUNITY_COUNT = 1;

export const COOP_OPPORTUNITY_SALESKIT_LINK = "https://coop.visitsouthwalton.com/userfiles/FY_25_Co-op_Sales_Kit_RoundII_6.3.24.pdf";

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const FACEBOOK_APP_ID = '274101851064397';

export const OPPORTUNITY_RESULTS_PER_PAGE = 10;