import React, { useContext } from "react";
import { useHistory } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';

import GoogleLoginButton from "../components/auth/GoogleLoginButton";

import { AccountContext } from "../AccountContext";
import { timeHasElapsed } from "../constants/deadlines";
import { URLS } from "../constants/urls"

import AuthCard from "../components/auth/AuthCard";
import FacebookLoginButton from "../components/auth/FacebookLoginButton";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(15,150,206,0.1)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
}));

const Auth = ({setIsAuthenticated, signup}) => {
    const { isRegistered, getOrCreateAccount } = useContext(AccountContext);
	localStorage.removeItem('userId');
	localStorage.removeItem('userType');
    const classes = useStyles();
    const history = useHistory();

    const googleLogin = response => {
        if(response.accessToken && response.profileObj) {
            getOrCreateAccount(response.profileObj)
                .then(accountObj => {
                    setIsAuthenticated(true);
                    localStorage.setItem('userId', accountObj.id);
                    localStorage.setItem('userType', "google");
                    if (isRegistered(accountObj)) {
                        history.push(timeHasElapsed() ? URLS.suppOpportunities : URLS.opportunities);
                    } else {
						localStorage.removeItem(`${accountObj.googleId}-signup`)
                        history.push(URLS.signup);
                    }
                })
        }
    }

    const facebookLogin = response => {
        if (response.accessToken) {
            getOrCreateAccount(response)
                .then(response => {
                    setIsAuthenticated(true);
                    localStorage.setItem('userId', response.id);
                    localStorage.setItem('userType', "facebook");
                    if (isRegistered(response)) {
                        history.push("/");
                    } else {
                        history.push(URLS.signup);
                    }
                })
          }
    }

    return (
        <div className={classes.root}>
            <AuthCard signup={signup}>
                <GoogleLoginButton login={googleLogin} signup={signup}/>
                <FacebookLoginButton login={facebookLogin} signup={signup}/>
            </AuthCard>
        </div>
    )
}

export default Auth;