import React, { useContext } from "react";

import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';

import AccountDetailsTable from "./AccountDetailsTable";
import { AccountContext } from "../../AccountContext";
import FormModal from "../common/FormModal";
import { WeddingSpaceContact } from "./forms/AccountForms";

import useModal from "../../hooks/useModal";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(2),
        },
    },
}));

const EditWeddingSpaceContact = ({
    formData, 
    handleChangeFormData, 
    saveAccountChanges
}) => {
    const classes = useStyles();
    const { accountData } = useContext(AccountContext);
    const { showModal, closeModal, openModal } = useModal();
    
    const rows = [
        {
            label: "Wedding Contact Name", 
            value: accountData["weddingSpaceContactName"] ? accountData["weddingSpaceContactName"] : accountData["contactName"] 
        },
        {
            label: "Wedding Contact Email", 
            value: accountData["weddingSpaceEmail"] ? accountData["weddingSpaceEmail"] : accountData["email"]
        },
        {
            label: "Wedding Contact Phone", 
            value: accountData["weddingSpacePhone"] ? accountData["weddingSpacePhone"] : accountData["phone"] 
        },
    ]

    return (
        <div className={classes.root}>

            <AccountDetailsTable rows={rows}/>

            <Button 
                variant="outlined" 
                color="primary" 
                size="large"
                onClick={openModal}
            >
                EDIT
            </Button>
            
            <FormModal 
                showModal={showModal} 
                closeModal={closeModal}
                submitModal={saveAccountChanges}
            >
                <WeddingSpaceContact 
                    formData={formData} 
                    handleChange={handleChangeFormData} 
                />
            </FormModal>
        </div>
    )
}

export default EditWeddingSpaceContact;