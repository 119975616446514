import React, { useContext, useEffect, useState } from "react";

import OpportunityCard from "./OpportunityCard";
import Typography from "@mui/material/Typography";

import { useHistory } from 'react-router-dom';

import { OPPORTUNITY_RESULTS_PER_PAGE } from "../../constants/constants";

import { OpportunitiesContext } from "../../OpportunitiesContext";

import { AccountContext } from "../../AccountContext";

const AvailableOpportunities = ({
	opportunities,
	selectedOpportunities,
	onDetailsClick,
	onManageClick,
	showManageModal,
	page,
}) => {

	const {
		handleSelect,
		selectIsDisabled,
		handleRemove,
		// budgetState,
	} = useContext(OpportunitiesContext);

	const { accountData } = useContext(AccountContext);
	const { properties } = accountData;
	const isMultiProperty = properties.length > 1;

	const [currentPageData, setCurrentPageData] = useState([]);

	const history = useHistory();

	const onSelector = (opportunity, propertyId, finalize) => {
		if (finalize) {
			history.push(`/finalize/${opportunity}_${propertyId}`)
		} else {
			if (isMultiProperty) {
				const opp = opportunities.filter(o => o.id === opportunity)[0];
				onManageClick(opp);
			} else {
				handleSelect(opportunity, propertyId)
					.then(res => {
						if (res && res.type === "Supplemental") {
							onManageClick(res);
						}
					})
			}
		}
	}

	useEffect(() => {
		const newData = opportunities.filter(o => !!o.availability).slice((page - 1) * OPPORTUNITY_RESULTS_PER_PAGE, page * OPPORTUNITY_RESULTS_PER_PAGE)
		setCurrentPageData(newData)
	}, [opportunities, page])

	return (
		<>
			{currentPageData.map(o => {
				const selectedProperties = selectedOpportunities.filter(soUuid => {
					return soUuid.indexOf(o.id) > -1
				})
				return (
					// Don't display cards for opportunities with no availability
					o.availability > 0 &&
					<div style={{ width: "100%" }} key={o.id}>
						<OpportunityCard
							opportunity={o}
							handleSelect={onSelector}
							showModal={showManageModal}
							handleRemove={handleRemove}
							selected={selectedProperties.length > 0}
							selectedProperties={selectedProperties}
							onDetailsClick={onDetailsClick}
							disabled={selectIsDisabled(o.id)}
							userIsMultiProperty={isMultiProperty}
							properties={properties}
							flags={
								<>
									{(o.Category[0].object.permalink.startsWith('leisure-print')) && (
										<Typography>Only available for South Walton Bed Tax Collectors</Typography>
									)}
									{(o.Category[0].object.permalink.startsWith('walton-county-print')) && (
										<Typography>Only available for Authentic Walton Bed Tax Collectors</Typography>
									)}
									{(o.Category[0].object.permalink.startsWith('meetings')) && (
										<Typography>Only available for South Walton Meetings Facilities</Typography>
									)}
									{(o.Category[0].object.permalink.startsWith('weddings')) && (
										<Typography>Only available for South Walton Wedding Facilities</Typography>
									)}
								</>
							}
						/>
					</div>
				)
			}
			)}
			{currentPageData.length === 0 && <div style={{ width: "100%" }}><Typography>We're sorry. We were not able to find a match. Please change your filter criteria to search for additional available opportunities.</Typography></div>}
		</>
	)
}

export default AvailableOpportunities;