import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const TabPanel = props => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const a11yProps = index => {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		marginTop: '3rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '16rem'
		}
	},
	tabColumn: {
		[theme.breakpoints.up('md')]: {
			height: "100%",
		}
	},
	tabTitle: {
		padding: ".75rem",
		[theme.breakpoints.down('md')]: {
			width: "100%"
		}
	},
	tabs: {
		width: "100%",
		"& > div > div": {
			flexDirection: "column",
		},
		[theme.breakpoints.up('md')]: {
			width: "initial",
			flexDirection: "row",
			height: "100%",
			borderRight: `1px solid ${theme.palette.divider}`,
		}
	},
	tabLabel: {
		display: 'flex',
		justifyContent: 'flex-start',
		fontSize: theme.typography.h6.fontSize,
		textAlign: "left",
		flexDirection: "row",
		// [theme.breakpoints.down('md')]: {
		// 	justifyContent: "center",
		// },
		color: theme.typography.h6.color,
		width: "100%",
		maxWidth: "initial"
	},
	tabPanel: {
		flexGrow: 1,
		"& > div": {
			[theme.breakpoints.down('md')]: {
				padding: 0
			}
		}
	},
	wrapper: {
		[theme.breakpoints.down('md')]: {
			display: "block",
		}
	}
}));

const VerticalTabs = ({ tabArray, tabTitle, defaultActiveTabIndex }) => {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	const handleChange = (e, newValue) => {
		if (newValue !== null) setValue(newValue);
	};

	React.useEffect(() => {
		setValue(defaultActiveTabIndex);
	}, [defaultActiveTabIndex])

	return (
		<div className={classes.root}>
			<Grid container>
				<Grid className={classes.tabColumn} container item xs={12} md={4} lg={4}>
					<Typography component="div" className={classes.tabTitle} variant="h4">{tabTitle}</Typography>
					<Tabs
						orientation={!matches ? "vertical" : "horizontal"}
						value={value}
						onChange={handleChange}
						aria-label="Account Page Vertical Tabs"
						className={classes.tabs}
					>
						{tabArray.map((t, i) => (
							<Tab
								key={i}
								value={t.value === undefined ? i : t.value}
								label={t.tabLabel}
								className={classes.tabLabel}
								classes={{ wrapper: classes.wrapper }}
								fullWidth={!matches}
								{...a11yProps(i)}
							/>
						))}
					</Tabs>
				</Grid>
				<Grid className={classes.tabColumn} container item xs={12} md={8} lg={8}>
					{tabArray.map((t, i) => (
						<TabPanel
							key={i}
							value={value}
							index={i}
							className={classes.tabPanel}
						>
							{t.panelContent}
						</TabPanel>
					))}
				</Grid>
			</Grid>
		</div>
	);
}

export default VerticalTabs;