import React, { useEffect, useState } from "react";

import {
	MeetingSpaceContact,
	PartnerDetails,
	SetBudget,
	SignupBasics,
	WeddingSpaceContact
} from "../components/account/forms/AccountForms";

/**
 * Custom hook for the Signup stepped form; keeps track of all required state
 */
const useSignupStepper = ({ accountData, updateAccount, formRef }) => {
	const [activeStep, setActiveStep] = useState(0);
	const [skippedSteps, setSkippedSteps] = useState(new Set([2, 3]));

	// NOTE: These keys correspond with the form input ids: 
	const [formData, setFormData] = useState({
		"organizationName": "",
		"contactName": "",
		"billingAddress": "",
		"state": "FL",
		"city": "",
		"zip": "",
		"phone": "",
		"email": "",
		"isBedTaxCollector": false,
		"bedTaxLocation": "south-walton",
		"isMeetingFacility": false,
		"isWeddingFacility": false,
		"meetingSpaceContactName": "",
		"meetingSpacePhone": "",
		"meetingSpaceEmail": "",
		"weddingSpaceContactName": "",
		"weddingSpaceEmail": "",
		"weddingSpacePhone": "",
		"budget": 0,
		"properties": [
			{
				"organizationName": "",
				"contactName": "",
				"billingAddress": "",
				"state": "FL",
				"city": "",
				"zip": "",
				"phone": "",
				"email": "",
				"isBedTaxCollector": false,
				"isMeetingFacility": false,
				"isWeddingFacility": false,
				"bedTaxLocation": false
			}
		]
	});

	const handleNext = (e) => {
		e.preventDefault();
		// Allows the validation alerts to pop up
		if (!formRef.current.reportValidity()) {
			return;
		}
		window.scroll({
			top: 0,
			behavior: 'smooth'
		});
		let nextStep = activeStep + 1;
		const newAccount = { ...accountData, ...formData }
		newAccount["properties"][0]["meetingSpaceContactName"] = !newAccount["properties"][0]["meetingSpaceContactName"] ? newAccount["properties"][0]["contactName"] : newAccount["properties"][0]["meetingSpaceContactName"]
		newAccount["properties"][0]["meetingSpacePhone"] = !newAccount["properties"][0]["meetingSpacePhone"] ? newAccount["properties"][0]["phone"] : newAccount["properties"][0]["meetingSpacePhone"]
		newAccount["properties"][0]["meetingSpaceEmail"] = !newAccount["properties"][0]["meetingSpaceEmail"] ? newAccount["properties"][0]["email"] : newAccount["properties"][0]["meetingSpaceEmail"]
		newAccount["properties"][0]["weddingSpaceContactName"] = !newAccount["properties"][0]["weddingSpaceContactName"] ? newAccount["properties"][0]["contactName"] : newAccount["properties"][0]["weddingSpaceContactName"]
		newAccount["properties"][0]["weddingSpacePhone"] = !newAccount["properties"][0]["weddingSpacePhone"] ? newAccount["properties"][0]["phone"] : newAccount["properties"][0]["weddingSpacePhone"]
		newAccount["properties"][0]["weddingSpaceEmail"] = !newAccount["properties"][0]["weddingSpaceEmail"] ? newAccount["properties"][0]["email"] : newAccount["properties"][0]["weddingSpaceEmail"]
		if (newAccount["budget"] < 200) newAccount["budget"] = 200
		updateAccount(newAccount);
		while (skippedSteps.has(nextStep)) {
			nextStep++;
		}
		setActiveStep(nextStep);
	};

	const handleBack = () => {
		let prevStep = activeStep - 1;
		while (skippedSteps.has(prevStep)) {
			prevStep--;
		}
		setActiveStep(prevStep);
	};

	const handleChangeFormData = (e) => {
		const key = e.target.id ? e.target.id : e.target.name;
		const newFormData = { ...formData };
		if (key.indexOf("properties") !== -1) {
			const [propertyKey, propertyIndex, fieldKey] = key.split(".");
			newFormData["properties"][propertyIndex][fieldKey] = e.target.value;
		} else {
			newFormData[key] = e.target.value;
		}
		setFormData(newFormData);
	}

	const addSkippedStep = (step) => {
		let newSkippedSteps = skippedSteps;
		newSkippedSteps.add(step);
		setSkippedSteps(newSkippedSteps)
	}

	const deleteSkippedStep = (step) => {
		let newSkippedSteps = skippedSteps;
		newSkippedSteps.delete(step);
		setSkippedSteps(newSkippedSteps)
	}

	const handleFacilityChange = (e) => {
		const key = e.target.id ? e.target.id : e.target.name;
		const value = e.target.value;
		// NOTE: If we are setting a facility to true,
		// ("yes, we are a meeting facility")
		// remove it from the skipped steps
		// and if we're setting it false, we must add it 
		const newFormData = { ...formData };
		if (key.indexOf("properties") !== -1) {
			const [propertyKey, propertyIndex, fieldKey] = key.split(".");
			if (fieldKey === "isMeetingFacility") {
				value ? deleteSkippedStep(2) : addSkippedStep(2);
			} else if (fieldKey === "isWeddingFacility") {
				value ? deleteSkippedStep(3) : addSkippedStep(3);
			}
			newFormData["properties"][propertyIndex][fieldKey] = value;
		} else {
			newFormData[key] = value;
		}
		newFormData[key] = value;
		setFormData(newFormData);
	}

	const getStepContent = (stepIndex) => {
		switch (stepIndex) {
			case 0:
				return (
					<SignupBasics
						formData={formData}
						handleChange={handleChangeFormData}
					/>
				)
			case 1:
				return (
					<PartnerDetails
						formData={formData}
						handleChange={handleFacilityChange}
					/>
				)
			case 2:
				return (
					<MeetingSpaceContact
						formData={formData}
						handleChange={handleChangeFormData}
					/>
				)
			case 3:
				return (
					<WeddingSpaceContact
						formData={formData}
						handleChange={handleChangeFormData}
					/>
				)
			case 4:
				return (
					<SetBudget
						formData={formData}
						handleChange={handleChangeFormData}
					/>
				)
			default:
				return;
		}
	}

	useEffect(() => {
		setFormData(accountData);
	}, [accountData])

	return {
		activeStep,
		formData,
		handleNext,
		handleBack,
		getStepContent,
	}
}

export default useSignupStepper;