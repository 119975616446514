import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';
import { theme } from '../../constants/SiteTheme';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		marginTop: '20px',
		marginBottom: '50px'
	},
	item: {
		justifyContent: 'center',
	}
}));

const Feature = ({icon, text}) => {
	const classes = useStyles(theme);

	return (
        <Grid 
			className={classes.root}
			container 
			item 
			justifyContent="center" 
			align="center" 
			direction="column" 
			xs
		>
			<Grid item xs>
				{icon}
			</Grid>
			<Grid item xs>
				<Typography variant="h6" >
					{text}
				</Typography>
			</Grid>
		</Grid>
    );
}

const SiteFeatures = () => {
	const classes = useStyles(theme);

	return <>
        <Grid 
            container
            direction="row"
            justifyContent="center"
            alignItems="center" 
            spacing={3}
            className={classes.root}
        >
            <Feature 
                icon={
                    <img 
                        src="images/icons/MonitorIcon.svg" 
                        alt="Icon of a dollar sign on a page"
                        className={classes.logo}
                    />
                } 
                text={"MONITOR BUDGET"}
            />
            <Feature 
                icon={
                    <img 
                        src="images/icons/LockInIcon.svg" 
                        alt="Icon of a lock symbol on a page with a signature"
                        className={classes.logo}
                    />
                } 
                text={"LOCK IN OPPORTUNITIES"}
            />
            <Feature 
                icon={						
                    <img 
                        src="images/icons/UpdatesIcon.svg" 
                        alt="Eye symbol on a page with text"
                        className={classes.logo}
                    />
                } 
                text={"SEE OPPORTUNITY UPDATES"}
            />
        </Grid>
    </>;
}

export default SiteFeatures;