import React from "react";

import makeStyles from '@mui/styles/makeStyles';

import Footer from "./Footer";
import SiteAppBar from "../nav/SiteAppBar";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
  }));


const PrivateLayout = ({children, isAuthenticated }) => {
    const classes = useStyles();

    return (
        <>
            <SiteAppBar 
                isAuthenticated={isAuthenticated} 
            />
            <div className={classes.offset}/>
            <div>
                {children}
            </div>
            <Footer />
        </>
    )
}

export default PrivateLayout;