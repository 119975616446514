import React, { useContext } from "react";

import NumberFormat from 'react-number-format';
import { TextField } from "@mui/material";
import { MAX_BUDGET } from "../../constants/constants";
import { AccountContext } from "../../AccountContext";

const NumberFormatCustom = (props) => {
	const { accountData } = useContext(AccountContext);
	const { inputRef, onChange, ...other } = props;
	const withValueLimit = (inputObj) => {
		const { value } = inputObj;
		let maxBudget = MAX_BUDGET
		if (accountData.properties.length === 2) maxBudget = MAX_BUDGET + MAX_BUDGET;
		if (accountData.properties.length >= 3) maxBudget = 250000;
		if (value <= maxBudget ) return inputObj;
	};

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						id: props.id,
						name: props.name,
						value: values.value,
					},
				});
			}}
			isAllowed={withValueLimit}
			thousandSeparator
			isNumericString
			prefix="$"
		/>
	);
}

const BudgetInput = ({id, value, handleChange, required}) => {

	return (
		<TextField
			id={id}
			required={required}
			label="Budget"
			onChange={handleChange}
			name="budget"
			InputProps={{
					inputComponent: NumberFormatCustom,
			}}
			variant="outlined"
			InputLabelProps={{ shrink: true }}
			value={value}
			fullWidth
		/>
	)
}

export default BudgetInput;