import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme) => ({
	logo: {
		height: "10rem",
		// width: "10rem",
		[theme.breakpoints.only('xs')]: {
			height: "6rem",
			// width: "6rem",
		},
	}
}));

const HeaderLogo = () => {
	const classes = useStyles();

	return (
		<RouterLink to="/">
			<img
				src="images/logos/WCT_White.png"
				alt="VSW Co-op Logo"
				className={classes.logo}
			/>
		</RouterLink>
	)
}

export default HeaderLogo;