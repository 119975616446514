import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { URLS } from '../../constants/urls';

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(4),
		width: "27.315rem",
		minHeight: "47rem",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		boxShadow: "0 6px 8px 0 rgba(0,0,0,0.12)",
	},
	cardContent: {
		padding: theme.spacing(6),
	},
	logo: {
		height: "12rem",
	},
	horizontalRule: {
		height: "2px",
		width: "100%",
		backgroundColor: "#E4F1F7",
	},
	termsContainer: {
		marginTop: "2rem",
		paddingTop: "2rem",
	}
}));

const AuthCard = ({ children, signup }) => {
	const classes = useStyles();

	return (
		<Card className={classes.root} variant="outlined">
			<Grid container direction="row" justifyContent="center">
				<RouterLink to="/">
					<img
						src="images/logos/WCT_RGB.jpg"
						alt="VSW Co-op Logo"
						className={classes.logo}
					/>
				</RouterLink>
			</Grid>
			<div className={classes.horizontalRule} />
			<CardContent className={classes.cardContent}>
				<Typography variant="h6" align="center">
					{signup ? "Sign Up" : "Login"}
				</Typography>
				<Typography align="center">
					Use a personal or business account to verify your identity. By {signup ? "signing up" : "signing in"} through Google,
					you will not need to create a new username and password. Google will verify who you are, but
					your account details with those entities will not be shared with this program. Google also
					do not receive any information from this program.
				</Typography>
				<Grid container direction="row" justifyContent="center">
					{children}
				</Grid>
				<Box
					className={classes.termsContainer}
					borderTop={1}
					borderColor="#E4F1F7"
				>
					<Typography align="center">
						By  {signup ? "Signing Up" : "Logging in"}, you have read and agreed to these <RouterLink to={URLS.terms}>important terms</RouterLink>.
					</Typography>
				</Box>
			</CardContent>
		</Card>
	);
}

export default AuthCard;