import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';


const FormModal = ({ showModal, closeModal, submitModal, children, size, text, stopDefaultClose, isDisabled }) => {

	function submitClose() {
		submitModal()
		if (!stopDefaultClose) closeModal()
	}

	if (!text) text = "Save"

	return (
		<div>
			<Dialog maxWidth={size} open={showModal} onClose={closeModal} aria-labelledby="form-dialog-title">
				<DialogContent>
					{children}
				</DialogContent>
				<DialogActions>
					<Button onClick={closeModal} color="primary">
						Cancel
					</Button>
					<Button
						onClick={submitClose}
						color="primary"
						type="submit"
						disabled={isDisabled}
					>
						{text}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
};

export default FormModal;