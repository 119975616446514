import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import { Button, Divider, Grid, Typography } from '@mui/material';
import Image from 'material-ui-image';

import { URLS } from '../../constants/urls';
import { timeHasElapsed } from '../../constants/deadlines';


const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	welcome: {
		color: theme.palette.text.primary,
	},
	image: {
		width: "100%",
		height: "100%",
		border: "3px solid black",
	},
	divider: {
		marginTop: "100px",
		height: "4px",
		backgroundColor: theme.palette.secondary.light,
		width: "100%",
	},
	italic: {
		fontStyle: "italic",
	}
}));


const Introducing = ({ isAuthenticated }) => {
	const classes = useStyles();

	return (
		<>
			<Grid container className={classes.root} direction="row" spacing={3}>
				<Grid item xs={12} md={4}>
					<Typography variant="h4" className={classes.italic}>
						Welcome To
					</Typography>
					<Typography variant="h3" >
						{"THE FULL WALTON COUNTY TOURISM CO-OP EXPERIENCE".toUpperCase()}
					</Typography>
					<Typography className={classes.welcome} variant="body2">
						Welcome to the Walton County Tourism Department’s {timeHasElapsed() ? "" : "FY2024-2025"} Co-Op Program. We are pleased to offer a fully digital sales process that allows you to purchase flexible flight opportunities and customized creative during the annual program time period, as well as throughout the year.
					</Typography>
					{!isAuthenticated ?
						<Button
							variant="outlined"
							color="primary"
							component={RouterLink}
							to="/signup"
							size="large"
						>
							Sign Up
						</Button> :
						<Button
							variant="contained"
							color="primary"
							size="large"
							style={{ width: "100%" }}
							component={RouterLink}
							to={timeHasElapsed ? URLS.opportunities : URLS.suppOpportunities}
						>
							Go to My Account
						</Button>
					}
				</Grid>
				<Grid item xs={12} md={8}>
					<Image
						src="/images/visit-south-walton-hero-desktop.jpg"
						alt="A beautiful South Walton beach"
					>
					</Image>
				</Grid>
				<Divider variant="middle" className={classes.divider} />
			</Grid>
		</>
	)
}

export default Introducing;