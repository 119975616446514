import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { Button, Divider, Grid, Typography } from '@mui/material';
import Image from 'material-ui-image';

import { Link as RouterLink } from 'react-router-dom';

import formatDateTime from "../../helpers/formatDateTime";
import { SALES_BEGIN, SALES_END } from "../../constants/deadlines";

import { URLS } from '../../constants/urls';
import { timeHasElapsed } from '../../constants/deadlines';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: "50px 0",
	},
	subtitle: {
		fontSize: "30px",
		lineHeight: "36px",
		fontStyle: "italic",
	},
	fineprint: {
		fontStyle: "italic",
	},
	divider: {
		marginTop: "20px",
		marginBottom: "20px",
		height: "8px",
		backgroundColor: theme.palette.warning.main,
		width: "32px",
	},
	bodytext: {
		fontSize: "1.25rem",
		marginBottom: "50px",
	},
	image: {
		width: "100%",
		height: "100%",
		border: "3px solid black",
	},
	italic: {
		fontStyle: "italic",
	}
}));

const Experience = ({ isAuthenticated }) => {
	const classes = useStyles();

	return (
		<Grid className={classes.root} spacing={{ xs: 0, md: 6 }} container direction="row">
			<Grid item xs={12} md={7}>

				<Typography variant="h4" className={classes.italic}>
					Experience
				</Typography>
				<Typography variant="h3">
					{"A CUSTOMIZABLE CO-OP CAMPAIGN DESIGNED TO MEET YOUR UNIQUE NEEDS"}
				</Typography>
				<Typography className={classes.bodytext}>
					 <>
						The Walton County Tourism Department will be re-opening the FY25 Co-Op Program, with all remaining annual co-op opportunities available for purchase. The additional sales period begins {formatDateTime(SALES_BEGIN)} and ends {formatDateTime(SALES_END)}.
						<br /><br />
						We are pleased to offer a fully digital experience that streamlines the sales process for annual co-op, as well as year-round, supplemental opportunities. Overall, the program provides quite a bargain benefit. Opportunities start at $600, and the Walton County Tourism Department shoulders at least 50% of the cost for media placements.
						<br /><br /><a href="https://coop.visitsouthwalton.com/userfiles/FY_25_Co-op_Sales_Kit_RoundII_6.3.24.pdf">View 2024-2025 Sales Kit (Remaining Opportunities)</a>
					</>
					{/* <br /><br />As in years past, the supplemental opportunities are available year-round, and each supplemental co-op campaign will launch 5 weeks from the time of the initial opportunity submission. */}
				</Typography>

				<Divider className={classes.divider} />
				<Typography variant="h6">
					Annual Sales Program & Contract Schedule
				</Typography>
				<>
					<Typography>
						The additional round of annual sales will open on {formatDateTime(SALES_BEGIN)} closes on {formatDateTime(SALES_END)}
						<br />
						<br />
						Partners to receive contracts by June 20th, 2024.
						<br />
					</Typography>
					<Typography className={classes.fineprint}>
						By acknowledging this information, you agree that you will include an accurate budget and commit to the selections made. Once contracts are disseminated and executed through DocuSign, you will receive an invoice for the program on or around July 29th. Payment is due within 15 days of invoice receipt.
					</Typography>
				</>

				{/* <Divider className={classes.divider} />
				<Typography variant="h6">
					Supplemental Program & Contract Schedule
				</Typography>
				<Typography>
					Supplemental opportunities are available at any time throughout the year. Each campaign will launch 5 weeks from the time of the initial opportunity submission.
				</Typography>
				<Typography className={classes.fineprint}>
					Once contracts are disseminated and executed through DocuSign, you will receive an invoice for the program. Payment is due within 15 days of invoice receipt. The supplemental campaign will launch 5 weeks from the time of opportunity submission. If payment is not received within 15 days, you forfeit the contracted items.
				</Typography> */}
				{!isAuthenticated ?
					<Button
						variant="outlined"
						color="primary"
						component={RouterLink}
						to="/signup"
						size="large"
					>
						Sign Up
					</Button>
					:
					<Button
						variant="contained"
						color="primary"
						size="large"
						style={{ width: "100%" }}
						component={RouterLink}
						to={timeHasElapsed ? URLS.opportunities : URLS.suppOpportunities}
					>
						Go to My Account
					</Button>
				}
			</Grid>
			<Grid item xs={12} md={5}>
				<Divider className={classes.divider} />
				<Image
					alt="woman using a gold iPad"
					src="images/Dashboard-Image.jpg"
				/>
			</Grid>
		</Grid>
	)
}

export default Experience;