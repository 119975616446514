import React from "react";
import { Link as RouterLink } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import { Container } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { URLS } from "../../constants/urls";

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.secondary.dark,
		height: "8rem",
		// position: "absolute",
		// bottom: 0,
  },
	container: {
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	link: {
		textDecoration: "none",
		color: "white",
	}
}));

const Footer = () => {
	const classes = useStyles();

	return (
		<AppBar position="static" className={classes.appbar}>
		<Container align="center" maxWidth="md" className={classes.container}>
			<Toolbar>
				<Typography variant="body1" color="inherit">
					©{new Date().getFullYear()} Walton County Tourism Department, All rights reserved.
					|  <RouterLink to={URLS.terms} className={classes.link}>Privacy & Terms of Use </RouterLink>
					|  <RouterLink to={URLS.faqs} className={classes.link}> FAQ </RouterLink>
					|  <RouterLink to="/logout" className={classes.link}> Reset Program</RouterLink>
				</Typography>
			</Toolbar>
		</Container>
	</AppBar>
	)
}

export default Footer;