import React from "react";

import BooleanSelector from "../../common/BooleanSelector";
import Selector from "../../common/Selector";


const BedTaxCollectorForm = ({ formData, handleChange, propertyIndex }) => {
	return (
		<>
			<Selector
				options={[
					{ value: "south-walton", label: "South Walton" },
					{ value: "f-ds-p", label: "Authentic Walton" },
				]}
				required
				handleChange={handleChange}
				defaultValue={"south-walton"}
				value={formData["properties"][propertyIndex]["bedTaxLocation"] || "south-walton"}
				labelText="Where are you located?"
				id={`properties.${propertyIndex}.bedTaxLocation`}
			/>
			<BooleanSelector
				required
				handleChange={handleChange}
				value={formData["properties"][propertyIndex]["isBedTaxCollector"]}
				labelText="Are you a bed tax collector?"
				id={`properties.${propertyIndex}.isBedTaxCollector`}
			/>
			{formData["properties"][propertyIndex]["isBedTaxCollector"]
				? (
					<>
						<BooleanSelector
							required
							handleChange={handleChange}
							value={formData["properties"][propertyIndex]["isMeetingFacility"]}
							labelText="Are you a meeting facility?"
							id={`properties.${propertyIndex}.isMeetingFacility`}
						/>
						<BooleanSelector
							required
							handleChange={handleChange}
							value={formData["properties"][propertyIndex]["isWeddingFacility"]}
							labelText="Are you a wedding facility?"
							id={`properties.${propertyIndex}.isWeddingFacility`}
						/>
					</>
				)
				: null
			}
		</>
	)
}

export default BedTaxCollectorForm;