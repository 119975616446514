import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faLink } from '@fortawesome/free-solid-svg-icons';

import { AccountContext } from "../../AccountContext";
import { OpportunitiesContext } from "../../OpportunitiesContext";

import FormModal from "../common/FormModal";
import useModal from "../../hooks/useModal";
import { SetBudget } from '../account/forms/AccountForms';

import formatCurrency from "../../helpers/formatCurrency";
import { URLS } from '../../constants/urls';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "13.65rem",
    },
    iconButton: {
        marginLeft: "1rem",
    },
    budgetAmount: {
        marginBottom: "0.5rem",
        color: "#0D9752",
        fontWeight: "bold",
    }
}));

const RemainingBudget = () => {
    const { accountData } = useContext(AccountContext);

    const { 
        opportunities,
        budgetState,
        handleBudgetChange,
        saveBudget,
    } = useContext(OpportunitiesContext);  


    const { showModal, closeModal, openModal } = useModal();

    const classes = useStyles();

    return (
        <Grid container direction="column" className={classes.root}>
            <Button color="primary" onClick={openModal}>
                {"Remaining Budget".toUpperCase()} 
                <FontAwesomeIcon 
                    icon={faEdit}
                    className={classes.iconButton}
                />
            </Button>
            <Typography variant="h5" align="center" className={classes.budgetAmount}>
                {opportunities && (accountData["budget"] || accountData["budget"] === 0)
                    ? formatCurrency(budgetState.remaining)
                    : <CircularProgress />
                }
                
            </Typography>
            <FormModal 
                showModal={showModal} 
                closeModal={closeModal}
                submitModal={saveBudget}
            >
                <SetBudget 
                    formData={budgetState} 
                    handleChange={handleBudgetChange} 
                />

                <Typography> 
                    Selected Opportunities ({accountData["opportunities"].length}): {formatCurrency(budgetState.used)} 
                    <IconButton 
                        color="primary" 
                        aria-label="upload picture" 
                        component={Link}
                        size="small"
                        to={URLS.account}
                        onClick={closeModal}
                    >
                        <FontAwesomeIcon icon={faLink} />
                    </IconButton>
                </Typography>

            </FormModal>
        </Grid>
    )
}

export default RemainingBudget;