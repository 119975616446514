import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(4),
		width: "27.315rem",
		minHeight: "47rem",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		boxShadow: "0 6px 8px 0 rgba(0,0,0,0.12)",
	},
	cardContent: {
		padding: theme.spacing(6),
	},
	logo: {
		height: "12rem",
	},
	horizontalRule: {
		height: "2px",
		width: "100%",
		backgroundColor: "#E4F1F7",
	},
	termsContainer: {
		marginTop: "2rem",
		paddingTop: "2rem",
	}
}));

const LogOutCard = ({ children }) => {
	const classes = useStyles();

	return (
		<Card className={classes.root} variant="outlined">
			<Grid container direction="row" justifyContent="center">
				<RouterLink to="/">
					<img
						src="images/logos/WCT_RGB.jpg"
						alt="VSW Co-op Logo"
						className={classes.logo}
					/>
				</RouterLink>
			</Grid>
			<div className={classes.horizontalRule} />
			<CardContent className={classes.cardContent}>
				<Typography variant="h6" align="center">
					Log Out
				</Typography>
				<Grid container direction="row" justifyContent="center">
					{children}
				</Grid>
				<Box
					className={classes.termsContainer}
					borderTop={1}
					borderColor="#E4F1F7"
				>
				</Box>
			</CardContent>
		</Card>
	);
}

export default LogOutCard;