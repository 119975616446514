import React, { useContext, useState, useEffect } from "react";

import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Button from "@mui/material/Button";
import { Link as RouterLink } from 'react-router-dom';
import useModal from "../hooks/useModal";
import Modal from "../components/common/Modal";
import { Typography } from '@mui/material';
import { timeHasElapsed } from "../constants/deadlines";

import VerticalTabs from "../components/common/VerticalTabs";
import SuppDashboard from '../components/account/SuppDashboard';
import AnnualDashboard from '../components/account/AnnualDashboard';
import EditCompanyInfo from '../components/account/EditCompanyInfo';
import EditMeetingSpaceContact from '../components/account/EditMeetingSpaceContact';
import EditWeddingSpaceContact from '../components/account/EditWeddingSpaceContact';

import { AccountContext } from "../AccountContext";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: "16rem",
		minHeight: "100vh",
	},
}));

const Account = () => {
	const { accountData, updateAccount } = useContext(AccountContext);
	const [defaultActiveTabIndex, setDefaultActiveTabIndex] = useState(0);
	const { showModal, closeModal, openModal } = useModal();
	const classes = useStyles();

	// use useEffect to check for url parameters ?tab=supplemental and update the vertical tabs active tab
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const tab = urlParams.get("tab");
		switch (tab) {
			case "supplemental":
				setDefaultActiveTabIndex(!timeHasElapsed() ? 1 : 0)
				break;
			case "properties":
				setDefaultActiveTabIndex(!timeHasElapsed() ? 2 : 1)
				break;
			default:
				setDefaultActiveTabIndex(0)
				break;
		}
	}, [])	

	const tabArray = [
		{
			tabLabel: "Annual Reservations",
			panelContent:
				<AnnualDashboard
					accountData={accountData}
					updateAccount={updateAccount}
				/>,
			hide: timeHasElapsed(),
		},
		{
			tabLabel: "Supplemental Reservations",
			panelContent:
				<SuppDashboard
					accountData={accountData}
					updateAccount={updateAccount}
				/>
		},
		{
			tabLabel: "My Properties",
			panelContent:
				<EditCompanyInfo />
		},
		//{
		//	tabLabel: "Meeting Space Contact",
		//	panelContent:
		//		<EditMeetingSpaceContact
		//			formData={formData}
		//			handleChangeFormData={handleChangeFormData}
		//			saveAccountChanges={saveAccountChanges}
		//		/>,
		//	hide: !accountData["isMeetingFacility"],
		//},
		//{
		//	tabLabel: "Wedding Space Contact",
		//	panelContent:
		//		<EditWeddingSpaceContact
		//			formData={formData}
		//			handleChangeFormData={handleChangeFormData}
		//			saveAccountChanges={saveAccountChanges}
		//		/>,
		//	hide: !accountData["isWeddingFacility"],
		//},
		{
			value: null,
			tabLabel: (
				<Button
					color="primary"
					variant="outlined"
					size="large"
					disabled={accountData.opportunities.length === 0}
					onClick={openModal}
				>
					Submit Annual Selections
				</Button>
			),
			hide: timeHasElapsed(),
		}
	]

	return (
		<Container maxWidth="lg" className={classes.root}>
			<VerticalTabs defaultActiveTabIndex={defaultActiveTabIndex} tabTitle="Account Details" tabArray={tabArray.filter(t => !t.hide)} />
			<Modal
				showModal={showModal}
				closeModal={closeModal}
			>
				<Typography style={{ marginRight: "1em" }}>
					Your selections have been submitted. Selections can be modified by adjusting within My Account until the sales period closes at 4:00pm on June 14th, 2024.
				</Typography>
				<Typography>
					Thank you for participating!
					<div style={{ width: "100%", paddingTop: "1em" }}>
						<Button
							variant="outlined"
							color="primary"
							component={RouterLink}
							to="/logout"
							size="large"
						>
							Log Out
						</Button>
					</div>
				</Typography>
			</Modal>
		</Container>
	)
}

export default Account;