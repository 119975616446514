import React from "react";

import NumberFormat from 'react-number-format';
import { TextField } from "@mui/material";

const NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              id: props.id,
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="(###) ###-####"
        mask="_"
      />
    );
  }

const PhoneInput = ({id, handleChange, value}) => {
    return (
        <TextField
            id={id}
            label="Phone Number"
            onChange={handleChange}
            name="phone number"
            InputProps={{
                inputComponent: NumberFormatCustom,
            }}
            variant="outlined"
            value={value}
            fullWidth
			InputLabelProps={{ shrink: true }}
            autoComplete="tel-national"
        />
    )
}

export default PhoneInput;