import Selector from "./Selector";

const BooleanSelector = (props) => {

	return (
		<Selector
			options={[
				{value: true, label: "Yes"},
				{value: false, label: "No"}
			]}
			{...props}
		/>
	)
}

export default BooleanSelector