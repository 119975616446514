import React from 'react'
import makeStyles from '@mui/styles/makeStyles';

import { GoogleLogin } from 'react-google-login';

import { GOOGLE_CLIENT_ID } from '../../constants/constants';

const useStyles = makeStyles((theme) => ({
    loginButton: {
		width: "18rem"
    }
}));


const GoogleBtn = ({login, signup}) => {

	const classes = useStyles();

	const handleLoginFailure = response => {
		console.log(response)
	}

	return (
		<div
			style={{ marginBottom: "1rem" }}
		>
			<GoogleLogin
				className={classes.loginButton}
				clientId={ GOOGLE_CLIENT_ID }
				buttonText={signup ? 'Sign Up with Google' : 'Login with Google'}
				onSuccess={ login }
				onFailure={ handleLoginFailure }
				cookiePolicy={ 'single_host_origin' }
				responseType='code,token'
				// isSignedIn={true}
			/>
		</div>
	)
}


export default GoogleBtn;