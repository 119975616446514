import React, { useContext, useState } from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';

import AccountPropertyDetailsTable from "./AccountPropertyDetailsTable";
import { AccountContext } from "../../AccountContext";
import FormModal from "../common/FormModal";
import CompanyEditInfoForm from "./forms/CompanyEditInfoForm";

import useModal from "../../hooks/useModal";
import BedTaxCollectorEditForm from "./forms/BedTaxCollectorEditForm";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(2),
		},
	},
}));

/**
 * Still need the temp form data structure to pass to the form in the modal
 * Use the onChange event in the form modal to watch fields and update the temp structure
 * If form submit, then update the account info with the temp structure -- otherwise throw everything away
 */ 

const EditCompanyInfo = () => {
	const classes = useStyles();
	const [ formData, setFormData ] = useState();
	const { accountData, updateAccount } = useContext(AccountContext);
	const { showModal, closeModal, openModal } = useModal();
	const [currentPropertyIndex, setcurrentPropertyIndex] = useState(0)
	const [ editHasReservations, setEditHasReservations ] = useState(false);

	const formRef = React.useRef(null);

	const openCreateFormModal = () => {
		const tmpFormData = {
			"organizationName": "",
			"contactName": "",
			"billingAddress": "",
			"state": "FL",
			"city": "",
			"zip": "",
			"phone": "",
			"email": "",
			"isBedTaxCollector": false,
			"isMeetingFacility": false,
			"isWeddingFacility": false,
			"bedTaxLocation": false,
			"uuid": Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
		};
		setFormData(tmpFormData);
		setcurrentPropertyIndex(-1);
		setEditHasReservations(false);
		openModal();
	}

	const openPropertyModal = (index) => {
		setcurrentPropertyIndex(index);
		setFormData({
			...accountData.properties[index],
		});
		setEditHasReservations(hasReservations(accountData.properties[index].uuid));
		openModal();
	}

	const closeModalAfterCheck = ()	=> {
		closeModal();
	}

	const onSubmit = (e) => {
		if (!formRef.current.reportValidity()) {
			console.log("not valid!")
			return;
		}
		const newFormData = {...formData}
		const newAccountData = {
			...accountData,
		};
		if (!!newFormData.bedTaxLocation === false) newFormData.bedTaxLocation = "south-walton"
		if (currentPropertyIndex >= 0) {
			newAccountData.properties[currentPropertyIndex] = newFormData
		} else {
			newAccountData.properties.push(newFormData);
		}
		updateAccount(newAccountData);
		closeModal();
	}

	const propertyRows = accountData["properties"].map(property => (
		{ label: "Property Name", value: property["organizationName"] }
	))

	const handleChange = (evt) => {
		setFormData({
			...formData,
			[evt.target.id||evt.target.name]: evt.target.value,
		});
	}

	const hasReservations = (propertyID) => {
		for (let opportunity of accountData?.opportunities) {
			const components = opportunity.split('_');
			if (propertyID === components[2])return true;
		}
		return false;
	}

	return (
		<div className={classes.root}>
			<Typography variant="h4">My Properties</Typography>

			<AccountPropertyDetailsTable rows={propertyRows} openEditPropertyModal={openPropertyModal} />

			<Button
				variant="outlined"
				color="primary"
				size="large"
				onClick={openCreateFormModal}
			>
				Create New Property ＋
			</Button>

			<FormModal
				size={`lg`}
				showModal={showModal}
				closeModal={closeModalAfterCheck}
				submitModal={onSubmit}
				stopDefaultClose={true}
			>
				<form action="#" ref={formRef}>
					<Typography variant="h5">{currentPropertyIndex >= 0 ? "Modify Property" : "Create Property"}</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<CompanyEditInfoForm
								formData={formData}
								handleChange={handleChange}
								disableEmailField={currentPropertyIndex === 0}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<BedTaxCollectorEditForm
								formData={formData}
								handleChange={handleChange}
								disableInputs={editHasReservations}
							/>
						</Grid>
					</Grid>
				</form>
			</FormModal>
		</div>
	)
}

export default EditCompanyInfo;