import React, { useContext } from "react";

import { Link as RouterLink } from 'react-router-dom';
import Button from "@material-ui/core/Button";

import makeStyles from '@mui/styles/makeStyles';

import { AccountContext } from "../AccountContext";

import LogOutCard from "../components/auth/LogOutCard";
import GoogleLogoutButton from "../components/auth/GoogleLogoutButton";

var canUseDOM = !!(
	(typeof window !== 'undefined' &&
	window.document && window.document.createElement)
);

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100vw",
		height: "100vh",
		backgroundColor: "rgba(15,150,206,0.1)",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center"
	},
}));

const FacebookLogoutButton = () => {
	return (
		<>
			{/* You have been successfully logged out of Facebook. You may safely close this window. */}
			You have been successfully logged out of Facebook. If you wish to use the system, please sign up again use Google at the Login button below.
			<br />
			<br />
			<Button
                variant="outlined"
                color="primary"
                component={RouterLink}
                to="/login"
                size="large"
            >
                Login
            </Button>
		</>
	)
}

const LogOut = () => {
	const { setIsAuthenticated } = useContext(AccountContext);
	const classes = useStyles();
	const isFacebook = localStorage.getItem("userType") === "facebook";

	const fbLogOut = () => {
		setIsAuthenticated(false);
		if (canUseDOM && window.FB) {
			localStorage.removeItem("userId")
			window.FB.logout();
		}
	}

	if (isFacebook) {
		fbLogOut();
	}

	return (
		<div className={classes.root}>
			<LogOutCard>
				{isFacebook ?
					<FacebookLogoutButton /> :
					<GoogleLogoutButton />
				}
			</LogOutCard>
		</div>
	)
}

export default LogOut;