// import { Link as RouterLink } from 'react-router-dom';

import { /* COOP_OPPORTUNITY_SALESKIT_LINK, */ OPPORTUNITY_YEAR } from "./constants";
// import { SALES_END, INVOICE_RECEIVED_BY } from "./deadlines";
// import formatDateTime from "../helpers/formatDateTime";
import { URLS } from "./urls";

export const FAQS = [
	{
		question: "If I have a question about details regarding a specific placement or if I am having issues with this website, who do I contact?",
		answer: <>You can contact Rebekah Walden at <a href="mailto:rebekah@wcfltourism.com">rebekah@wcfltourism.com</a> or Katherine Simon at <a href="mailto:ksimon@z-comm.com">ksimon@z-comm.com</a></>
	},
	{
		question: "How do I submit my Annual Opportunity selections?",
		answer: <>Under My Account, in the Account Details column, click the Submit Annual Selections Button.</>
	},
	{
		question: "Who can participate in this program?",
		answer: "Co-op opportunities are open to tourism-facing businesses in Walton County (bed tax collectors and specific placements reserved for non-bed tax collectors). Meetings and weddings opportunities are open to eligible businesses."
	},
	{
		question: "How do I see the full list of all the opportunities originally available within the program?",
		answer: <>Annual opportunities can be viewed <a href={`https://coop.visitsouthwalton.com/userfiles/FY_25_Co-op_Sales_Kit_RoundII_6.3.24.pdf`} target="_blank" rel="noreferrer">here</a> and supplemental opportunities can be viewed <a href={`${URLS.suppOpportunities}`} target="_blank" rel="noreferrer">here</a>.</>
	},
	{
		question: "Will my information be shared?",
		answer: "The program will not share your information with Google via sign-in, and we will not receive any of your information from Google. With regard to the contact information entered during sign-up, this information will be used for co-op program administration and communication from the Walton County Tourism Department.",
	},
	// {
	//     question: "How do I delete my website activities?",
	//     answer: <>Follow the instructions outlined <RouterLink to={URLS.datadeletion}>here</RouterLink></>
	// },
	{
		question: "How do I remove an opportunity I have already selected?",
		answer: `Under “My Account”, your selected opportunities will appear. There is a Release Reservation button on the right side of each opportunity where you can click to remove the opportunity.`,
	},
	{
		question: "Am I locked into these selections?",
		answer: <>Within the annual program, your selections will remain in your Selected Opportunities until you release it or Submit Annual Selections by 4 pm on Friday, June 14th, 2024. Once you Submit Annual Selections, you will receive a contract through DocuSign including your selected opportunity, at which point you will give the official approval.
			<br />
			<br />
			Within the supplemental program, when a selection is made, the opportunity will be reserved for 72 hours. The opportunity will be viewable in your dashboard. Please upload all requested assets within the 72 hour window to generate the opportunity contract. You will receive a contract through DocuSign including your selected opportunity, at which point you will give the official approval. The opportunity will be released if the reservation is not finalized.
		</>,
	},
	{
		question: "When will I receive an invoice for this program?",
		answer: `You will receive your invoice for the program via email following the contract signature in DocuSign. Payment will be due within 15 days from invoice date. If payment is not received within 15 days, you forfeit the contracted items.`,
	},
	{
		question: "How do I change my Partner status from Bed Tax Collector to Non-Bed Tax Collector or vice versa?",
		answer: `You can add or edit information for each of your properties by clicking on “My Account”, selecting “My Properties and “Edit Property Info”.`,
	},
	{
		question: "How do I submit what I want featured in the advertisements?",
		answer: "Prior to the run dates for your selections, you will receive communication via email outlining asset needs for your upcoming placements. Once provided, you will receive an additional follow-up email for approval of final assets.",
	},
	{
		question: "What type of reporting do I receive and when?",
		answer: "For digital placements, you will receive a report after your campaign runs that includes relevant data such as impressions, clicks to your page on visitsouthwalton.com, traffic to your website, video views (where applicable), etc.",
	},
	{
		question: "When will my campaign run?",
		answer: "The campaign will run during the selected flight dates. If you have purchased a supplemental opportunity, the campaign will launch 5 weeks from the time of opportunity submission.",
	},
	{
		question: "How will you incorporate non-bed tax collectors into leisure placements?",
		answer: "The Walton County Tourism Department has opened up most digital opportunities to both bed tax and non-bed tax collectors.",
	},
	{
		question: "How can I participate in the Connected TV Co-Op Video Ad opportunities if I do not not have video footage?",
		answer: "Both the annual and supplemental co-op programs include strategic opportunities that will involve a collaborative effort between partners and the Walton County Tourism Department. We will work with each partner to customize creative according to your needs. We ask that you provide assets at the time of submission so we can review before creative development. If assets are not available, we will work with each partner to identify assets owned by the Walton County Tourism Department. For the supplemental program, please note that this placement begins 6 weeks after the initial opportunity submission. For the annual program, placements will run according to the selected flight dates.",
	},
	{
		question: "When do I need to provide assets?",
		answer: `Prior to the run dates for your selections, you will receive communication via email outlining asset needs for your upcoming placements. Once provided, you will receive an additional follow-up email for approval of final assets.`,
	},
]