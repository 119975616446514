// Make sure backend sale's dates are identical
export const SALES_BEGIN = 'June 10, 2024 09:00:00';
export const SALES_END = 'June 14, 2024 16:00:00';

export const timeHasElapsed = () => {
	const today = new Date();
	const startline = new Date(SALES_BEGIN);
	const deadline = new Date(SALES_END);
	return !(today > startline && today < deadline)
}

// Swap for emergency
// export const timeHasElapsed = () => {
// 	const today = new Date();
// 	const deadline = new Date(SALES_END);
// 	const timeRemaining = deadline - today;
// 	return timeRemaining <= 0;
// }