export const BASE_URL = process.env.REACT_APP_BASE_URL || ""
export const BASE_CDN_URL = "https://coop.visitsouthwalton.com"
export const BASE_FETCH_URL = `${BASE_URL}/api`

export const ALL_OPPORTUNITIES_FETCH_URL = `${BASE_FETCH_URL}/opportunities/opportunity/all/?_join=true`
export const UPDATE_OPPORTUNITY_FETCH_URL = `${BASE_FETCH_URL}/opportunities/opportunity/updateAll`
export const ALL_CATEGORIES_FETCH_URL = `${BASE_FETCH_URL}/opportunities/category/all/`

export const ADD_ACCOUNT_FETCH_URL = `${BASE_FETCH_URL}/partner/add`
export const GET_ACCOUNT_FETCH_URL = `${BASE_FETCH_URL}/partner/get`
export const GET_ACCOUNT_DRIVE_URL = `${BASE_FETCH_URL}/partner/drive`
export const UPDATE_ACCOUNT_FETCH_URL = `${BASE_FETCH_URL}/partner/update`