import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';

import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';

import DeadlineApproaching from "../common/DeadlineApproaching";
import HeaderLogo from './HeaderLogo';
import MyAccount from './MyAccount';
import SignInOrUp from './SignInOrUp';
import Navbar from './Navbar';

import { URLS } from '../../constants/urls';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	appbar: {
		zIndex: "999",
		boxShadow: "none",
		borderBottom: "1px solid #E9E9E9",
		transition: "transform 0.3s ease-in-out",
	},
	brandbarWrapper: {
		width: "100%",
		backgroundColor: theme.palette.primary.light,
	},
	navbarWrapper: {
		width: "100%",
		backgroundColor: "white",
	},
	alertWrapper: {
		width: "100%",
		backgroundColor: "#14506B",
	},
	toolbar: {
		minHeight: 128,
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			paddingBottom: "1rem"
		}
	},
	smallBottomGutter: {
		marginBottom: "0.25rem",
	},
	center: {
		display: "flex",
		justifyContent: "center",
	},
	outlinedBtn: {
		borderColor: theme.palette.primary.light,
	},
}));

const SiteAppBar = ({ isAuthenticated }) => {
	const classes = useStyles();
	const location = useLocation();
	const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
	const [visible, setVisible] = useState(true);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.pageYOffset;
			setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
			setPrevScrollPos(currentScrollPos);
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [prevScrollPos, visible]);

	return (
		<div className={classes.root}>
			<AppBar style={{ transform: visible ? "translateY(0)" : "translateY(-164px)" }} position="fixed" className={classes.appbar}>
				<div className={classes.brandbarWrapper}>
					<Toolbar className={classes.toolbar}>
						<Container maxWidth="lg">
							<Grid container direction="row">
								<Grid item container justifyContent={{ xs: "center", md: "flex-end" }} alignItems="flex-end" xs={12} md={7}>
									<HeaderLogo />
								</Grid>
								<Grid item container direction="row" justifyContent={{ xs: "center", md: "flex-end" }} alignItems="center" xs={12} md={5}>
									{isAuthenticated && (location.pathname !== URLS.signup && location.pathname !== "/")
										? (
											<MyAccount />
										)
										: (
											<SignInOrUp isAuthenticated={isAuthenticated} />
										)
									}
								</Grid>
							</Grid>
						</Container>
					</Toolbar>
				</div>
				{isAuthenticated && (location.pathname !== URLS.signup && location.pathname !== "/") && (
					<div className={classes.navbarWrapper}>
						{isAuthenticated && <Navbar />}
					</div>
				)}
				{isAuthenticated && location.pathname === URLS.opportunities && (
					<div className={classes.alertWrapper}>
						<Container maxWidth="lg">
							<DeadlineApproaching />
						</Container>
					</div>
				)}
			</AppBar>
		</div>
	);
}

export default SiteAppBar;