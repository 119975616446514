import React, { useContext } from "react";

import Grid from "@mui/material/Grid";
import { OpportunitiesContext } from "../../OpportunitiesContext";

import Selector from "../common/Selector";

const CategoryFilter = ({
    handleChange,
    filters,
	type
}) => {
    const { categories, suppCategories } = useContext(OpportunitiesContext);
	const cateOptions = [...[{ value: '', label: 'All' }], ...categories];
	const suppCateOptions = [...[{ value: '', label: 'All' }], ...suppCategories];

    return (
        <Selector
            handleChange={handleChange}
            options={type !== "Supplemental" ? cateOptions : suppCateOptions}
            showLabel={true}
            labelText="CATEGORY"
            id="category"
            value={filters["category"] == null ? '' : filters["category"]}
			disablePleaseSelect={true}
        />
    )
}

const OpportunityCostFilter = ({
    handleChange,
    filters,
	type
}) => {

    const options = [
		{ value: '', label: 'All' },
        //{ value: 300, label: "$300" },
        { value: 600, label: "$600 or less" },
        { value: 750, label: "$750 or less" },
        { value: 900, label: "$900 or less" },
        { value: 1000, label: "$1,000 or less" },
        { value: 1800, label: "$1,800 or less" },
        { value: 2500, label: "$2,500 or less" },
        { value: 3000, label: "$3,000 or less" },
        { value: 4800, label: "$4,800 or less" },
    ];

	const suppOptions = [
		{ value: '', label: 'All' },
        { value: 200, label: "$200" },
        { value: 250, label: "$250 or less" },
        { value: 300, label: "$300 or less" },
        { value: 600, label: "$600 or less" },
        { value: 1000, label: "$1,000 or less" },
    ];

    return (
        <Selector
            handleChange={handleChange}
            options={type !== "Supplemental" ? options : suppOptions}
            labelText="OPPORTUNITY COST"
            id="opportunity-cost"
            value={filters["opportunity-cost"] == null ? '' : filters["opportunity-cost"]}
			disablePleaseSelect={true}
        />
    )
}

const SortSelector = ({
    handleChange,
    sortBy,
}) => {

    const options = [
        { value: "mostRecent", label: "Most Recent" },
        { value: "alphabetical", label: "Alphabetical" },
        { value: "priceDesc", label: "$$$ - $"},
        { value: "priceAsc", label: "$ - $$$"},
    ];

    return (
        <Selector
            handleChange={handleChange}
            options={options}
            labelText="SORT BY"
            id="sort-by"
            value={sortBy}
			disablePleaseSelect={true}
        />
    )
}

const OpportunityFilters = ({
    filters,
    handleFilterChange,
    sortBy,
    handleSortChange,
	type
}) => {

    return (
        <>
            <Grid item xs={12} md={4}>
                <CategoryFilter
                    handleChange={handleFilterChange}
                    filters={filters}
					type={type}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <OpportunityCostFilter
                    handleChange={handleFilterChange}
                    filters={filters}
					type={type}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <SortSelector
                    handleChange={handleSortChange}
                    sortBy={sortBy}
                />
            </Grid>
        </>
    )
}

export default OpportunityFilters;