import React, { useContext } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { AccountContext } from "../AccountContext";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(2),
        },
        // flexGrow: 1,
        marginTop: theme.spacing(35),
        minHeight: "100vh",
    },
	year: {
		color: "#6B6B6B",
		fontWeight: "bold",
		fontSize: "18px",
		letterSpacing: "0.9px",
		lineHeight: "22px",
		marginBottom: "27px",
		marginTop: "41px"
	},
	label: {
		color: "#525252",
		fontSize: "18px",
		fontWeight: "bold",
		letterSpacing: "0.9px",
		lineHeight: "22px",
		margin: "0"
	},
	sign: {
		margin: "0",
		color: "#FF1414",
	},
	signed: {
		color: "#13731C",
	},
	buttonRight: {
		textAlign: "right",
		"& > a:nth-child(2)": {
			marginLeft: "1rem"
		}
	},
	borders: {
		borderTop: "1px solid #979797",
		borderBottom: "1px solid #979797",
		padding: "1rem 0",
		alignItems: "center"
	}
}));

const Contracts = () => {
    const classes = useStyles()
    const { accountData } = useContext(AccountContext)

	let { contracts } = accountData

	contracts.sort((a, b) => (Number(b.year) - Number(a.year)))

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Typography variant="h4">My Contracts</Typography>
			<Grid container direction="row">
				{contracts.length ? (contracts.map(({ year, label, signed, contract, invoice }, i) => (
					<>
						<Grid item xs={12}>
							<Typography variant="body1" className={classes.year}>{ year }</Typography>
						</Grid>
						<Grid item className={classes.borders} xs={6}>
							<Typography variant="body1" className={classes.label}>
								{ label }
								<Typography className={`${classes.sign} ${signed ? classes.signed : ""}`}>{ signed ? "Signed" : "Unsigned" }</Typography>
							</Typography>
						</Grid>
						<Grid item className={classes.borders} xs={6}>
							<div className={`${classes.buttonRight}`}>
								{contract && <Button
									variant="outlined"
									color="primary"
									href={contract}
									target="_blank"
									size="large"
								>
									View Contract
								</Button>}
								{invoice && <Button
									variant="outlined"
									color="primary"
									href={invoice}
									target="_blank"
									size="large"
								>
									View Invoice
								</Button>}
							</div>
						</Grid>
					</>
				))) : <Typography variant="h5">No Contracts Available for viewing</Typography>}
			</Grid>
        </Container>
    )
}

export default Contracts;