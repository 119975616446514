import React, { /* useContext, */ useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
// import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faBullhorn } from '@fortawesome/free-solid-svg-icons';
// import { faTimesCircle as faRegularTimesCircle } from '@fortawesome/free-regular-svg-icons';

import formatCurrency from "../../helpers/formatCurrency";
import formatDateTime from "../../helpers/formatDateTime";

import parse from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import Modal from "../common/Modal";
import useModal from "../../hooks/useModal";
// import { OpportunitiesContext } from "../../OpportunitiesContext";
// import { LocalConvenienceStoreOutlined } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
	root: {
		// minHeight: "17.6rem",
		marginBottom: theme.spacing(4),
		padding: theme.spacing(3),
		paddingTop: theme.spacing(5),
		position: "relative"
	},
	cardActionArea: {
		minHeight: "13.6rem",
		display: "flex",
		justifyContent: "start",
		alignItems: "start",
		padding: theme.spacing(3),
		"&:hover": {
			backgroundColor: "#3B7156",
			"& *": {
				color: "white",
			},
		}
	},
	cardActionsRoot: {
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "flex-end",
		textAlign: "right",
		[theme.breakpoints.down('xl')]: {
			textAlign: "left"
		}
	},
	cost: {
		fontSize: "40px",
		fontWeight: 500,
		letterSpacing: "2px",
		lineHeight: "48px",
		textAlign: "right",
		marginBottom: "34px",
		[theme.breakpoints.down('xl')]: {
			textAlign: "left"
		}
	},
	iconButton: {
		color: theme.palette.text.primary,
		"&:hover": {
			color: "#C81A1A",
		}
	},
	cardContent: {
		padding: 0,
		height: "100%",
		width: "100%",
	},
	moreDetails: {
		marginBottom: 0,
	},
	opportunityName: {
		fontWeight: "bold",
		color: "#3B7156",
		letterSpacing: "1.5px",
		textTransform: "uppercase",
		wordWrap: "break-word",
		marginBottom: "4px"
	},
	opportunityType: {
		width: "fit-content",
		fontSize: "16px",
		fontWeight: "bold",
		letterSpacing: "0.8px",
		lineHeight: "19px",
		padding: "4px 6px",
		textTransform: "uppercase",
		color: "white",
		backgroundColor: "#3B7156",
		marginBottom: "8px"
	},
	runDate: {
		fontWeight: 500,
		textTransform: "uppercase",
		marginBottom: 0,
	},
	stripe: {
		position: "absolute",
		width: "100%",
		top: "0",
		left: "0",
		backgroundColor: "#FFE9CC",
		padding: `6px ${theme.spacing(3)}`,
		color: "#57270B",
		fontSize: "14px",
		fontWeight: 500,
		letterSpacing: "0.7px",
		lineHeight: "17px",
		textTransform: "uppercase"
	},
}));

const SelectedOpportunitySuppCard = ({ opportunity, opportunities, handleRemove, onDetailsClick, selectedProperties, onSelector }) => {

	const relatedPropertiesNames = selectedProperties.map(prop => prop.organizationName)

	const classes = useStyles();

	let estimatedRunDate = null;

	let hoursTil = null

	// const {
	// 	handleSelect,
	// 	selectIsDisabled,
	// 	budgetState,
	// } = useContext(OpportunitiesContext);

	if (opportunity.type === "Supplemental") {
		estimatedRunDate = opportunity.properties.estimatedRunDate
		hoursTil = Math.round((Math.abs(new Date().getTime() - new Date(opportunity.properties.expireDate))) / 36e5)
	}

	if (!opportunity) return null;

	return (
		<>
			<Card className={classes.root} variant="outlined">

				<div className={classes.stripe}>Opportunity Reserved {hoursTil && (`| ${hoursTil} Hours remaining to sign contract`)}</div>

				<Grid container>
					<Grid item xs={12} lg={9}>
						<CardContent
							classes={{ root: classes.cardContent }}
						>
							<Box
								className={classes.cardContent}
								display="flex"
								flexDirection="column"
								justifyContent="flex-start"
							>
								<Box>
									<Typography className={classes.opportunityType}>
										<FontAwesomeIcon style={{ marginRight: "8px" }} icon={faBullhorn} size="sm" />Supplemental Program
									</Typography>
									<Typography variant="h5" className={classes.opportunityName}>
										{opportunity.name}
										<br />
										<small href="#">{`Reserved for ${relatedPropertiesNames.join(relatedPropertiesNames.length === 2 ? ' & ' : ', ').trim()} ${relatedPropertiesNames.length > 3 ? "& More..." : ""}`}</small>
									</Typography>
									<Typography className={classes.runDate}>
										<strong>Reserved </strong> {formatDateTime(opportunity.properties.reserveDate)} | <strong>Estimated Run Date </strong>{formatDateTime(estimatedRunDate)}
									</Typography>
								</Box>
								<Box
									display="flex"
									flexDirection="column-reverse"
									flexGrow={1}
								>
								</Box>
							</Box>
						</CardContent>

					</Grid>

					<Grid item lg={3} md={6}>

						<CardActions
							classes={{ root: classes.cardActionsRoot }}
						>
							<Box>
								<Typography variant="h5" className={classes.cost}>
									{formatCurrency(opportunity.cost)}
								</Typography>
								<Button
									color="primary"
									variant="outlined"
									aria-label="remove an opportunity"
									onClick={() => handleRemove(opportunity.id, opportunity.properties.propertyUuid)}
								>
									Release Reservation
								</Button>
								<Button
									style={{ marginTop: "17px" }}
									color="primary"
									variant="outlined"
									aria-label="remove an opportunity"
									onClick={() => onSelector(opportunity.id, opportunity.properties.propertyUuid)}
								>
									Complete Reservation
								</Button>
							</Box>
						</CardActions>

					</Grid>
				</Grid>

				<Box>
					<CardActionArea
						onClick={() => onDetailsClick(opportunity)}
					>
						<Typography className={classes.moreDetails}>
							More Details <FontAwesomeIcon icon={faCamera} size="lg" />
						</Typography>
					</CardActionArea>
				</Box>


			</Card>
		</>
	);
}

export default SelectedOpportunitySuppCard;