import makeStyles from '@mui/styles/makeStyles';
import { NavLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { URLS } from '../../constants/urls';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    userIcon: {
      height: "1.25rem",
      width: "1.25rem",
    },
    myAccount: {
      color: "#FFF",
      marginBottom: "0",
    },
    button: {
        "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
        },
        width: "13.65rem",
        textDecoration: "none",
    },
    activeButton: {
		[theme.breakpoints.up('md')]: {
			backgroundColor: theme.palette.secondary.dark,
		},
    }
  }));

const MyAccount = () => {
    const classes = useStyles();

    return (
        <Box
            display="flex"
            alignItems="center"
            height="100%"
            role="button"
            component={NavLink}
            to={URLS.account}
            className={classes.button}
            activeClassName={classes.activeButton}
        >
            <Grid container>
                <Grid item container direction="row" justifyContent="center">
                    <img
                        src="images/icons/User.svg"
                        alt="user icon"
                        className={classes.userIcon}
                    />
                </Grid>
                <Grid item container direction="row" justifyContent="center">
                    <Typography variant="h6" className={classes.myAccount}>
                        MY ACCOUNT
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default MyAccount;