import React from "react";

import { TextField } from "@mui/material";
import Selector from "../../common/Selector";
import PhoneInput from "../../common/PhoneInput";

import STATES from "../../../constants/usa-states";

const EditCompanyInfoForm = ({ formData, handleChange, disableEmailField }) => {
	return (
		<>
			<TextField
				id={`organizationName`}
				required
				label="Organization Name"
				autoComplete="organization"
				variant="outlined"
				value={formData["organizationName"]}
				onChange={handleChange}
				fullWidth
			/>

			<TextField
				id={`contactName`}
				required
				label="Contact Name"
				autoComplete="name"
				variant="outlined"
				value={formData["contactName"]}
				onChange={handleChange}
				fullWidth
			/>

			<TextField
				id={`billingAddress`}
				required
				label="Billing Address"
				autoComplete="street-address"
				variant="outlined"
				value={formData["billingAddress"]}
				onChange={handleChange}
				fullWidth
			/>

			<Selector
				id={`state`}
				required
				autoComplete="address-line1"
				options={STATES}
				labelText="State"
				value={formData["state"] ? formData["state"] : 'FL'}
				handleChange={handleChange}
			/>

			<TextField
				id={`city`}
				required
				label="City"
				variant="outlined"
				value={formData["city"]}
				onChange={handleChange}
				fullWidth
			/>

			<TextField
				id={`zip`}
				required
				label="Zip Code"
				autoComplete="postal-code"
				variant="outlined"
				value={formData["zip"]}
				inputProps={{ pattern: "[0-9]{5}" }}
				// https://github.com/mui-org/material-ui/issues/5309#issuecomment-355462588
				onInput={(e) => {
					e.target.value = e.target.value.slice(0, 5)
				}}
				onChange={handleChange}
				fullWidth
				min={5}
			/>

			<PhoneInput
				id={`phone`}
				required
				value={formData["phone"]}
				handleChange={handleChange}
			/>

			<TextField
				id={`email`}
				required
				label="Email"
				autoComplete="email"
				variant="outlined"
				value={formData["email"]}
				InputProps={{ type: "email" }}
				onChange={handleChange}
				disabled={disableEmailField}
				fullWidth
			/>
		</>
	)
}

export default EditCompanyInfoForm;