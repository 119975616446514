import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import Collapse from "@mui/material/Collapse";

import Image from 'material-ui-image';

import { styled } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faImage } from '@fortawesome/free-regular-svg-icons';

import GooglePicker from 'react-google-picker';
import { GOOGLE_CLIENT_ID, GOOGLE_API_KEY } from '../constants/constants';

import { AccountContext } from "../AccountContext";
import { OpportunitiesContext } from "../OpportunitiesContext";
import { BASE_CDN_URL } from "../constants/api";

import parse from 'html-react-parser';
import { useHistory, useParams } from 'react-router-dom';
import { URLS } from "../constants/urls";

import {
	BASE_FETCH_URL
} from "../constants/api";

// var canUseDOM = !!(
// 	(typeof window !== 'undefined' &&
// 		window.document && window.document.createElement)
// );

const Input = styled('input')({
	display: 'none',
});

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(2),
		},
		// flexGrow: 1,
		marginTop: theme.spacing(35),
		minHeight: "80vh",
	},
	textCenter: {
		textAlign: "center"
	},
	header: {
		color: "#6B6B6B",
		fontSize: "24px",
		fontWeight: "bold",
		letterSpacing: "1.2px",
		lineHeight: "29px",
		textAlign: "center",
		marginBottom: "59px"
	},
	body: {
		fontSize: "18px",
		fontWeight: 300,
		letterSpacing: "0.9px",
		lineHeight: "24px",
	},
	link: {
		color: "#1C7399"
	},
	button: {
		paddingLeft: "112px",
		paddingRight: "112px",
		margin: "5rem 0",
		fontSize: "20px"
	},
	bigButton: {
		padding: "13.5px 98px",
		color: "#086790",
		fontSize: "20px",
		fontWeight: 500,
		letterSpacing: "0",
		lineHeight: "24px"
	}
}));

const Finalize = () => {
	const { accountData } = useContext(AccountContext);

	const [status, setStatus] = useState(null);
	const [open, setOpen] = React.useState(false);
	// const [image, setImage] = useState(null);

	const onChange = (e) => {
		let formData = new FormData();
		var fileField = document.querySelector("input[type='file']");
		formData.append('file', fileField.files[0]);
		const config = {
			method: 'POST',
			body: formData,
		};
		fetch(`${BASE_FETCH_URL}/g/drive/upload?id=${accountData.id}&driveId=${accountData.driveId}`, config)
			.then(res => res.json())
			.then(({ data }) => {
				setOpen(false);
				if (data.data === "OK") {
					setStatus({ message: `${fileField.files[0].name} uploaded successfully!`, severity: "success" })
					setImageList([...imageList, ...[fileField.files[0].name]])
					setAllowButton(true)
				} else {
					setStatus({ message: "An error occured, please wait and try again later.", severity: "info" })
				}
				setOpen(true);
			})
	}

	const onImgLoad = ({ target: img }) => {
		const { offsetHeight, offsetWidth } = img;
		if (offsetHeight > offsetWidth) {
			img.style.height = "80%"
		}
	}

	const getPropertyDetail = (propertyID) => {
        for (let property of accountData.properties) {
            if (property.uuid === propertyID)return property;
        }
        return null;
    }

	const { id } = useParams()

	const parsed = id.split("_")
	const parsedId = parsed[0]
	const parsedPropertyUuid = parsed[2]

	const history = useHistory()

	const [allowButton, setAllowButton] = useState(true);

	const [imageList, setImageList] = useState([]);

	const [formComplete, setFormComplete] = useState(false);

	const {
		opportunities,
		finalize
	} = useContext(OpportunitiesContext);

	const opportunity = opportunities.find(o => o.id === parsedId);

	if (!opportunity) history.push(URLS.suppOpportunities)

	const { name, Category, image } = opportunity

	let latestReservedOpportunity = {}

	const latestReservedOpportunityTmp = opportunity.latestReservedOpportunity.filter(op => op.properties.propertyUuid === parsedPropertyUuid)[0]

	if (latestReservedOpportunityTmp) latestReservedOpportunity = latestReservedOpportunityTmp

	let propertyName = ""

	if (!!getPropertyDetail(latestReservedOpportunity.properties.propertyUuid)) {
		propertyName = getPropertyDetail(latestReservedOpportunity.properties.propertyUuid).organizationName
	}

	const classes = useStyles();

	const SCOPE = ['https://www.googleapis.com/auth/drive'];

	return (
		<Container maxWidth="lg" className={classes.root}>
			{formComplete ?
				(
					<Container maxWidth="lg">
						<Grid item align="center" xs={12}>
							<Grid item align="center" xs={7}>
								<Typography align="center" style={{ color: "#3B7156", "fontSize": "18px", "fontWeight": "bold", margin: 0 }} variant="h5">WE'VE FINALIZED YOUR RESERVATION FOR</Typography>
								<Typography align="center" style={{ color: "#3B7156", "fontSize": "30px", "fontWeight": "bold", marginBottom: "60px" }} variant="h6">{name}<br /><strong>{propertyName ? ` with ${propertyName}` : ""}</strong></Typography>
								<Typography className={classes.body}>Now that your reservation is complete, you will receive a contract through an email from DocuSign, at which point you give the official approval for the opportunity to be purchased. The opportunity will be released if the contract is not signed.</Typography>
								<div style={{ textAlign: "center" }}>
									<Button
										onClick={() => {
											history.push("/supplemental-opportunities")
										}}
										className={classes.bigButton}
										variant="outlined"
										color="secondary"
										size="large"
									>
										Back To Opportunities
									</Button>
								</div>
							</Grid>
						</Grid>
					</Container>
				)
				:
				(
					<>
						<Typography style={{ textAlign: "center", marginBottom: "82px" }} variant="h4">Finalize Reservation for {name}<br /><strong>{propertyName ? ` with ${propertyName}` : ""}</strong></Typography>
						{Category[0].object.description &&
							<>
								<Typography className={classes.header} style={{ textAlign: "center", marginTop: "52px" }} variant="h5">Assets for Inclusion:</Typography>
								<Container maxWidth="lg">
									<Grid item align="center" xs={12}>
										<div className={classes.instructions}>
											<Typography>{parse(Category[0].object.description)}</Typography>
										</div>
									</Grid>
								</Container>
							</>
						}
						{/*<br />

						<Typography variant="body1" style={{ color: "#AC0F0F", textAlign: "center" }}>
							"View Assets" is currently experiencing an issue that we are working to resolve. If you'd like to continue through, please click <strong>Advanced</strong> and press <strong>Continue</strong>.
							<br />
							<small><strong>Note:</strong> Google marks this as unsafe due to a verification issue that we are working to resolve.</small>
						</Typography>

						<br />
						<br />*/}
						<div className={classes.textCenter}>
							{/*{status !== null && <Collapse in={open}><Alert onClose={() => setOpen(false)} severity={status.severity}>{status.message}</Alert></Collapse>}
							<Grid direction="row" container spacing={2}>
								<Grid style={{ textAlign: "right" }} item xs={6}>
									<form id="fileUpload">
										<label htmlFor="contained-button-file">
											<Input onChange={onChange} id="contained-button-file" single type="file" />
											<Button
												variant="contained"
												component="span"
												size="large"
												endIcon={<FontAwesomeIcon icon={faImage} />}
											>
												Upload Assets
											</Button>
										</label>
									</form>
								</Grid>
								<Grid style={{ textAlign: "left" }} item xs={6}>
									<GooglePicker clientId={GOOGLE_CLIENT_ID}
										developerKey={GOOGLE_API_KEY}
										scope={SCOPE}
										onChange={data => console.log('on change:', data)}
										onAuthFailed={data => console.log('on auth failed:', data)}
										multiselect={true}
										navHidden={true}
										authImmediate={false}
										viewId={'DOCS'}
										createPicker={(google, oauthToken) => {
											const googleViewId = google.picker.ViewId.DOCS;

											const folderId = accountData.driveId

											// const docsUploadView = new google.picker.DocsUploadView()
											// 	.setParent(folderId)
											// 	.setIncludeFolders(true)

											const docsView = new google.picker.DocsView(googleViewId)
												.setParent(folderId)
												.setEnableDrives(true)
												.setMode(google.picker.DocsViewMode.GRID)

											const picker = new window.google.picker.PickerBuilder()
												// .addView(docsUploadView)
												.addView(docsView)
												.setOAuthToken(oauthToken)
												.setDeveloperKey(GOOGLE_API_KEY)
												.setCallback((data) => {
													if (data.action === google.picker.Action.PICKED) {
														var fileName = data.docs[0].name;
														setImageList([...imageList, ...[fileName]])
														setAllowButton(true)
														// picker.build().setVisible(true);
													}
												});

											picker.build().setVisible(true);
										}}
									>
										<Button
											variant="contained"
											color="primary"
											size="large"
											endIcon={<FontAwesomeIcon icon={faPlusSquare} />}
										>
											Select Assets
										</Button>
										<div className="google"></div>
									</GooglePicker>
								</Grid>
							</Grid>

							<Typography style={{ marginTop: "1rem", fontSize: "1.25rem" }}>
								{imageList.length > 0 && (<>
									<strong>Files uploaded: <br /></strong>
									{imageList.map(txt => parse(`${txt}<br />`))}
								</>)}
							</Typography>*/}

							{allowButton ?
								<Button
									onClick={() => {
										window.scrollTo(0, 0);
										finalize(id)
										setFormComplete(true);
									}}
									className={classes.button}
									variant="contained"
									color="primary"
									size="large"
								>
									Submit Order / Generate Contract
								</Button> : <div style={{ marginBottom: "5rem" }} />
							}
						</div>
						<hr />
						<br />
						{Category[0].object.information &&
							<>
								<Typography className={classes.header} style={{ textAlign: "center" }} variant="h5">Internal Information Only:</Typography>
								<Typography className={classes.body}>{parse(Category[0].object.information)}</Typography>
							</>
						}
						<Container style={{ textAlign: "center" }} maxWidth="lg">
							<Grid item style={{ margin: "0 auto" }} alignItems="center" xs={4}>
								<Grid item xs={12}>
									<Typography className={classes.header} style={{ textAlign: "center" }} variant="h6">Example Ad:</Typography>
									<img
										alt=""
										src={`${BASE_CDN_URL}${image}`}
										onLoad={onImgLoad}
										style={{
											display: "block",
											verticalAlign: "middle",
											maxWidth: "100%",
											margin: "0 auto",
										}}
									/>
								</Grid>
							</Grid>
						</Container>
						<Typography className={classes.body}>Should you have any questions please reach out to <a className={classes.link} href="mailto:vswpartners@z-comm.com">vswpartners@z-comm.com</a></Typography>
					</>
				)}
		</Container>
	)
}

export default Finalize;