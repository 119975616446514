import React, { useContext } from "react";
import { Link as RouterLink } from 'react-router-dom';

import { useHistory } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import { Box, Container } from "@mui/material";
import Typography from "@mui/material/Typography";

import SignupStepper from "../components/signup/SignupStepper";
import useSignupStepper from "../hooks/useSignupStepper";

import { AccountContext } from "../AccountContext";
import { URLS } from "../constants/urls";

import { timeHasElapsed } from "../constants/deadlines";

const STEP_LENGTH = 5;

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: "10rem",
		marginBottom: "5rem",
		minHeight: "100vh",
	}
}));

/**
 * Page component for the Signup stepped form.
 */
const Signup = () => {
	const classes = useStyles();
    const history = useHistory();

	const { accountData, updateAccount } = useContext(AccountContext);

	if (accountData && localStorage.getItem(`${accountData.googleId ? accountData.googleId : accountData.facebookId }-signup`) === "true") history.push(timeHasElapsed() ? URLS.suppOpportunities : URLS.opportunities)

	const formRef = React.useRef(null);

	const {
		activeStep,
		handleNext,
		handleBack,
		getStepContent,
	} = useSignupStepper({accountData, updateAccount, formRef});

	const handleSubmit = (e) => {
		handleNext(e);
		localStorage.setItem(`${accountData.googleId ? accountData.googleId : accountData.facebookId }-signup`, "true")
		history.push(timeHasElapsed() ? URLS.suppOpportunities : URLS.opportunities);
	}

	return (
		<>
			<Container align="center" maxWidth="sm" className={classes.root}>
				<form action="#" ref={formRef}>
					<Box>
						{getStepContent(activeStep)}
					</Box>
					<SignupStepper
						activeStep={activeStep}
						handleNext={handleNext}
						handleBack={handleBack}
						handleSubmit={handleSubmit}
						steps={STEP_LENGTH}
					/>
					<Typography>
						Please read these <RouterLink to={URLS.terms}>important terms.</RouterLink>
					</Typography>
				</form>
			</Container>
		</>
	)
}

export default Signup;