import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Pagination from '@mui/material/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const PaginationController = ({handleChange, count, page}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Pagination count={count} page={page} defaultPage={1} shape="rounded" onChange={handleChange}/>
    </div>
  );
}

export default PaginationController;