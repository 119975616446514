// import React from 'react';
// import FacebookLogin from 'react-facebook-login';
// import { FACEBOOK_APP_ID } from '../../constants/constants';

const FacebookLoginButton = ({ login, signup }) => {
	return null;
	// return (
	// 	<FacebookLogin
	// 		containerStyle={{ width: "18rem" }}
	// 		buttonStyle={{ width: "100%" }}
	// 		appId={FACEBOOK_APP_ID}
	// 		autoLoad={false}
	// 		size="small"
	// 		fields="name,email,picture"
	// 		textButton={signup ? 'Sign Up with Facebook' : 'Login with Facebook'}
	// 		callback={login}
	// 		icon="fa-facebook"
	// 	/>
	// )
}

export default FacebookLoginButton;