import React from 'react';
import { NavLink } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';

import Container from '@mui/material/Container';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from '@mui/material/Toolbar';

import RemainingBudget from "./RemainingBudget";

import { NAV_ARRAY } from '../../constants/urls';

const useStyles = makeStyles((theme) => ({
    appbar: {
        backgroundColor: "white",
    },
    navlist: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
		overflowX: "scroll",
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    navItemText: {
        marginBottom: "0",
        '&:hover': {
            textDecoration: "underline",
            textDecorationColor: theme.palette.primary.light,
            textDecorationThickness: "3px",
            textUnderlineOffset: "2px",
        },
		textAlign: "left",
		fontSize: "14px"
    },
    activeNavItemText: {
        textDecoration: "underline",
        textDecorationColor: theme.palette.primary.light,
        textDecorationThickness: "3px",
        textUnderlineOffset: "2px",
    },
    budgetWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    },
}));

const Navbar = () => {
    const classes = useStyles();

    return (
        <Toolbar>
            <Container maxWidth="lg">
                <List
                    component="nav"
                    aria-labelledby="main navigation"
                    className={classes.navlist}
                >
                    {NAV_ARRAY.map(({ title, path, hide }) => (
                        !hide ? <ListItem
                            component={NavLink}
                            to={path}
                            exact
                            activeClassName={classes.activeNavItemText}
                            key={title}
                        >
                            <ListItemText
                                primary={title.toUpperCase()}
                                primaryTypographyProps={{
                                    variant: "h6",
                                    className: classes.navItemText,
                                }}
                            />
                        </ListItem> : null
                    ))}
                    <div classes={classes.budgetWrapper}>
                        <RemainingBudget />
                    </div>
                </List>
            </Container>
        </Toolbar>
    )
}

export default Navbar;