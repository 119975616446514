import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faTimesCircle as faSolidTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle as faRegularTimesCircle } from '@fortawesome/free-regular-svg-icons';

import formatCurrency from "../../helpers/formatCurrency";
import formatDateTime from "../../helpers/formatDateTime";

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: "17.6rem",
		marginBottom: theme.spacing(4),
	},
	cardActionArea: {
		minHeight: "13.6rem",
		display: "flex",
		justifyContent: "start",
		alignItems: "start",
		padding: theme.spacing(3),
		"&:hover": {
			backgroundColor: "#3B7156",
			"& *": {
				color: "white",
			},
		}
	},
	cardActionsRoot: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		display: "flex",
		justifyContent: "space-between",
	},
	cost: {
		fontWeight: "bold",
		letterSpacing: "1.5px",
		marginBottom: 0,
	},
	iconButton: {
		color: theme.palette.text.primary,
		"&:hover": {
			color: "#C81A1A",
		}
	},
	cardContent: {
		padding: 0,
		height: "100%",
		width: "100%",
	},
	moreDetails: {
		marginBottom: 0,
	},
	opportunityName: {
		fontWeight: "bold",
		color: "#3B7156",
		letterSpacing: "1.5px",
		textTransform: "uppercase",
		wordWrap: "break-word"
	},
	runDate: {
		fontWeight: 500,
		textTransform: "uppercase",
		marginBottom: 0,
	},
}));

const SelectedOpportunityCard = ({opportunity, handleRemove, onDetailsClick, propertyDetail }) => {
	const classes = useStyles();

	let estimatedRunDate = null;

	if (opportunity.type === "Supplemental") estimatedRunDate = opportunity.properties.estimatedRunDate

	const [removeButtonHovering, setRemoveButtonHovering] = useState(false);
	if (!opportunity) return null;

	return (
        <Card className={classes.root} variant="outlined">

			<CardActionArea
				className={classes.cardActionArea}
				onClick={() => onDetailsClick(opportunity)}
			>
				<CardContent
					classes={{root: classes.cardContent}}
				>
					<Box
						className={classes.cardContent}
						display="flex"
						flexDirection="column"
						justifyContent="flex-start"
					>
						<Box>
							<Typography className={classes.runDate}>
								{opportunity.type === "Supplemental" ? `Estimated Run Date ${formatDateTime(estimatedRunDate)}` : opportunity.runDate}
							</Typography>
							<Typography variant="h5" className={classes.opportunityName}>
								{opportunity.name}
							</Typography>
							{propertyDetail && <Typography>Reserved for {propertyDetail.organizationName} </Typography> }
						</Box>
						<Box
							display="flex"
							flexDirection="column-reverse"
							flexGrow={1}
						>
							<Box
								borderTop={1}
								borderBottom={1}
								paddingBottom={1}
								paddingTop={1}
							>
								<Typography className={classes.moreDetails}>
									More Details <FontAwesomeIcon icon={faCamera} size="lg"/>
								</Typography>
							</Box>
						</Box>
					</Box>
				</CardContent>
			</CardActionArea>

			<CardActions
				classes={{ root: classes.cardActionsRoot }}
			>
				<Typography variant="h5" className={classes.cost}>
					{formatCurrency(opportunity.cost)}
				</Typography>
				{opportunity.type === "Supplemental" ? (
					<Button
						color="primary"
						variant="outlined"
						aria-label="remove an opportunity"
						onClick={() => handleRemove(opportunity.id, opportunity.properties.propertyUuid)}
					>
						Release Reservation
					</Button>
				) : (
					<IconButton
                        color="primary"
                        aria-label="remove an opportunity"
                        classes={{ root: classes.iconButton }}
                        onClick={() => handleRemove(opportunity.id, opportunity.properties.propertyUuid)}
                        onMouseEnter={() => setRemoveButtonHovering(true)}
                        onMouseLeave={() => setRemoveButtonHovering(false)}
                        size="large">
						<FontAwesomeIcon
							icon={removeButtonHovering ? faSolidTimesCircle : faRegularTimesCircle}
							className={classes.iconButton}
						/>
					</IconButton>
				)}
			</CardActions>

		</Card>
    );
}

export default SelectedOpportunityCard;