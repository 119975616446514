import React, { useContext, useState } from "react";
import { Link as RouterLink } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

import { OpportunitiesContext } from "../../OpportunitiesContext";

import SelectedOpportunitySuppCard from "../opportunities/SelectedOpportunitySuppCard";
import OpportunityDetailPaper from "../opportunities/OpportunityDetailPaper";
import Modal from "../common/Modal";
import RecentlyReleasedOpportunities from "../opportunities/RecentlyReleasedOpportunities";

import Container from '@mui/material/Container';
import parse from 'html-react-parser';
import { useHistory } from 'react-router-dom';

import { URLS } from "../../constants/urls";

import useModal from "../../hooks/useModal";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(2),
		},
	},
	container: {
		padding: 0,
	}
}));

const SuppDashboard = ({ accountData }) => {
	const {
		opportunities,
		handleSelect,
		handleRemove,
		isLoading,
		// hasError,
		errorMessage
	} = useContext(OpportunitiesContext);

	const { showModal, closeModal, openModal } = useModal();
	const [modalOpportunity, setModalOpportunity] = useState({});

	const classes = useStyles();

	const onDetailsClick = (opportunity) => {
		setModalOpportunity(opportunity);
		openModal();
	}

	const getUnselectedOpportunities = () => {
		return opportunities.filter(o => !accountData.opportunities.includes(o.id))
	}

	const { properties } = accountData;
	const isMultiProperty = properties.length > 1;

	const count = accountData["SupplementalOpportunity"] ? accountData["SupplementalOpportunity"].length : 0

	const { showSuppModal, closeSuppModal, openSuppModal } = useModal();

	const onSelector = (opp, propertyUuid) => {
		let opt = opportunities.find(o => o.id === opp);
		opt = opt.latestReservedOpportunity.filter(op => op.properties.propertyUuid === propertyUuid)[0]
		setModalOpportunity(opt);
		openSuppModal();
	}
	const history = useHistory();

	return (
		<div className={classes.root}>
			<Typography variant="h4">Selected Supplemental Opportunities ({count})</Typography>

			{accountData["SupplementalOpportunity"].length > 0
				? opportunities.length > 0
					? (
						<>
							<Grid container direction="row">
								{accountData.SupplementalOpportunity.map((o) => {
									const selectedProperties = properties.filter(p => {
										return o.properties.propertyUuid === p.uuid
									})
									return (
										<Grid item xs={12} key={o.properties.uuid}>
											<SelectedOpportunitySuppCard
												opportunity={o}
												opportunities={opportunities}
												handleRemove={handleRemove}
												onDetailsClick={onDetailsClick}
												selectedProperties={selectedProperties}
												properties={properties}
												userIsMultiProperty={isMultiProperty}
												onSelector={onSelector}
											/>
										</Grid>
									)
								})}
							</Grid>
						</>
					)
					: null
				: (
					<Typography variant="body2" component="div">
						Looks like you haven't selected any opportunities yet
					</Typography>
				)
			}

			{/*{opportunities.length === 0
				? isLoading
					? <CircularProgress />
					: errorMessage && <Alert severity="error">{errorMessage}</Alert>
				: (
					<RecentlyReleasedOpportunities
						opportunities={getUnselectedOpportunities()}
						selectedOpportunities={accountData.opportunities}
						onDetailsClick={onDetailsClick}
						type="Supplemental"
					/>
				)
			}*/}
			<Button
				variant="outlined"
				color="primary"
				component={RouterLink}
				to={URLS.suppOpportunities}
				size="large"
			>
				VIEW ALL OPPORTUNITIES
			</Button>
			<Modal
				showModal={showModal}
				closeModal={closeModal}
			>
				{modalOpportunity.hasOwnProperty("properties") && (
					<OpportunityDetailPaper
						closeModal={closeModal}
						opportunity={modalOpportunity}
						selected={accountData.opportunities.includes(modalOpportunity.properties.uuid)}
						handleSelect={() => {
							closeModal()
							onSelector(modalOpportunity.id, modalOpportunity.properties.propertyUuid)
						}}
						handleRemove={() => {
							handleRemove(modalOpportunity.id, modalOpportunity.properties.propertyUuid)
							closeModal()
						}}
						disabled={true}
					/>
				)}
			</Modal>
			<Modal
				showModal={showSuppModal}
				closeModal={closeSuppModal}
			>
				{Object.keys(modalOpportunity).length > 0 &&
					<>
						{/* <Typography align="center" style={{ color: "#3B7156", "fontSize": "18px", "fontWeight": "bold", margin: 0 }} variant="h5">WE'VE TEMPORARILY RESERVED</Typography> */}
						<Typography align="center" style={{ color: "#3B7156", "fontSize": "30px", "fontWeight": "bold", marginBottom: "60px" }} variant="h6">{modalOpportunity.name}</Typography>
						<Container maxWidth="md">
							<Grid item align="center" xs={12}>
								<Grid item align="center" xs={12} md={9}>
									<div className={classes.instructions}>
										{modalOpportunity.Category[0].object.description &&
											<>
												<Typography className={classes.insHeader} align="center">To finalize your reservation you will need the following assets:</Typography>
												<Typography>{parse(modalOpportunity.Category[0].object.description)}</Typography>
											</>
										}
										<hr style={{ borderColor: "#E9E9E9", marginBottom: "28px" }} />
										<Typography className={classes.subline}>Note: We've reserved this opportunity for 72 hours. This opportunity is available in your dashboard. Please upload all requested assets within the 72 hour window to generate the opportunity contract. The opportunity will be released if the reservation is not finalized.</Typography>
										<Grid style={{ marginBottom: "1rem" }} item align="center" xs={12}>
											<Button size="large" style={{ margin: "10px" }} variant="outlined" color="primary" onClick={() => closeSuppModal()}>
												Save Reservation
											</Button>
											<Button size="large" style={{ margin: "10px" }} variant="outlined" color="secondary" onClick={() => history.push(`/finalize/${modalOpportunity.properties.uuid}_${modalOpportunity.properties.propertyUuid}`)}>
												Complete Reservation
											</Button>
										</Grid>
									</div>
								</Grid>
							</Grid>
						</Container>
					</>

				}
			</Modal>
		</div>
	)
}

export default SuppDashboard;