import React from "react";

import { TextField } from "@mui/material";
import Selector from "../../common/Selector";
import PhoneInput from "../../common/PhoneInput";

import STATES from "../../../constants/usa-states";

const CompanyInfoForm = ({ formData, handleChange, disableEmailField, propertyIndex }) => {
	return (
		<>
			<TextField
				id={`properties.${propertyIndex}.organizationName`}
				required
				label="Organization Name"
				autoComplete="organization"
				variant="outlined"
				value={typeof propertyIndex === 'number' ? formData["properties"][propertyIndex]["organizationName"] : ""}
				onChange={handleChange}
				fullWidth
			/>

			<TextField
				id={`properties.${propertyIndex}.contactName`}
				required
				label="Contact Name"
				autoComplete="name"
				variant="outlined"
				value={typeof propertyIndex === 'number' ? formData["properties"][propertyIndex]["contactName"] : ""}
				onChange={handleChange}
				fullWidth
			/>

			<TextField
				id={`properties.${propertyIndex}.billingAddress`}
				required
				label="Billing Address"
				autoComplete="street-address"
				variant="outlined"
				value={typeof propertyIndex === 'number' ? formData["properties"][propertyIndex]["billingAddress"] : ""}
				onChange={handleChange}
				fullWidth
			/>

			<Selector
				id={`properties.${propertyIndex}.state`}
				required
				autoComplete="address-line1"
				options={STATES}
				labelText="State"
				defaultValue={typeof propertyIndex === 'number' ? "FL" : ""}
				value={typeof propertyIndex === 'number' ? formData["properties"][propertyIndex]["state"] : ""}
				handleChange={handleChange}
			/>

			<TextField
				id={`properties.${propertyIndex}.city`}
				required
				label="City"
				variant="outlined"
				value={typeof propertyIndex === 'number' ? formData["properties"][propertyIndex]["city"] : ""}
				onChange={handleChange}
				fullWidth
			/>

			<TextField
				id={`properties.${propertyIndex}.zip`}
				required
				label="Zip Code"
				autoComplete="postal-code"
				variant="outlined"
				value={typeof propertyIndex === 'number' ? formData["properties"][propertyIndex]["zip"] : ""}
				inputProps={{ pattern: "[0-9]{5}" }}
				// https://github.com/mui-org/material-ui/issues/5309#issuecomment-355462588
				onInput={(e) => {
					e.target.value = e.target.value.slice(0, 5)
				}}
				onChange={handleChange}
				fullWidth
				min={5}
			/>

			<PhoneInput
				id={`properties.${propertyIndex}.phone`}
				required
				value={typeof propertyIndex === 'number' ? formData["properties"][propertyIndex]["phone"] : ""}
				handleChange={handleChange}
			/>

			<TextField
				id={`properties.${propertyIndex}.email`}
				required
				label="Email"
				autoComplete="email"
				variant="outlined"
				value={typeof propertyIndex === 'number' ? formData["properties"][propertyIndex]["email"] : ""}
				InputProps={{ type: "email" }}
				onChange={handleChange}
				disabled={disableEmailField}
				fullWidth
			/>
		</>
	)
}

export default CompanyInfoForm;