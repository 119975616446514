import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

import { SALES_END } from "../../constants/deadlines";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    alertText: {
        color: "white",
        marginBottom: 0,
    },
}));

const DeadlineApproaching = () => {
    const classes = useStyles();

    const today = new Date();
    const deadline = new Date(SALES_END);
    const daysLeft = Math.ceil((deadline - today) / (1000 * 3600 * 24));

    return (
        <div className={classes.root}>
            <Typography variant="h6" className={classes.alertText}>
                <FontAwesomeIcon icon={faClock} className={classes.icon}/>
                {daysLeft > 0
                    ? `Deadline Approaching: ${daysLeft} Days Remaining To Apply for Remaining Co-Op Opportunities.`
                    : `Deadline has Elapsed: Selection of Co-Op Opportunities has been locked.`
                }
            </Typography>
        </div>
    )
}

export default DeadlineApproaching;