import { useEffect, useState } from "react";

const useOpportunityFilters = (opportunities, handlePageChange, opportunityType, accountData) => {
	const [organizedOpportunities, setOrganizedOpportunities] = useState([]);
	const [sortBy, setSortBy] = useState("mostRecent");
	const [filters, setFilters] = useState({
		"opportunity-cost": null,
		"category": null,
	});

	if (!opportunityType) opportunityType = "Annual"

	const handleFilterChange = e => {
		handlePageChange("", 1);
		const key = e.target.id ? e.target.id : e.target.name;
		const newFilters = { ...filters }
		newFilters[key] = e.target.value;
		setFilters(newFilters);
	}

	const handleSortChange = e => {
		handlePageChange("", 1);
		setSortBy(e.target.value);
	}

	const applySort = (opportunities) => {
		switch (sortBy) {
			case "mostRecent":
				return opportunities.sort((a, b) => new Date(b.approveDate) - new Date(a.approveDate))
			case "alphabetical":
				return opportunities.sort((a, b) => a.name.localeCompare(b.name))
			case "priceDesc":
				return opportunities.sort((a, b) => b.cost - a.cost)
			case "priceAsc":
				return opportunities.sort((a, b) => a.cost - b.cost)
			default:
				return opportunities;
		}
	}

	const applyFilter = (opportunities) => {
		let filteredOpportunities = [...opportunities];

		if (filters["opportunity-cost"]) {
			filteredOpportunities = filteredOpportunities.filter(o => o.cost <= filters["opportunity-cost"])
		}

		if (filters["category"]) {
			filteredOpportunities = filteredOpportunities.filter(o => o.Category[0].object.name === filters["category"])
		}

		return filteredOpportunities
	}

	useEffect(() => {
		const filteredOpps = applyFilter(opportunities);
		let filteredAndSortedOpps = applySort(filteredOpps);
		if (opportunityType) filteredAndSortedOpps = filteredAndSortedOpps.filter(opp => (opp.type === opportunityType))
		setOrganizedOpportunities(filteredAndSortedOpps)
	}, [filters, sortBy, opportunities, accountData])

	return {
		organizedOpportunities,
		sortBy,
		handleSortChange,
		filters,
		handleFilterChange,
	}
}

export default useOpportunityFilters;