import React, { useContext } from "react";

import { TextField, Typography } from "@mui/material";
import PhoneInput from "../../common/PhoneInput";
import BudgetInput from "../../common/BudgetInput";

import CompanyInfoForm from "./CompanyInfoForm";
import BedTaxCollectorForm from "./BedTaxCollectorForm";

import { MAX_BUDGET } from "../../../constants/constants";
import { AccountContext } from "../../../AccountContext";


export const SignupBasics = ({formData, handleChange}) => {

	return (
		<>
			<Typography variant="h5">Welcome</Typography>
			<Typography>Sign up for free to start browsing all co-op marketing opportunities.</Typography>

			<CompanyInfoForm formData={formData} handleChange={handleChange} propertyIndex={0} />
		</>
	)
}

export const PartnerDetails = ({formData, handleChange}) => {
	return (
		<>
			<Typography variant="h5">Partner Details</Typography>
			<BedTaxCollectorForm formData={formData} handleChange={handleChange} propertyIndex={0} />
		</>
	)
}

export const MeetingSpaceContact = ({formData, handleChange}) => {

	return (
		<>
			<Typography variant="h5">Meeting Space Contact</Typography>
			<Typography>Edit the contact information below if meetings-specific co-op information should be coordinated with a different contact.</Typography>

			{/* NOTE: Prepopulating these fields with meeting facility info
				if the meeting space specific fields are empty */}
			<TextField
				id="meetingSpaceContactName"
				label="Contact Name"
				autoComplete="name"
				variant="outlined"
				fullWidth
				value={formData["meetingSpaceContactName"]}
				onChange={handleChange}
			/>

			<PhoneInput
				id="meetingSpacePhone"
				value={formData["meetingSpacePhone"]}
				handleChange={handleChange}
			/>

			<TextField
				id="meetingSpaceEmail"
				label="Email"
				autoComplete="email"
				variant="outlined"
				fullWidth
				value={formData["meetingSpaceEmail"]}
				onChange={handleChange}
			/>
		</>
	)
}

export const WeddingSpaceContact = ({formData, handleChange}) => {
	return (
		<>
			<Typography variant="h5">Wedding Space Contact</Typography>
			<Typography>
				Edit the contact information below if wedding-specific co-op information should be coordinated with a different contact.
			</Typography>
			<TextField
				id="weddingSpaceContactName"
				label="Contact Name"
				autoComplete="name"
				variant="outlined"
				fullWidth
				value={formData["weddingSpaceContactName"]}
				onChange={handleChange}
			/>
			<PhoneInput
				id="weddingSpacePhone"
				value={formData["weddingSpacePhone"]}
				handleChange={handleChange}
			/>
			<TextField
				id="weddingSpaceEmail"
				label="Email"
				autoComplete="email"
				variant="outlined"
				fullWidth
				value={formData["weddingSpaceEmail"]}
				onChange={handleChange}
			/>
		</>
	)
}

export const SetBudget = ({formData, handleChange}) => {
	const { accountData } = useContext(AccountContext);
	let maxBudget = MAX_BUDGET
	if (accountData.properties.length === 2) maxBudget = MAX_BUDGET + MAX_BUDGET;
	if (accountData.properties.length >= 3) maxBudget = 250000;
	let maxBudgetInDollarsStringNoCents = Number(maxBudget).toLocaleString('en-US', {style: 'currency', currency: 'USD'}).slice(0, -3);

	return (
		<>
			<Typography variant="h5">Set Budget</Typography>
			<Typography>This can be adjusted at any time, but is restricted to {maxBudgetInDollarsStringNoCents} max.</Typography>
			<BudgetInput
				id="budget"
				required
				value={formData["budget"]}
				handleChange={handleChange}
			/>
			<h4>The minimum budget for this program is $200.</h4>
		</>
	)
}
