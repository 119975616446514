import { timeHasElapsed } from "./deadlines";

export const URLS = {
    account: "/account",
    //assets: "/assets",
    contracts: "/contracts",
    datadeletion: "/data-deletion",
    faqs: "/faq",
    invoices: "/invoices",
    login: "/login",
    logout: "/logout",
    opportunities: "/annual-opportunities",
    suppOpportunities: "/supplemental-opportunities",
    signup: "/signup",
    terms: "/terms",
}

export const NAV_ARRAY = [
    {title: "Annual Opportunities", path: URLS.opportunities, hide: timeHasElapsed() },
    {title: "Supplemental Opportunities", path: URLS.suppOpportunities},
    {title: "My Contracts / Invoices", path: URLS.contracts},
    // {title: "My Invoices", path: URLS.invoices},
    // {title: "Asset Library", path: URLS.assets},
    {title: "FAQs", path: URLS.faqs},
	{title: "Log Out", path: URLS.logout},
]