import React, { useContext } from "react";

import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';

import AccountDetailsTable from "./AccountDetailsTable";
import { AccountContext } from "../../AccountContext";
import FormModal from "../common/FormModal";
import { MeetingSpaceContact } from "./forms/AccountForms";

import useModal from "../../hooks/useModal";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(2),
        },
    },
}));

const EditMeetingSpaceContact = ({
    formData, 
    handleChangeFormData, 
    saveAccountChanges
}) => {
    const classes = useStyles();
    const { accountData } = useContext(AccountContext);
    const { showModal, closeModal, openModal } = useModal();

    // NOTE: Displaying custom contact info if entered, otherwise the default info
    const rows = [
        {
            label: "Meeting Space Contact Name", 
            value: accountData["meetingSpaceContactName"] ? accountData["meetingSpaceContactName"] : accountData["contactName"] 
        },
		{
            label: "Meeting Space Contact Email", 
            value: accountData["meetingSpaceEmail"] ? accountData["meetingSpaceEmail"] : accountData["email"]
        },
		{
            label: "Meeting Space Contact Phone", 
            value: accountData["meetingSpacePhone"] ? accountData["meetingSpacePhone"] : accountData["phone"]
        },
    ]
    
    return (
        <div className={classes.root}>

            <AccountDetailsTable rows={rows}/>

            <Button 
                variant="outlined" 
                color="primary" 
                size="large"
                onClick={openModal}
            >
                EDIT
            </Button>

            <FormModal 
                showModal={showModal} 
                closeModal={closeModal}
                submitModal={saveAccountChanges}
            >
                <MeetingSpaceContact 
                    formData={formData} 
                    handleChange={handleChangeFormData} 
                />
            </FormModal>
        </div>
    )
}

export default EditMeetingSpaceContact;