import {
	Switch,
	Route,
	useLocation
} from "react-router-dom";
import { Component, useEffect } from "react";

import Account from "../../pages/Account";
import Assets from "../../pages/Assets";
import Contracts from "../../pages/Contracts";
import AnnualOpportunities from "../../pages/AnnualOpportunities";
import SuppOpportunities from "../../pages/SuppOpportunities";
// import DataDeletion from "../../pages/DataDeletion";
import Faqs from "../../pages/Faqs";
import Invoices from "../../pages/Invoices";
import Landing from "../../pages/Landing";
import Auth from "../../pages/Auth";
import Finalize from "../../pages/Finalize";
import PrivateRoute from "../routes/PrivateRoute";
import Signup from "../../pages/Signup";
import LogOut from "../../pages/LogOut";
import Terms from "../../pages/Terms";

import { URLS } from "../../constants/urls";
import PublicLayout from "../layouts/PublicLayout";
import PrivateLayout from "../layouts/PrivateLayout";

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

class ScrollRoute extends Component {
	render() {

		return (
			<Route {...this.props}>
				<ScrollToTop />
				{this.props.children}
			</Route>
		)
	}
}

const Routes = ({ isAuthenticated, setIsAuthenticated }) => {
	return (

		<Switch>

			<ScrollRoute path={URLS.login}>
				<Auth setIsAuthenticated={setIsAuthenticated} />
			</ScrollRoute>

			<ScrollRoute
				path={URLS.logout}
			>
				<LogOut setIsAuthenticated={setIsAuthenticated} />
			</ScrollRoute>

			<ScrollRoute path={URLS.terms}>
				{isAuthenticated
					? (
						<PrivateLayout
							path={URLS.terms}
							isAuthenticated={isAuthenticated}
						>
							<Terms />
						</PrivateLayout>
					)
					: (
						<PublicLayout
							isAuthenticated={isAuthenticated}
						>
							<Terms />
						</PublicLayout>
					)
				}
			</ScrollRoute>

			{/* <ScrollRoute path={URLS.datadeletion}>
				{isAuthenticated
					? (
						<PrivateLayout
							path={URLS.datadeletion}
							isAuthenticated={isAuthenticated}
						>
							<DataDeletion />
						</PrivateLayout>
					)
					: (
						<PublicLayout
							isAuthenticated={isAuthenticated}
						>
							<DataDeletion />
						</PublicLayout>
					)
				}
			</ScrollRoute> */}

			<ScrollRoute path={URLS.faqs}>
				{isAuthenticated
					? (
						<PrivateLayout
							path={URLS.faqs}
							isAuthenticated={isAuthenticated}
						>
							<Faqs />
						</PrivateLayout>
					)
					: (
						<PublicLayout
							isAuthenticated={isAuthenticated}
						>
							<Faqs />
						</PublicLayout>
					)
				}
			</ScrollRoute>

			<ScrollRoute path={URLS.contracts}>
				{isAuthenticated
					? (
						<PrivateLayout
							path={URLS.contracts}
							isAuthenticated={isAuthenticated}
						>
							<Contracts />
						</PrivateLayout>
					)
					: <PrivateRoute />
				}
			</ScrollRoute>

			<ScrollRoute
				path={URLS.signup}
				isAuthenticated={isAuthenticated}
			>
				{isAuthenticated ?
					<Signup /> :
					<Auth setIsAuthenticated={setIsAuthenticated} signup={true} />
				}
			</ScrollRoute>

			<PrivateRoute
				path={URLS.contracts}
				isAuthenticated={isAuthenticated}
			>
				<Contracts />
			</PrivateRoute>

			<PrivateRoute
				path={URLS.invoices}
				isAuthenticated={isAuthenticated}
			>
				<Invoices />
			</PrivateRoute>

			{/*<PrivateRoute
				path={URLS.assets}
				isAuthenticated={isAuthenticated}
			>
				<Assets />
			</PrivateRoute>*/}

			<PrivateRoute
				path={URLS.account}
				isAuthenticated={isAuthenticated}
			>
				<Account />
			</PrivateRoute>

			<PrivateRoute
				path={URLS.opportunities}
				isAuthenticated={isAuthenticated}
				noScroll={true}
			>
				<AnnualOpportunities />
			</PrivateRoute>

			{/*<PrivateRoute
				path={URLS.assets}
				isAuthenticated={isAuthenticated}
			>
				<Assets />
			</PrivateRoute>*/}

			<PrivateRoute
				path={URLS.suppOpportunities}
				isAuthenticated={isAuthenticated}
				noScroll={true}
			>
				<SuppOpportunities />
			</PrivateRoute>

			<PrivateRoute
				path="/finalize/:id"
				isAuthenticated={isAuthenticated}
			>
				<Finalize />
			</PrivateRoute>

			<ScrollRoute path="/">
				<PublicLayout
					isAuthenticated={isAuthenticated}
				>
					<Landing isAuthenticated={isAuthenticated} />
				</PublicLayout>
			</ScrollRoute>

		</Switch>
	)
}

export default Routes;