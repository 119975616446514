import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import SimpleAccordion from "../components/common/SimpleAccordion";
import { FAQS } from "../constants/faqs";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(2),
		},
		marginTop: "15rem",
		marginBottom: "7rem",
		minHeight: "100vh",
	},
}));

const Faqs = () => {
	const classes = useStyles();
	return (
		<Container maxWidth="md" className={classes.root}>
			<Typography variant="h4">FAQs</Typography>
			<SimpleAccordion items={FAQS} />
		</Container>
	)
}

export default Faqs;