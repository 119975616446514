import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import { Container } from "@mui/material";

import Introducing from "../components/landing/Introducing";
import SiteFeatures from "../components/landing/SiteFeatures";
import Experience from "../components/landing/Experience";

const useStyles = makeStyles((theme) => ({
	shadow: {
		flexGrow: 1,
		margin: "50px 0",
		boxShadow: "0 6px 14px 0 rgba(0,0,0,0.06)",
		marginTop: "10rem",
	},
}));

const Landing = ({ isAuthenticated }) => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.shadow}>
				<Container>
					<Introducing isAuthenticated={isAuthenticated} />
					<SiteFeatures />
				</Container>
			</div>
			<Container>
				<Experience isAuthenticated={isAuthenticated} />
			</Container>
		</>
	)
}

export default Landing;