import React, { useContext, useState } from "react";
// import { useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Button from "@mui/material/Button";

import { GoogleLogout } from 'react-google-login';

import { GOOGLE_CLIENT_ID } from '../../constants/constants';

import { AccountContext } from "../../AccountContext";

var canUseDOM = !!(
	(typeof window !== 'undefined' &&
	window.document && window.document.createElement)
);

const GoogleLogoutButton = () => {
    const { setIsAuthenticated } = useContext(AccountContext);
	const [hidden, setHidden] = useState(false);
	// const history = useHistory();

    const logout = () => {
        setIsAuthenticated(false);
		localStorage.removeItem("userId")
		if (canUseDOM) {
			var auth2 = window.gapi.auth2.getAuthInstance();
			if (auth2 != null) {
				auth2.signOut()
					.then(() => {
						setHidden(true)
					});
			}
		}
        // history.push("/");
    }

    return (
		<>
			{!hidden ?
				<>
					<div style={{ width: "100%", textAlign: "center" }}>
						To reset your account you must log out of Google. Please use the "Logout" button below to reset your account.
						<br />
						<br />
					</div>
					<GoogleLogout
						clientId={ GOOGLE_CLIENT_ID }
						buttonText="Logout"
						onLogoutSuccess={logout}
					>
					</GoogleLogout>
				</>
				:
				<>
					{/* You have been successfully logged out of Google. You may safely close this window. */}
					You have been successfully logged out of Google. If you wish to log back in please click the Login button below.
					<br />
					<br />
					<Button
						variant="outlined"
						color="primary"
						component={RouterLink}
						to="/login"
						size="large"
					>
						Login
					</Button>
				</>
			}
		</>
    )
}

export default GoogleLogoutButton;