import React, { useContext, useState } from "react";
import { Link as RouterLink } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

import { OpportunitiesContext } from "../../OpportunitiesContext";

import SelectedOpportunityCard from "../opportunities/SelectedOpportunityCard";
import OpportunityDetailPaper from "../opportunities/OpportunityDetailPaper";
import Modal from "../common/Modal";
import RecentlyReleasedOpportunities from "../opportunities/RecentlyReleasedOpportunities";

import { URLS } from "../../constants/urls";
import { OPPORTUNITY_YEAR  } from "../../constants/constants";

import useModal from "../../hooks/useModal";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(2),
        },
    },
}));

const AnnualDashboard = ({accountData}) => {
    const {
        opportunities,
        handleSelect,
        selectIsDisabled,
        handleRemove,
        isLoading,
        // hasError,
        errorMessage
    } = useContext(OpportunitiesContext);

    const { showModal, closeModal, openModal } = useModal();
    const [modalOpportunity, setModalOpportunity] = useState({});

    const classes = useStyles();

    const getOpportunityById = (id) => {
        return opportunities.find(o => o.id === id);
    }

	const onDetailsClick = (opportunity) => {
		setModalOpportunity(opportunity);
		openModal();
	}

    const getUnselectedOpportunities = () => {
        return opportunities.filter(o => !accountData.opportunities.includes(o.id))
    }

    const getPropertyDetail = (propertyID) => {
        for (let property of accountData.properties) {
            if (property.uuid === propertyID)return property;
        }
        return null;
    }

	const count = accountData["Opportunity"] ? accountData["Opportunity"].length : 0
    return (
        <div className={classes.root}>
            <Typography variant="h4">{OPPORTUNITY_YEAR} Selected Opportunities ({count})</Typography>

            {accountData["opportunities"].length > 0
                ? opportunities.length > 0
                    ? (
                        <>
                            <Grid container direction="row">
                                {accountData.Opportunity.map((o) => {
                                    return (
                                        <Grid item xs={6} sx={{ paddingRight: "1rem" }} key={o.properties.uuid}>
                                            <SelectedOpportunityCard
                                                opportunity={o}
                                                propertyDetail={getPropertyDetail(o.properties.propertyUuid)}
                                                handleRemove={() => {
													handleRemove(o.id, o.properties.propertyUuid)
												}}
                                                onDetailsClick={onDetailsClick}
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </>
                    )
                    : null
                : (
                    <Typography variant="body2" component="div">
                        Looks like you haven't selected any opportunities yet
                    </Typography>
                )
            }

            {/*{opportunities.length === 0
                ? isLoading
                    ? <CircularProgress />
                    : errorMessage && <Alert severity="error">{errorMessage}</Alert>
                : (
                    <RecentlyReleasedOpportunities
                        opportunities={getUnselectedOpportunities()}
                        selectedOpportunities={accountData.opportunities}
                        onDetailsClick={onDetailsClick}
                    />
                )
            }*/}
            <Button
                variant="outlined"
                color="primary"
                component={RouterLink}
                to={URLS.opportunities}
                size="large"
            >
                VIEW ALL OPPORTUNITIES
            </Button>
            <Modal
                showModal={showModal}
                closeModal={closeModal}
            >
                {modalOpportunity && (
                    <OpportunityDetailPaper
						propertyDetail={getPropertyDetail(modalOpportunity?.properties?.propertyUuid)}
                        opportunity={modalOpportunity}
                        selected={getPropertyDetail(modalOpportunity?.properties?.propertyUuid)}
                        handleSelect={() => handleSelect(modalOpportunity.id)}
                        handleRemove={() => { handleRemove(modalOpportunity.id, modalOpportunity?.properties?.propertyUuid); closeModal(); }}
                        disabled={selectIsDisabled(modalOpportunity.id)}
                    />
                )}
            </Modal>
        </div>
    )
}

export default AnnualDashboard;