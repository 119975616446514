import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import OpportunityCardHeader from "./OpportunityCardHeader"

import formatCurrency from "../../helpers/formatCurrency";
import formatDateTime from "../../helpers/formatDateTime";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faBullhorn } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		marginBottom: theme.spacing(4),
	},
	cardContent: {
		padding: theme.spacing(3),
	},
	cardActionsRoot: {
		display: "flex",
		justifyContent: "space-between",
		padding: theme.spacing(2),
	},
	titleRow: {
		display: "flex",
		justifyContent: "space-between",
	},
	title: {
		marginTop: "0.75rem",
		marginBottom: "0.75rem",
		lineHeight: 1,
		'& a': {
			color: theme.palette.primary.main,
			textDecoration: "underline",
			textTransform: "uppercase",
			fontSize: '14px',
			fontWeight: "400",
			lineHeight: 1.8,
			'& img': {
				marginLeft: ".5rem",
			}
		}
	},
	icon: {
		marginLeft: "0.5rem",
	},
	type: {
		padding: "6px 6px 6px 6px",
		display: "inline",
		fontSize: "16px",
		fontWeight: "bold",
		letterSpacing: "0.8px",
		lineHeight: "19px",
		color: "#fff",
		backgroundColor: "#3B7156",
		textTransform: "uppercase"
	}
}));

const OpportunityCard = ({
	opportunity,
	onDetailsClick,
	selected,
	handleSelect,
	handleRemove,
	disabled,
	userIsMultiProperty,
	flags,
	properties,
	selectedProperties,
}) => {

	let relatedPropertiesUuids = !!selectedProperties && selectedProperties.length > 0 ? selectedProperties.map(p => p.split('_')[p.split('_').length - 1]) : []
	if (opportunity.type === "Supplemental") relatedPropertiesUuids = opportunity.latestReservedOpportunity.length > 0 ? opportunity.latestReservedOpportunity.map(opt => opt.properties.propertyUuid) : []
	const relatedPropertiesNames = !!properties && properties.length > 0 ? properties.filter(pr => relatedPropertiesUuids.indexOf(pr.uuid) !== -1).map(prop => prop.organizationName) : []

	if (!selected && relatedPropertiesNames.length > 0) selected = true
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);

	const onRemove = () => {
		handleRemove(opportunity.id, properties[0].uuid)
	}

	const onSelect = () => {
		handleSelect(opportunity.id, properties[0].uuid)
	}

	useEffect(() => {
		setIsLoading(false);
	}, [selected])

	return (
		<Card className={classes.root} variant="outlined">

			<OpportunityCardHeader
				opportunity={opportunity}
				selected={selected}
				disabled={disabled}
			/>

			<CardContent className={classes.cardContent}>
				<Grid container direction="row" className={classes.titleRow}>
					<Grid item>
						{opportunity.type === "Supplemental" && <Typography className={classes.type}>
							<FontAwesomeIcon style={{ marginRight: "8px" }} icon={faBullhorn} size="sm" />Supplemental Program
						</Typography>}
						<Typography variant="h6" className={classes.title}>
							{opportunity.name}
							<br />
							<a style={(disabled && !userIsMultiProperty) ? { filter: "brightness(0) saturate(100%) invert(57%) sepia(10%) saturate(0%) hue-rotate(285deg) brightness(86%) contrast(98%) " } : {}} onClick={() => {
								if (!disabled || userIsMultiProperty) {
									onSelect()
								}
							}} href="#">{selected ? (
								`Reserved for ${relatedPropertiesNames.join(relatedPropertiesNames.length === 2 ? ' & ' : ', ').trim()} ${relatedPropertiesNames.length > 3 ? "& More..." : ""}`
							) : "Link To My Properties"} <img src="images/icons/pen-paper.svg" alt="Link My Properties" /></a>
						</Typography>
						<Typography className={classes.avaliableFor}>
							{flags}
						</Typography>
					</Grid>
					<Grid item lg={3}>
						<Box style={{ textAlign: "right" }}>
							<Typography variant="h5" className={classes.cost}>
								{formatCurrency(opportunity.cost)}
							</Typography>
							{opportunity.latestReservedOpportunity.length !== 0 && (
								userIsMultiProperty ? (
									<>
										<Button
											style={{ marginTop: "17px" }}
											color="primary"
											variant="outlined"
											aria-label="complete an opportunity"
											onClick={onSelect}
											disabled={!userIsMultiProperty}
										>
											Manage Reservations
										</Button>
									</>
								) : (
									<>
										<Button
											color="primary"
											variant="outlined"
											aria-label="release an opportunity"
											onClick={() => onRemove()}
										>
											Release Reservation
										</Button>
										<Button
											style={{ marginTop: "17px" }}
											color="primary"
											variant="outlined"
											aria-label="complete an opportunity"
											onClick={() => {
												if (opportunity.latestReservedOpportunity.length > 0) {
													handleSelect(opportunity.latestReservedOpportunity[0].properties.uuid, opportunity.latestReservedOpportunity[0].properties.propertyUuid, true)
												} else {
													handleSelect(opportunity.properties.uuid, opportunity.latestReservedOpportunity[0].properties.propertyUuid, true)
												}
											}}
										>
											Complete Reservation
										</Button>
									</>
								)
							)
							}
						</Box>
					</Grid>
				</Grid>
				<Typography color="textSecondary" component="div">
					{opportunity.editorialFocus}
				</Typography>
			</CardContent>

			<CardActions classes={{ root: classes.cardActionsRoot }}>
				<Button size="small" color="primary" onClick={() => onDetailsClick(opportunity)}>
					More Details <FontAwesomeIcon icon={faCamera} size="lg" className={classes.icon} />
				</Button>
				{!userIsMultiProperty && isLoading
					? <CircularProgress size="2rem" />
					: (opportunity.latestReservedOpportunity.length === 0 && <Button
						color="primary"
						variant="outlined"
						size="large"
						onClick={
							userIsMultiProperty ?
								onSelect :
								(
									selected
										? () => onRemove()
										: () => onSelect()
								)
						}
						disabled={disabled && !selected}
					>

						{selected ? (userIsMultiProperty ? "Manage Reservations" : "Remove Opportunity") : "Select Opportunity"}
					</Button>)
				}
			</CardActions>

		</Card>
	);
}

export default OpportunityCard;