import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(2),
		},
		marginTop: "15rem",
		marginBottom: "15rem",
		minHeight: "100vh",
	},
}));

const Terms = () => {
	const classes = useStyles();
	return (
		<Container maxWidth="md" className={classes.root}>
			<Typography variant="h3">
				PRIVACY POLICY
			</Typography>
			<Typography variant="h4">
				TERMS OF USAGE, DISCLAIMERS AND PRIVACY POLICY
			</Typography>
			<Typography variant="h5">
				PLEASE READ CAREFULLY!
			</Typography>
			<Typography variant="h4">
				Web-based App Information and Usage
			</Typography>
			<Typography>
				The purpose of this web-based application is to provide bed tax collectors and tourism partners in South Walton and Authentic Walton the opportunity to promote their accommodations or services and align themselves with the Walton County Tourism brand message in high-quality paid media placements for a minimal cost.

				All materials on this site, as well as email messages, including but not limited to images, illustrations, special surveys, sweepstakes, games and promotions, are protected by copyrights owned and controlled either by Site Owners, affiliate entities or by a third party who has licensed their materials to the Site Owners. Material from this web site may not be copied, downloaded, reproduced, republished, uploaded, posted, transmitted or distributed in any way. Modification of the materials or use of the materials for any purpose other than your own personal, non-commercial, home use is a violation of the copyrights and other property rights held by Site Owners or affiliate companies. The use of any such material on any other web site or networked computer environment is prohibited.

				The trademarks, logos and service marks seen on this site are registered and unregistered trademarks of Site Owners, their affiliates and other entities. This site does not grant you the license or the right to use any trademark displayed on this site without the written consent of the owner of the trademark. Your use or misuse of the trademarks displayed on this site, or any other content on this site, is strictly prohibited.

				This site is controlled and operated from its offices located at:
			</Typography>

			<Typography>
				Walton County Tourism Department
			</Typography>

			<Typography>
				25777 US Highway 331 South
			</Typography>

			<Typography>
				Santa Rosa Beach, Florida 32459
			</Typography>

			<Typography>
				Phone Number: 1-800-822-6877
			</Typography>

			<Typography>
				Site and email content is also owned and controlled by the Walton County Tourism Department.
			</Typography>

			<Typography variant="h4">
				Privacy Policy
			</Typography>

			<Typography>
				Data about the site activity of registered and non-registered visitors to this site is automatically provided to Site Owners when you sign on or enter the site. Site Owners use it for internal review in aggregate and non-personally-identifiable form to enhance the relevancy of our content for our users.

				E-mail addresses and other personally identifying data about you is known to Site Owners only when voluntarily submitted or emailed to us. Site Owners use personally identifying data provided by visitors to tell you about products and offers, events and other types of interests which you have indicated are of interest to you. We may also share this information with other interested parties but only when you have expressly indicated agreement or acceptance in receiving information about specials or promotions or other partner products.

				If at any point you no longer wish to receive communications from us, follow the removal instructions located at the bottom of any email you receive from us. Notifying us to be removed in any fashion other than an unsubscribe, may not result in the removal of your email address from our list.
			</Typography>

			<Typography variant="h3">
				Site Users
			</Typography>

			<Typography>
				Cookies may be used to track usage of the web site. This does not provide any personal identification and is only used to further understand how users navigate and use the web site. This information is used to optimize the web site and increase usability for all users.

				In addition, we use E-mail addresses to contact visitors who communicate with us, as indicated in our registration areas. For example, we direct E-mail to visitors who provide us with their E-mail addresses for specific interests, specials, offers and other promotions fitting their indicated interests or for notifying users that they have won one of our giveaways or contests. If at any point you no longer wish to receive communications from us, indicate that in the unsubscribe area of our regular email newsletter as described above.

				Due to the fact that the Site Owner is a governmental entity in the State of Florida, all data provided and collected may be subject to disclosure as public record under Florida Law. Please be advised of this, and operate under the assumption that all information provided could be released as public record.
			</Typography>
			<Typography>
				<strong>
					BY SUBMITTING PERSONAL INFORMATION TO THIS SITE, YOU HAVE ACCEPTED THE TERMS OF USAGE LISTED ABOVE. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT SUBMIT ANY OF YOUR PERSONAL INFORMATION TO THIS SITE.
				</strong>
			</Typography>
			<Typography>
				Any request received by the Walton County Tourism Department (Visit South Walton) made by the creator of social content, regarding removal of post(s) will be considered.  The request itself is most likely also subject to public record disclosure.
			</Typography>

			<Typography variant="h4">
				Materials Submitted to Site Owners
			</Typography>

			<Typography>
				Site Owners welcome your comments and suggestions regarding services, including comments about our website. However, any notes, messages, postings, ideas, suggestions, or other material, which you submit to Site Owners, will become property of Site Owners. Site Owners shall be entitled to use any kind and nature of material, which you submit for any type of use indefinitely for promotional and advertising purposes, without compensation to you of any kind. You agree to shield Site Owners, their shareholders, officers, directors, employees, agents, distributors, vendors, and affiliates from and against any and all third party claims, demands, liabilities, costs or expenses, including reasonable attorneys’ fees, resulting from your breach of any of these terms and conditions.
			</Typography>

			<Typography variant="h4">
				Other Sites Which May be Linked to this Site
			</Typography>

			<Typography>
				Site Owners may include some links to other Internet sites maintained by third parties for your convenience only, these are defined as Linked Sites. Neither Site Owners nor their affiliates operate or control in any respect any information, products or services on Linked Sites which are not owned or controlled by an affiliate of Site Owners or the other owner represented in this notification. Site Owners are not responsible for the content on the Linked Sites and disclaims all liability for any injury you experience while you are in such Linked Site. Access them at your own risk.
			</Typography>

			<Typography variant="h4">
				Disclaimer
			</Typography>

			<Typography>
				The materials in this site and any third-party sites are provided "as is" and without warranties of any kind, either expressed or implied, including but not limited to, implied warranties of merchantability and fitness for a particular purpose. Site Owners do not warrant or make any representations regarding the use or the results of the use of the materials or services in this site or in linked sites in terms of their correctness, accuracy, timeliness, reliability, or otherwise. Site Owners do not warrant that defects will be corrected or that this site or the server that makes it available, are free of viruses or other harmful components. The material that you read in the site is provided solely for entertainment, promotional, and commercial purposes.
			</Typography>

			<Typography variant="h4">
				Liability Limitations
			</Typography>

			<Typography>
				Site Owners, affiliates, related entities, vendors and content providers are not responsible or liable to any person or entity whatsoever for any direct or indirect loss, damage, injury, claim, liability or other cause of any kind (whether actual, consequential, punitive, special or otherwise), whatsoever based upon or resulting from any information or opinions provided on the Site Owners site or from the use of, or the inability to use, Site Owners materials or services on the site. You specifically agree that Site Owners are not liable for any defamatory, offensive or illegal conduct of any user. If you are dissatisfied with the Site Owners site, any materials on the site or with any of Site Owners Terms of Usage, your sole and exclusive remedy is to discontinue using the Site Owners site.

				Site Owner reserves the right, at any time, to modify, alter or update these Terms of Usage. By submitting your email address and information to this site, you agree to be limited by such modifications, alterations or updates.
			</Typography>

		</Container>
	)
}

export default Terms;