import { Route, Redirect } from "react-router-dom";
import PrivateLayout from "../layouts/PrivateLayout";

// A wrapper for <Route> that redirects to:
// - login/signup if not authenticated
// - signup process if not finished registering
const PrivateRoute = ({ isAuthenticated, children, noScroll, ...rest }) => {
	if (noScroll !== undefined && noScroll) window.scrollTo(0, 0);

	return (
	  <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated
          ? (
            <PrivateLayout
              isAuthenticated={isAuthenticated}
            >
              {children}
            </PrivateLayout>
          )
          : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
	  />
	);
}

export default PrivateRoute;