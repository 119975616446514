import React, { useContext } from "react";
import { useState } from "react";

import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";

import { styled } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faImage } from '@fortawesome/free-regular-svg-icons';

import GooglePicker from 'react-google-picker';
import { GOOGLE_CLIENT_ID, GOOGLE_API_KEY } from '../constants/constants';

import { AccountContext } from "../AccountContext";

import {
	BASE_FETCH_URL
} from "../constants/api";

// var canUseDOM = !!(
// 	(typeof window !== 'undefined' &&
// 		window.document && window.document.createElement)
// );

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(2),
		},
		// flexGrow: 1,
		marginTop: theme.spacing(35),
		minHeight: "80vh",
	},
	textCenter: {
		textAlign: "center"
	}
}));

const Input = styled('input')({
	display: 'none',
});

const Assets = () => {

	const { accountData } = useContext(AccountContext);

	const [status, setStatus] = useState(null);
	const [open, setOpen] = React.useState(false);
	// const [image, setImage] = useState(null);

	const onChange = (e) => {
		let formData = new FormData();
		var fileField = document.querySelector("input[type='file']");
		formData.append('file', fileField.files[0]);
		const config = {
			method: 'POST',
			body: formData,
		};
		fetch(`${BASE_FETCH_URL}/g/drive/upload?id=${accountData.id}&driveId=${accountData.driveId}`, config)
			.then(res => res.json())
			.then(({ data }) => {
				setOpen(false);
				if (data.data === "OK") {
					setStatus({ message: `${fileField.files[0].name} uploaded successfully!`, severity: "success" })
				} else {
					setStatus({ message: "An error occured, please wait and try again later.", severity: "info" })
				}
				setOpen(true);
			})
	}

	const classes = useStyles();

	const SCOPE = ['https://www.googleapis.com/auth/drive'];

	return (
		<Container maxWidth="lg" className={classes.root}>
			{status !== null && <Collapse in={open}><Alert onClose={() => setOpen(false)} severity={status.severity}>{status.message}</Alert></Collapse>}
			<Typography variant="h4">Asset Library</Typography>
			<Typography variant="body1">
				<strong>Supplemental Co-Op Program</strong>
				<br />
				Asset upload is a requirement at the time of supplemental opportunity purchase. Please upload necessary assets and files for the selected co-op opportunity based on specs included in the More Details section. Sample creative is included in the More Details section for your convenience. Assets can be uploaded directly from Google Drive. Copy can be uploaded via Microsoft Word Document or PDF.
			</Typography>
			<Typography variant="body1">
				<strong>Annual Co-Op Program</strong>
				<br />
				Opportunities can be purchased without asset upload at the time of the annual co-op sales period. Zehnder will follow up for assets to be uploaded in the Asset Library before each campaign flight. At the time of asset upload, please upload necessary assets and files for the selected co-op opportunity based on specs included in the More Details section. Sample creative is included in the More Details section for your convenience. Assets can be uploaded directly from Google Drive. Copy can be uploaded via Microsoft Word Document or PDF. </Typography>
			<br />
			<br />
			<Typography variant="body1" style={{ color: "#AC0F0F", textAlign: "center" }}>
				"View Assets" is currently experiencing an issue that we are working to resolve. If you'd like to continue through, please click <strong>Advanced</strong> and press <strong>Continue</strong>.
				<br/>
				<small><strong>Note:</strong> Google marks this as unsafe due to a verification issue that we are working to resolve.</small>
			</Typography>
			<br />
			<br />
			<div className={classes.textCenter}>
				<Grid direction="row" container spacing={2}>
					<Grid sx={{ textAlign: { md: "right", xs: "center" } }} item xs={12} md={6}>
						<form id="fileUpload">
							<label htmlFor="contained-button-file">
								<Input onChange={onChange} id="contained-button-file" single type="file" />
								<Button
									variant="contained"
									component="span"
									size="large"
									disabled={!accountData.driveId}
									endIcon={<FontAwesomeIcon icon={faImage} />}
								>
									Upload Assets
								</Button>
							</label>
						</form>
					</Grid>
					<Grid sx={{ textAlign: { md: "left", xs: "center" } }} item xs={12} md={6}>
						<GooglePicker clientId={GOOGLE_CLIENT_ID}
							developerKey={GOOGLE_API_KEY}
							scope={SCOPE}
							onChange={data => console.log('on change:', data)}
							onAuthFailed={data => console.log('on auth failed:', data)}
							multiselect={true}
							navHidden={true}
							authImmediate={false}
							viewId={'DOCS'}
							createPicker={(google, oauthToken) => {
								const googleViewId = google.picker.ViewId.DOCS;

								const folderId = accountData.driveId

								// const docsUploadView = new google.picker.DocsUploadView()
								// 	.setParent(folderId)
								// 	.setIncludeFolders(true)

								const docsView = new google.picker.DocsView(googleViewId)
									.setParent(folderId)
									.setEnableDrives(true)
									.setMode(google.picker.DocsViewMode.GRID)

								const picker = new window.google.picker.PickerBuilder()
									// .addView(docsUploadView)
									.addView(docsView)
									.setOAuthToken(oauthToken)
									.setDeveloperKey(GOOGLE_API_KEY)

								picker.build().setVisible(true);
							}}
						>
							<Button
								variant="contained"
								color="primary"
								size="large"
								disabled={!accountData.driveId}
								endIcon={<FontAwesomeIcon icon={faPlusSquare} />}
							>
								View Assets
							</Button>
							<div className="google"></div>
						</GooglePicker>
					</Grid>
				</Grid>
			</div>
		</Container>
	)
}

export default Assets;