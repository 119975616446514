
import React, { useContext, useState } from 'react';

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';

import { SwitchLovely } from "../common/Switch";
import { AccountContext } from "../../AccountContext";
import { OpportunitiesContext } from "../../OpportunitiesContext";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import makeStyles from '@mui/styles/makeStyles';
import parse from 'html-react-parser';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: "50px 0",
	},
	instructions: {
		padding: "66.5px 31.5px",
		marginBottom: "109px",
		border: "1px solid #979797"
	},
	insHeader: {
		fontSize: "24px",
		fontWeight: "bold",
		letterSpacing: "1.2px",
		lineHeight: "31px",
		textAlign: "center",
		margin: 0
	},
	subline: {
		fontSize: "16px",
		fontStyle: "italic",
		fontWeight: "300",
		letterSpacing: "0.8px",
		lineHeight: "22px",
		textAlign: "center",
	},
	table: {
		width: "100%",
	},
	thead: {
		borderBottomColor: "#056791",
	},
	smallHead: {
		fontSize: "14px",
		fontWeight: 600,
		lineHeight: "1",
		textTransform: "uppercase",
		color: "#6B6B6B",
	},
	blue: {
		color: "#1093CE",
	},
	actionLink: {
		fontWeight: 600,
		fontSize: "14px",
		lineHeight: "48px",
		textTransform: "uppercase",
		textDecoration: "none",
		color: "#0071BC",
		'&:hover': {
			textDecoration: "underline",
		}
	},
	cell: {
		width: "50%",
	},
	disallowed: {
		color: '#6B6B6B',
		fontSize: "20px",
		fontWeight: "400",
		lineHeight: "1",
		margin: "12px 0"
	},
}));

const isPropertyAllowedToReserve = (property, o) => {
	if (property.isBedTaxCollector) {
		if (property.bedTaxLocation === 'south-walton') {
			if (property.isMeetingFacility && o.Category[0].object.permalink.startsWith("meetings")) return true;
			if (property.isWeddingFacility && o.Category[0].object.permalink.startsWith("weddings")) return true;
			if (
				o.Category[0].object.permalink.startsWith('leisure-print') ||
				o.Category[0].object.permalink.startsWith('online-display') ||
				o.Category[0].object.permalink.startsWith('facebook-instagram') ||
				o.Category[0].object.permalink.startsWith('vrbo-banner') ||
				o.Category[0].object.permalink.startsWith('tripadvisor') ||
				o.Category[0].object.permalink.startsWith('connected-tv') ||
				o.type === "Supplemental"
			) return true;
			return false;
		} else if (property.bedTaxLocation === 'f-ds-p') {
			if (
				o.Category[0].object.permalink.startsWith("walton-county-print")
			) return true;
			return false;
		}
	} else if (property.bedTaxLocation === 'south-walton') {
		if (
			o.Category[0].object.permalink.startsWith('online-display') ||
			o.Category[0].object.permalink.startsWith('facebook-instagram') ||
			o.Category[0].object.permalink.startsWith('vrbo-banner') ||
			o.Category[0].object.permalink.startsWith('tripadvisor') ||
			o.Category[0].object.permalink.startsWith('connected-tv') ||
			o.type === "Supplemental"
		) return true;
		return false;
	} else if (property.bedTaxLocation === 'f-ds-p') {
		return false;
	}
	return false;
};

const OpportunitySelectPaper = props => {

	const classes = useStyles();
	const history = useHistory();

	const {
		handleBulk
	} = useContext(OpportunitiesContext);

	const { modalOpportunity, closeModal, selectedOpportunities, toggleReservation, pendingReservations } = props
	const { accountData } = useContext(AccountContext);
	const { properties } = accountData;
	const isMultiProperty = properties.length > 1;
	
	const submitChanges = () => {
		const tmp = { ...pendingReservations }
		if (Object.keys(tmp).length === 0) {
			closeModal()
		} else {
			handleBulk(tmp)
				.then(() => {
					closeModal()
				})
		}
	}

	if (!modalOpportunity) return null;

	return (
		<>
			{!isMultiProperty ?
				(<>
					<Typography align="center" style={{ color: "#3B7156", "fontSize": "18px", "fontWeight": "bold", margin: 0 }} variant="h5">WE'VE TEMPORARILY RESERVED</Typography>
					<Typography align="center" style={{ color: "#3B7156", "fontSize": "30px", "fontWeight": "bold", marginBottom: "60px" }} variant="h6">{modalOpportunity.name}</Typography>
					<Container maxWidth="md">
						<Grid item align="center" xs={12}>
							<Grid item align="center" xs={12} md={9}>
								<div className={classes.instructions}>
									{modalOpportunity.Category[0].object.description &&
										<>
											<Typography className={classes.insHeader} align="center">To finalize your reservation you will need the following assets:</Typography>
											<Typography>{parse(modalOpportunity.Category[0].object.description)}</Typography>
										</>
									}
									<hr style={{ borderColor: "#E9E9E9", marginBottom: "28px" }} />
									<Typography className={classes.subline}>Note: We've reserved this opportunity for 72 hours. This opportunity is available in your dashboard. Please upload all requested assets within the 72 hour window to generate the opportunity contract. The opportunity will be released if the reservation is not finalized.</Typography>
									<Grid item align="center" xs={12}>
										<Button size="large" style={{ margin: "10px" }} variant="outlined" color="primary" onClick={() => closeModal()}>
											Save Reservation
										</Button>
										<Button size="large" style={{ margin: "10px" }} variant="outlined" color="secondary" onClick={() => history.push(`/finalize/${modalOpportunity.properties.uuid}_${modalOpportunity.properties.propertyUuid}`)}>
											Complete Reservation
										</Button>
									</Grid>
								</div>
							</Grid>
						</Grid>
					</Container>
				</>)
				:
				(<>
					<Container maxWidth="md">
						<Grid item align="center" xs={12}>
							<Typography align="center" style={{ "fontSize": "35px", "fontWeight": "bold", marginBottom: "22px" }} variant="h6">Manage Properties For {modalOpportunity.name}</Typography>
							<Grid item align="center" xs={12} md={10}>
								<Typography align="center" style={{ fontSize: "16px", fontStyle: "normal", fontWeight: 400, lineHeight: "26px", marginBottom: "74px" }} variant="body1">Please select a property (or properties) to link with this opportunity if you have not done so already. You may also release a linked reservation here using the toggle. Properties that are not available will not have a toggle option.</Typography>
							</Grid>
							{modalOpportunity.type === "Supplemental" && (
								<Grid item align="center" xs={12}>
									{modalOpportunity.Category[0].object.description &&
										<>
											<Accordion sx={{ marginBottom: "2rem", '& .MuiAccordionSummary-content': { justifyContent: "center" } }}>
												<AccordionSummary
													expandIcon={<ExpandMoreIcon />}
													aria-controls="panel1-content"
													id="panel1-header"
												>
													<Typography className={classes.insHeader} align="center">To finalize your reservation you will need the following assets:</Typography>
												</AccordionSummary>
												<AccordionDetails>
													<Typography>{parse(modalOpportunity.Category[0].object.description)}</Typography>
												</AccordionDetails>
											</Accordion>
										</>}
								</Grid>
							)}
							<TableContainer style={{ marginBottom: "72px" }}>
								<Table aria-label="simple table" className={classes.table}>
									<TableHead scope="row">
										<TableCell className={classes.thead} align="left">Property Name</TableCell>
										<TableCell className={`${classes.thead} ${classes.smallHead}`} align="right">Release Opportunity</TableCell>
										<TableCell className={`${classes.thead} ${classes.smallHead} ${classes.blue}`} align="left">Reserve Opportunity</TableCell>
									</TableHead>
									<TableBody>
										{accountData["properties"].map((row, index) => (
											<TableRow key={`${row.organizationName}-${index}`}>
												<TableCell className={classes.cell} align="left">{row.organizationName}</TableCell>
												<TableCell align={'center'} colSpan={2} className={classes.cell}>
													{isPropertyAllowedToReserve(row, modalOpportunity) ? <SwitchLovely
														defaultChecked={
															modalOpportunity.type !== "Supplemental" ?
																selectedOpportunities
																	.filter(soUuid => {
																		return soUuid.indexOf(modalOpportunity.id) > -1 && soUuid.indexOf(row.uuid) > -1
																	})
																	.length > 0
																:
																modalOpportunity.latestReservedOpportunity
																	.filter(opt => {
																		return opt.properties.propertyUuid === row.uuid
																	})
																	.length > 0
														}
														onChange={(e) => toggleReservation(e, modalOpportunity.id, row.uuid)}
													/> : (
														<Typography className={classes.disallowed}>Not Available for Selection</Typography>
													)}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
							{modalOpportunity.type !== "Supplemental" && (
								<>
									<Button size="large" style={{ margin: "20px" }} variant="outlined" color="primary" onClick={() => closeModal()}>
										Close
									</Button>
									<Button size="large" style={{ margin: "20px" }} variant="outlined" color="primary" onClick={() => submitChanges()}>
										Save
									</Button>
								</>
							)}
						</Grid>
					</Container>
					{modalOpportunity.type === "Supplemental" && (
						<Grid item align="center" xs={12}>
							<Button size="large" style={{ margin: "20px" }} variant="outlined" color="primary" onClick={() => closeModal()}>
								Close
							</Button>
							<Button size="large" style={{ margin: "20px" }} variant="outlined" color="primary" onClick={() => submitChanges()}>
								Save
							</Button>
						</Grid>
					)}
				</>)
			}
		</>
	)
}

export default OpportunitySelectPaper;