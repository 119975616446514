import React from "react";

import BooleanSelector from "../../common/BooleanSelector";
import Selector from "../../common/Selector";
import { Typography } from "@mui/material";


const BedTaxCollectorEditForm = ({ formData, handleChange, disableInputs = false }) => {
	return (
		<>
			<Selector
				options={[
					{ value: "south-walton", label: "South Walton" },
					{ value: "f-ds-p", label: "Authentic Walton" },
				]}
				required
				handleChange={handleChange}
				defaultValue={"south-walton"}
				value={formData["bedTaxLocation"] || "south-walton"}
				labelText="Where are you located?"
				id={`bedTaxLocation`}
				isDisabled={disableInputs}
			/>
			<BooleanSelector
				required
				handleChange={handleChange}
				value={formData["isBedTaxCollector"]}
				labelText="Are you a bed tax collector?"
				id={`isBedTaxCollector`}
				isDisabled={disableInputs}
			/>
			{disableInputs && (
				<Typography>
					<strong>This property has one or more opportunities reserved and the bed tax information can not be changed at this time. <br />
						If you need to make changes, please release the opportunities first.</strong>
				</Typography>
			)}
			{formData["isBedTaxCollector"]
				? (
					<>
						<BooleanSelector
							required
							handleChange={handleChange}
							value={formData["isMeetingFacility"]}
							labelText="Are you a meeting facility?"
							id={`isMeetingFacility`}
							isDisabled={disableInputs}
						/>
						<BooleanSelector
							required
							handleChange={handleChange}
							value={formData["isWeddingFacility"]}
							labelText="Are you a wedding facility?"
							id={`isWeddingFacility`}
							isDisabled={disableInputs}
						/>
					</>
				)
				: null
			}
		</>
	)
}

export default BedTaxCollectorEditForm;