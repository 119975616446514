import React, { useEffect, useContext, useState } from "react";

import Alert from '@mui/material/Alert';
import Container from "@mui/material/Container";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { useHistory } from 'react-router-dom';

import { OpportunitiesContext } from "../OpportunitiesContext";
import { AccountContext } from "../AccountContext";

import {
	// COOP_OPPORTUNITY_SALESKIT_LINK,
	// OPPORTUNITY_YEAR,
	OPPORTUNITY_RESULTS_PER_PAGE
} from "../constants/constants";

import AvailableOpportunities from "../components/opportunities/AvailableOpportunities";
import OpportunityFilters from "../components/opportunities/OpportunityFilters";
import OpportunityDetailPaper from "../components/opportunities/OpportunityDetailPaper";
import Modal from "../components/common/Modal";

import useModal from "../hooks/useModal";
import useOpportunityFilters from "../hooks/useOpportunityFilters";
import PaginationController from "../components/common/PaginationController";
import OpportunitySelectPaper from "../components/opportunities/OpportunitySelectPaper";

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(2),
		},
		// flexGrow: 1,
		marginTop: theme.spacing(35),
		minHeight: "100vh",
	},
	filterRow: {
		margin: theme.spacing(2),
		'& > *:not(:last-of-type)': {
			paddingRight: "1rem"
		},
	}
}));



const SuppOpportunities = () => {
	const { accountData } = useContext(AccountContext);

	const [page, setPage] = useState(1);

	const {
		opportunities,
		handleSelect,
		selectIsDisabled,
		handleRemove,
		isLoading,
		// hasError,
		closeErrorMessage,
		errorMessage
	} = useContext(OpportunitiesContext);

	const handlePageChange = (event, value) => {
		// Scroll to top
		// window.scrollTo({ top: 0, behavior: 'smooth' });
		setPage(value);
	};

	const {
		organizedOpportunities,
		sortBy,
		handleSortChange,
		filters,
		handleFilterChange,
	} = useOpportunityFilters(opportunities, handlePageChange, "Supplemental", accountData);

	const classes = useStyles();
	const { showModal, closeModal, openModal } = useModal();

	const { showSuppModal, closeSuppModal, openSuppModal } = useModal();

	const [modalOpportunity, setModalOpportunity] = useState({});

	const onDetailsClick = (opportunity) => {
		setModalOpportunity(opportunity);
		openModal();
	}

	const onManageClick = (opportunity) => {
		setModalOpportunity(opportunity);
		openSuppModal();
	}

	const [pendingReservations, setPendingReservations] = useState({});

	const toggleReservation = (e, opportunity_id, propertyUuid) => {
		let tmp = {}
		tmp[`${opportunity_id}_${propertyUuid}`] = e.target.checked
		setPendingReservations({ ...pendingReservations, ...tmp })
	}

	const closePropModal = () => {
		setPendingReservations({})
		closeSuppModal();
	}

	const history = useHistory();

	const { properties } = accountData;
	const isMultiProperty = properties.length > 1;

	const onSelector = (opportunity, propertyId, finalize) => {
		if (finalize) {
			history.push(`/finalize/${opportunity}_${propertyId}`)
		} else {
			if (isMultiProperty) {
				const opp = opportunities.filter(o => o.id === opportunity)[0];
				onManageClick(opp);
			} else {
				handleSelect(opportunity, propertyId)
					.then(res => {
						if (res && res.type === "Supplemental") {
							onManageClick(res);
						}
					})
			}
		}
	}

	useEffect(() => { }, [opportunities])

	return (
		<Container maxWidth="lg" className={classes.root}>
			<Typography variant="h4">Search Supplemental Co-Op Opportunities</Typography>
			<Typography>
				Viewable below are all available opportunities. Each opportunity is reserved for 72 hours. To complete the reservation, please provide associated assets.
			</Typography>
			<Grid
				container
				item
				direction="row"
				className={classes.filterRow}
			>
				<OpportunityFilters
					filters={filters}
					handleFilterChange={handleFilterChange}
					sortBy={sortBy}
					handleSortChange={handleSortChange}
					type={"Supplemental"}
				/>
			</Grid>

			<Grid container item direction="row">
				{errorMessage && <Alert severity="error">{errorMessage} <a style={{ cursor: "pointer" }} onClick={closeErrorMessage}><FontAwesomeIcon icon={faTimesCircle} /></a></Alert>}
				{organizedOpportunities.length === 0
					? isLoading && <CircularProgress />
					: (
						<>
							<AvailableOpportunities
								opportunities={organizedOpportunities}
								selectedOpportunities={accountData["opportunities"]}
								onDetailsClick={onDetailsClick}
								onManageClick={onManageClick}
								openPropModal={openSuppModal}
								showManageModal={showSuppModal}
								page={page}
							/>
							<PaginationController
								page={page}
								handleChange={handlePageChange}
								count={Math.ceil(organizedOpportunities.filter(o => !!o.availability).length / OPPORTUNITY_RESULTS_PER_PAGE)}
							/>
						</>
					)
				}
			</Grid>
			<Modal
				showModal={showModal}
				closeModal={closeModal}
				>
				<OpportunityDetailPaper
					onManageClick={onManageClick}
					closeModal={closeModal}
					opportunity={modalOpportunity}
					selected={accountData.opportunities.includes(modalOpportunity.id)}
					handleSelect={onSelector}
					handleRemove={() => handleRemove(modalOpportunity.latestReservedOpportunity ? modalOpportunity.latestReservedOpportunity.properties.uuid : modalOpportunity.properties.uuid)}
					disabled={selectIsDisabled(modalOpportunity.id)}
					isMultiProperty={isMultiProperty}
					properties={properties}
				/>
			</Modal>
			<Modal
				showModal={showSuppModal}
				closeModal={closePropModal}
				maxWidth={modalOpportunity?.type === "Supplemental" ? "xl" : "md"}
			>
				{modalOpportunity &&
					<OpportunitySelectPaper 
						toggleReservation={toggleReservation}
						modalOpportunity={modalOpportunity}
						closeModal={closePropModal}
						selectedOpportunities={accountData["opportunities"]}
						pendingReservations={pendingReservations}
					/>
				}
			</Modal>
		</Container>
	)
}

export default SuppOpportunities;