import { useState } from "react";

import {
	ADD_ACCOUNT_FETCH_URL,
	GET_ACCOUNT_FETCH_URL,
	UPDATE_ACCOUNT_FETCH_URL,
	GET_ACCOUNT_DRIVE_URL
} from "../constants/api";

import buildQueryParams from "../helpers/buildQueryParams";

const useMyAccount = ({
	getFetch,
	postFetch,
	isAuthenticated,
	setIsAuthenticated,
	isLoading,
	hasError,
	errorMessage
}) => {

	const schema = {
		"id": "",
		"googleId": "",
		"facebookId": "",
		"organizationName": "",
		"contactName": "",
		"billingAddress": "",
		"state": "FL",
		"city": "",
		"zip": "",
		"phone": "",
		"email": "",
		"isBedTaxCollector": false,
		"isMeetingFacility": false,
		"isWeddingFacility": false,
		"bedTaxLocation": false,
		"meetingSpaceContactName": "",
		"meetingSpacePhone": "",
		"meetingSpaceEmail": "",
		"weddingSpaceContactName": "",
		"weddingSpaceEmail": "",
		"weddingSpacePhone": "",
		"budget": 0,
		"driveId": "",
		"contracts": [],
		"Opportunity": [],
		"SupplementalOpportunity": [],
		"FinalizeOpportunity": [],
		"opportunities": [], // Opportunity on db: needs to be a string; comma delimited list of OpportunityID
		"released": [], // Opportunity on db: needs to be a string; comma delimited list of OpportunityID
		"reserved": [], // Opportunity on db: needs to be a string; comma delimited list of OpportunityID
		"properties": [{
			"deleted": false,
			"deleteDate": null,
			"organizationName": "",
			"contactName": "",
			"billingAddress": "",
			"state": "FL",
			"city": "",
			"zip": "",
			"phone": "",
			"email": "",
			"isBedTaxCollector": false,
			"isMeetingFacility": false,
			"isWeddingFacility": false,
			"bedTaxLocation": false,
			"uuid": Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
		}]
	}

	// NOTE: These keys correspond with the form input ids:
	const [accountData, setAccountData] = useState(schema);

	const isRegistered = (accountObj) => {
		if (
			accountObj["properties"].length > 0
			&& !!accountObj["properties"][0]["organizationName"]
			&& !!accountObj["properties"][0]["contactName"]
			&& accountObj["properties"][0]["billingAddress"]
			&& accountObj["properties"][0]["state"]
			&& accountObj["properties"][0]["city"]
			&& accountObj["properties"][0]["zip"]
			&& accountObj["properties"][0]["phone"]
			&& !!accountObj["properties"][0]["email"]
			&& !!accountObj["properties"][0]["bedTaxLocation"]
			&& accountObj["budget"] > 0
		) {
			return true;
		} else {
			return false;
		}
	}

	// Look through here to try to minimize the impact of account property updates
	const updateAccount = (newDataObj) => {
		const newData = { ...newDataObj }
		if (Object.keys(newData).includes("opportunities")) {
			// Remove array property
			delete newData.opportunities
		}
		if (Object.keys(newData).includes("SupplementalOpportunity")) {
			delete newData.SupplementalOpportunity
		}
		if (Object.keys(newData).includes("Opportunity")) {
			delete newData.Opportunity
		}
		if (Object.keys(newData).includes("releasedOpportunities")) {
			delete newData.releasedOpportunities
		}
		newData["id"] = accountData["id"] ? accountData["id"] : newData["id"]
		if (newData.hasOwnProperty("properties")) { 
			// add uuid for each property
			newData["properties"] = newData["properties"].map(property => {
				if (!!property.bedTaxLocation === false) property.bedTaxLocation = "south-walton"
				if (!property.hasOwnProperty("uuid")) {
					property["uuid"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
				}
				if (!property.hasOwnProperty("deleted")) property["deleted"] = false
				return property
			})
			newData["properties"] = JSON.stringify(newData["properties"])
		}
		const queryParams = buildQueryParams(newData);
		return postFetch(`${UPDATE_ACCOUNT_FETCH_URL}/?${queryParams}`, newData)
			.then(response => {
				if (response) {
					let newAccountData = { ...response.data[0] }
					const newOpportunities = [...newAccountData["Opportunity"].filter(opt => !opt.archived).map(o => `${o.properties.uuid}_${o.properties.propertyUuid}`), ...newAccountData.SupplementalOpportunity.filter(op => op.properties.status === "pending").map(o => `${o.properties.uuid}_${o.properties.propertyUuid}`)]
					if (!newAccountData.driveId) {
						const driveUrl = `${GET_ACCOUNT_DRIVE_URL}/?id=${newAccountData.id}&_join=true`;
						getFetch(driveUrl)
							.then(response => {
								if (!response.errorCode) {
									// They're logging in
									if (response.data.length > 0) {
										newAccountData = response.data[0];
									}
								}
							})
					}
					newAccountData.FinalizeOpportunity = newAccountData.SupplementalOpportunity.filter(op => op.properties.status === "finalized")
					newAccountData.SupplementalOpportunity = newAccountData.SupplementalOpportunity.filter(op => op.properties.status === "pending")
					newAccountData["opportunities"] = newOpportunities;
					if (!Array.isArray(newAccountData.properties))newAccountData.properties = [];
					newAccountData.properties = newAccountData.properties.filter(item => item.deleted !== true);
					// delete newAccountData.Opportunity;
					setAccountData(newAccountData);
					return getAccount(newData["id"])
				}
			})
	}

	const addAccount = (response) => {
		const newAccount = {};

		// Google
		if (response.googleId) {
			newAccount["contactName"] = response.name;
			newAccount["email"] = response.email;
			newAccount["googleId"] = `g${response.googleId}`;
			// Facebook
		} else {
			newAccount["name"] = response.name;
			newAccount["email"] = response.email ? response.email : "";
			newAccount["facebookId"] = response.userID;
		}

		const queryParams = buildQueryParams(newAccount);
		return postFetch(`${ADD_ACCOUNT_FETCH_URL}/?${queryParams}`, newAccount)
			.then(response => {
				let newAccount = response.data[0];
				newAccount["opportunities"] = [];
				if (!Array.isArray(newAccount.properties))newAccount.properties = [];
				newAccount.properties = newAccount.properties.filter(item => item.deleted !== true);
				if (newAccount["properties"].length === 0) {
					newAccount["properties"] = [{
						organizationName: newAccount["organizationName"],
						contactName: newAccount["contactName"],
						email: newAccount["email"],
						phone: newAccount["phone"],
						billingAddress: newAccount["billingAddress"],
						city: newAccount["city"],
						state: newAccount["state"],
						zip: newAccount["zip"],
						isBedTaxCollector: newAccount["isBedTaxCollector"] ? newAccount["isBedTaxCollector"] : false,
						isMeetingFacility: newAccount["isMeetingFacility"],
						isWeddingFacility: newAccount["isWeddingFacility"],
						bedTaxLocation: newAccount["bedTaxLocation"] ? newAccount["bedTaxLocation"] : "south-walton",
						deleted: false,
					}]
				}
				if (!newAccount.driveId) {
					const driveUrl = `${GET_ACCOUNT_DRIVE_URL}/?id=${newAccount.id}&_join=true`;
					getFetch(driveUrl)
						.then(response => {
							if (!response.errorCode) {
								// They're logging in
								if (response.data.length > 0) {
									newAccount["driveId"] = response.data[0].driveId;
								}
							}
						})
				}
				setAccountData(newAccount);
				return newAccount
			})
	}

	const getOrCreateAccount = (authResponse) => {
		let url = authResponse.googleId ? `${GET_ACCOUNT_FETCH_URL}/?googleId=g${authResponse.googleId}&_join=true` : `${GET_ACCOUNT_FETCH_URL}/?facebookId=${authResponse.userID}&_join=true`
		return getFetch(url)
			.then(zendevResponse => {
				if (!zendevResponse.errorCode) {
					// They're logging in
					if (zendevResponse.data.length > 0) {
						let account = zendevResponse.data[0];
						if (!account.driveId) {
							const driveUrl = `${GET_ACCOUNT_DRIVE_URL}/?id=${account.id}&_join=true`;
							getFetch(driveUrl)
								.then(response => {
									if (!response.errorCode) {
										// They're logging in
										if (response.data.length > 0) {
											account.driveId = response.data[0].driveId;
										}
									}
								})
						}
						account.FinalizeOpportunity = account.SupplementalOpportunity.filter(op => op.properties.status === "finalized")
						account.SupplementalOpportunity = account.SupplementalOpportunity.filter(op => op.properties.status === "pending")
						account["opportunities"] = account.Opportunity.map(o => `${o.properties.uuid}_${o.properties.propertyUuid}`)
						if (!Array.isArray(account.properties))account.properties = [];
						account.properties = account.properties.filter(item => item.deleted !== true);
						if (account["properties"].length === 0) {
							account["properties"] = [{
								organizationName: account["organizationName"],
								contactName: account["contactName"],
								email: account["email"],
								phone: account["phone"],
								billingAddress: account["billingAddress"],
								city: account["city"],
								state: account["state"],
								zip: account["zip"],
								isBedTaxCollector: account["isBedTaxCollector"] ? account["isBedTaxCollector"] : false,
								isMeetingFacility: account["isMeetingFacility"],
								isWeddingFacility: account["isWeddingFacility"],
								bedTaxLocation: account["bedTaxLocation"] ? account["bedTaxLocation"] : "south-walton",
								deleted: false,
							}]
							account = updateAccount(account)
						}
						setAccountData(account);
						return account;
						// They're signing up
					} else {
						return addAccount(authResponse);
					}
				}
			})
	}

	const getAccount = (id) => {
		const url = `${GET_ACCOUNT_FETCH_URL}/?id=${id}&_join=true`;
		return getFetch(url)
			.then(response => {
				if (!response.errorCode) {
					// They're logging in
					if (response.data.length > 0) {
						let account = response.data[0];
						if (!account.driveId) {
							const driveUrl = `${GET_ACCOUNT_DRIVE_URL}/?id=${id}&_join=true`;
							getFetch(driveUrl)
								.then(response => {
									if (!response.errorCode) {
										// They're logging in
										if (response.data.length > 0) {
											account.driveId = response.data[0].driveId;
										}
									}
								})
						}
						account.FinalizeOpportunity = account.SupplementalOpportunity.filter(op => op.properties.status === "finalized")
						account.SupplementalOpportunity = account.SupplementalOpportunity.filter(op => op.properties.status === "pending")
						account["opportunities"] = account.Opportunity.map(o => `${o.properties.uuid}_${o.properties.propertyUuid}`)
						if (!Array.isArray(account.properties))account.properties = [];
						account.properties = account.properties.filter(item => item.deleted !== true);
						if (account["properties"].length === 0) {
							account["properties"] = [{
								organizationName: account["organizationName"],
								contactName: account["contactName"],
								email: account["email"],
								phone: account["phone"],
								billingAddress: account["billingAddress"],
								city: account["city"],
								state: account["state"],
								zip: account["zip"],
								isBedTaxCollector: account["isBedTaxCollector"] ? account["isBedTaxCollector"] : false,
								isMeetingFacility: account["isMeetingFacility"],
								isWeddingFacility: account["isWeddingFacility"],
								bedTaxLocation: account["bedTaxLocation"] ? account["bedTaxLocation"] : "south-walton",
								deleted: false,
							}]
							account = updateAccount(account)
						}
						setAccountData(account);
						return account;
						// They're signing up
					}
				}
			})
	}

	return {
		accountData,
		updateAccount,
		getAccount,
		getOrCreateAccount,
		isRegistered,
		isAuthenticated,
		setIsAuthenticated,
	}
}

export default useMyAccount;