import { useState } from 'react';

let headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");

const requestOptions = {
  headers: headers,
  redirect: 'follow'
};

const useApi = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const handleErrorMessage = (response) => {
		let message;
		if (response.statusText && response.status) {
			message = `${response.status}: ${response.statusText}`
		} else if (response.message) {
			message = response.message
		} else if (response.status) {
			message = `${response.status} Response`
		} else {
			message = "Failed to fetch"
		}
		response.json()
			.then(result => {
				if (result.errorCode) setErrorMessage(result.errorMessage);
			})
		setIsLoading(false);
		setHasError(true);
		setErrorMessage(message);
	}

	const closeErrorMessage = () => {
		setHasError(false);
		setErrorMessage("");
	}

	const getFetch = (url) => {
		setIsLoading(true);
		return fetch(url, requestOptions)
			.then((response) => {
				setIsLoading(false);
				if (response.ok) {
					return response.json()
				} else if (response.documentation_url) {
					handleErrorMessage(response)
				} else {
					handleErrorMessage(response)
				}
			}).catch(() => {
				handleErrorMessage({statusText: "Failed to fetch"});
			}).finally(() => setIsLoading(false))
	}

	const postFetch = (url, dataToPost) => {
		setIsLoading(true);
		setHasError(false);
		setErrorMessage("");
		return fetch(url, {
			...requestOptions,
			method: "POST",
			body: JSON.stringify(dataToPost)
		}).then((response) => {
			if (response.ok) {
				return response.json()
			} else if (response.documentation_url) {
				handleErrorMessage(response)
			} else {
				handleErrorMessage(response)
			}
		}).catch(() => {
			handleErrorMessage({statusText: "Failed to fetch"});
		}).finally(() => setIsLoading(false))
	}

  return {
	  getFetch,
	  postFetch,
	  closeErrorMessage,
	  isLoading,
	  hasError,
	  errorMessage
	};
};

export default useApi;