import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  question: {
    marginBottom: 0,
  },
}));

const SimpleAccordion = ({items}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        {items.map((item, index) => (
            <Accordion key={index}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${index}-content`}
                    id={`panel-${index}-header`}
                >
                    <Typography variant="h6" className={classes.question}>
                      {item.question}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {item.answer}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        ))}
    </div>
  );
}

export default SimpleAccordion;