import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { TableHead } from '@mui/material';

const useStyles = makeStyles({
	table: {
		width: "100%",
	},
	thead: {
		borderBottomColor: "#056791",
	},
	actionLink: {
		fontWeight: 600,
		fontSize: "14px",
		lineHeight: "48px",
		textTransform: "uppercase",
		textDecoration: "none",
		color: "#0071BC",
		'&:hover': {
			textDecoration: "underline",
		}
	}
});

const AccountDetailsTable = ({ rows, openProperty }) => {
	const classes = useStyles();

	return (
		<TableContainer >
			<Table aria-label="simple table" className={classes.table}>
				<TableHead scope="row">
					<TableCell className={classes.thead} align="left">Property Name</TableCell>
					<TableCell className={classes.thead} align="left">&nbsp;</TableCell>
					<TableCell className={classes.thead} align="left">&nbsp;</TableCell>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row.label}>
							<TableCell align="left">{row.value}</TableCell>
							{row.isProperty && <TableCell align="left"><Button size="small" className={classes.actionLink} href="#">Edit Property Info</Button></TableCell>}
							{row.isProperty && <TableCell align="left"><Button size="small" className={classes.actionLink} href="#">Delete Property</Button></TableCell>}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default AccountDetailsTable;