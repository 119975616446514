import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle, faFire } from "@fortawesome/free-solid-svg-icons";
import circleStar from "../../icons/circleStar.svg"

import { LIMITED_OPPORTUNITY_COUNT } from "../../constants/constants";
import { faClock } from '@fortawesome/free-regular-svg-icons';

import isRecentlyReleased from "../../helpers/isRecentlyReleased";

const getColorPrimitives = (color) => {
    switch (color) {
        case "red":
            return {
                backgroundColor: "#F0DFDF",
                color: "#AC0F0F"
            }
        case "green":
            return {
                backgroundColor: "#DFF0E1",
                color: "#2C8234"
            }
        case "blue":
            return {
                backgroundColor: "#D7EAED",
                color: "#0098AE",
            }
        case "yellow":
            return {
                backgroundColor: "#FFE9CC",
                color: "#57270B",
            }
        default:
            return;
    }
}

const useStyles = color => makeStyles(theme => ({
	avatar: {
        ...getColorPrimitives(color),
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
	headerRoot: {
        ...getColorPrimitives(color),
	},
	headerText: {
		...getColorPrimitives(color),
		marginBottom: 0,
		fontWeight: "bold",
	},
}));

const OpportunityCardHeader = ({opportunity, selected, disabled}) => {
    const isLimited = opportunity.availability <= LIMITED_OPPORTUNITY_COUNT;

	// let estimatedRunDate, hoursTil;

	// if (opportunity.type === "Supplemental") {
	// 	estimatedRunDate = opportunity.properties.estimatedRunDate
	// 	hoursTil = Math.round((Math.abs(new Date().getTime() - new Date(opportunity.properties.expireDate))) / 36e5)
	// }

    const recentlyReleased = isRecentlyReleased(opportunity.modified);

    const getColor = () => {
        if (opportunity.latestReservedOpportunity.length !== 0 || selected) {
			return "yellow";
        } else if (isLimited) {
            return "red";
        } else if (recentlyReleased) {
            return "blue";
        } else {
            return "green";
        }
    }

    const classes = useStyles(getColor())();

    const getIcon = () => {
        if (selected) {
            return <FontAwesomeIcon icon={faCheckCircle}/>;
        } else if (isLimited || disabled) {
            return <FontAwesomeIcon icon={faClock}/>;;
        } else if (recentlyReleased) {
            return (
                <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faCircle} size="lg"/>
                    <FontAwesomeIcon icon={faFire} color="#D7EAED" size="sm"/>
                </span>
            )
        } else {
            return <img style={{ width: "1.25em" }} src={circleStar} alt="Circle With Star Icon" />
        }
    }

    const getTitle = () => {
		if (opportunity.type === "Supplemental") {
			if (opportunity.latestReservedOpportunity.length === 1) {
				let hoursTil = Math.round((Math.abs(new Date().getTime() - new Date(opportunity.latestReservedOpportunity[0].properties.expireDate))) / 36e5)
				return `Opportunity Reserved ${hoursTil && (`| ${hoursTil} Hours remaining to sign contract`)}`
			} else if (opportunity.latestReservedOpportunity.length > 1) {
				return "Reversed For Multiple Properties"
			}
			return "Supplemental Opportunity"
		}
        if (selected) {
            return "Opportunity Reserved";
        } else if (isLimited) {
            return `Limited Availability Warning: ${opportunity.availability} Remaining Opening`;
        } else if (recentlyReleased) {
            return "Recently Released Opportunity";
        } else {
            return "Remaining Available Opportunity";
        }
    }

	if (opportunity.type === "Supplemental" && opportunity.latestReservedOpportunity === undefined) return null;

	return (
		<CardHeader
			avatar={
				<Avatar aria-label="recipe" className={classes.avatar}>
					{getIcon()}
				</Avatar>
			}
			classes={{root: classes.headerRoot}}
			title={getTitle().toUpperCase()}
			titleTypographyProps={{variant:"body1", className: classes.headerText}}
		/>
	)
}

export default OpportunityCardHeader;