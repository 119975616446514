import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import { FormControl, InputLabel, MenuItem, Select  } from "@mui/material";


const useStyles = makeStyles((theme) => ({
	select: {
		display: "flex",
	}, 
	label: {
		paddingLeft: "0.5rem",
		paddingRight: "0.5rem",
		backgroundColor: "white",
	}
}));

const Selector = ({
	handleChange, 
	options, 
	labelText, 
	id,
	value,
	defaultValue,
	disablePleaseSelect,
	isDisabled = false
}) => {
	const classes = useStyles();

	return (
		<FormControl 
			variant="outlined" 
			fullWidth
		>
			<InputLabel
				shrink
				className={classes.label} 
				id={`${id}-label`}
			>
				{labelText}
			</InputLabel>
			<Select
				labelId={`${id}-label`}
				onChange={handleChange}
				displayEmpty
				label={labelText}
				value={value !== null ? value : (defaultValue !== undefined ? defaultValue : null)}
				inputProps={{
					name: id,
					className: classes.select
				}}
				disabled={isDisabled}
			>
				{!disablePleaseSelect && (
					<MenuItem disabled={true} value={null}>
						<em>Please Select</em>
					</MenuItem>
				)}
				{options.map((o, index) => (
					<MenuItem 
						key={index} 
						value={o.value}
					>
						{o.label}
					</MenuItem>
				))}	
			</Select>
		</FormControl>
	)
}

export default Selector