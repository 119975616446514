import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { URLS } from '../../constants/urls';
import { timeHasElapsed } from '../../constants/deadlines';


const SignInOrUp = ({ isAuthenticated }) => {
	const location = useLocation();

	return (
		<Box display="flex" alignItems="center" height="50%">
			{!isAuthenticated ?
				(
					<>
						<Button
							color="inherit"
							component={RouterLink}
							to={URLS.login}
						>
							Sign In
						</Button>
						{/* Hide sign up button while signing up */}
						{location.pathname !== URLS.signup
							&& (
								<Button
									variant="outlined"
									color="inherit"
									component={RouterLink}
									to="/signup"
								>
									Sign Up
								</Button>
							)
						}
					</>
				) : (
					<>
						<Button
							variant="outlined"
							color="inherit"
							component={RouterLink}
							to={timeHasElapsed ? URLS.opportunities : URLS.suppOpportunities}
						>
							My Account
						</Button>
						<Button
							color="inherit"
							component={RouterLink}
							to={URLS.logout}
						>
							Log Out
						</Button>
					</>
				)}
		</Box>
	)
}

export default SignInOrUp;