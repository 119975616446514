import React, { useState, useMemo, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { theme } from './constants/SiteTheme';

import Routes from "./components/routes/Routes";

import useMyAccount from "./hooks/useMyAccount";
import useApi from "./hooks/useApi";

import { AccountContext } from "./AccountContext";
import { ApiContext } from "./ApiContext";
import { OpportunitiesContext } from "./OpportunitiesContext";
import useOpportunities from "./hooks/useOpportunities";


export default function App() {
  // console.log(theme)
  // NOTE: Temporary
  const [ isAuthenticated, setIsAuthenticated ] = useState(localStorage.getItem("userId"));

  const {
    getFetch,
    postFetch,
    isLoading,
    hasError,
	closeErrorMessage,
    errorMessage
  } = useApi();

  const {
    accountData,
    updateAccount,
    getAccount,
    getOrCreateAccount,
    isRegistered
  } = useMyAccount({
    isAuthenticated,
    setIsAuthenticated,
    getFetch,
    postFetch,
    isLoading,
    hasError,
	closeErrorMessage,
    errorMessage
  });

  const {
    opportunities,
    categories,
    suppCategories,
    getOpportunities,
    setOpportunities,
	finalize,
    handleSelect,
	handleBulk,
    selectIsDisabled,
    handleRemove,
    budgetState,
		handleBudgetChange,
		saveBudget,
  } = useOpportunities({
    accountData,
    updateAccount,
	getAccount,
    getFetch,
    postFetch,
    isLoading,
    hasError,
	closeErrorMessage,
    errorMessage
  });

  const accountContextValue = useMemo(() => ({
    accountData,
    updateAccount,
    getAccount,
    getOrCreateAccount,
    isRegistered,
    isAuthenticated,
    setIsAuthenticated
  }), [
    accountData,
    updateAccount,
    getAccount,
    getOrCreateAccount,
    isRegistered,
    isAuthenticated,
    setIsAuthenticated
  ])

  const apiContextValue = useMemo(() => ({
    getFetch,
    postFetch,
    isLoading,
    hasError,
	closeErrorMessage,
    errorMessage
  }), [
    getFetch,
    postFetch,
    isLoading,
    hasError,
	closeErrorMessage,
    errorMessage
  ])

  const opportunitiesContextValue = useMemo(() => ({
    opportunities,
    categories,
	suppCategories,
    getOpportunities,
    setOpportunities,
    handleSelect,
	handleBulk,
    selectIsDisabled,
    handleRemove,
    budgetState,
    finalize,
		handleBudgetChange,
		saveBudget,
    isLoading,
    hasError,
	closeErrorMessage,
    errorMessage
  }), [
    opportunities,
    categories,
	suppCategories,
    getOpportunities,
    setOpportunities,
    handleSelect,
	handleBulk,
    selectIsDisabled,
    handleRemove,
    budgetState,
    finalize,
		handleBudgetChange,
		saveBudget,
    isLoading,
    hasError,
	closeErrorMessage,
    errorMessage
  ])

  useEffect(() => {
    if (isAuthenticated) {
      getAccount(localStorage.getItem("userId"));
      getOpportunities();
    };
  }, [])

  return (
    <AccountContext.Provider value={accountContextValue}>
      <ApiContext.Provider value={apiContextValue}>
        <OpportunitiesContext.Provider value={opportunitiesContextValue}>
          <Router>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <Routes
                  accountData={accountContextValue}
                  isAuthenticated={isAuthenticated}
                  setIsAuthenticated={setIsAuthenticated}
                />
              </ThemeProvider>
            </StyledEngineProvider>
          </Router>
        </OpportunitiesContext.Provider>
      </ApiContext.Provider>
    </AccountContext.Provider>
  );
}