import { useEffect, useState } from "react";
import {
	ALL_OPPORTUNITIES_FETCH_URL,
	ALL_CATEGORIES_FETCH_URL,
} from "../constants/api.js";
import { timeHasElapsed } from "../constants/deadlines";


const useOpportunities = ({
	accountData,
	updateAccount,
	getFetch,
	isLoading,
	hasError,
	errorMessage
}) => {
	const [opportunities, setOpportunities] = useState([]);
	const [categories, setCategories] = useState([]);
	const [suppCategories, setSuppCategories] = useState([]);

	const [budgetState, setBudgetState] = useState({
		budget: 0,
		used: 0,
		remaining: 0,
	});

	const handleBudgetChange = (e) => {
		setBudgetState({ ...budgetState, budget: e.target.value });
	}

	const saveBudget = () => {
		updateAccount({ budget: budgetState['budget'] })
	}

	const calculateUsedBudget = () => {
		const selectedOpportunities = [...accountData["Opportunity"], ...accountData["SupplementalOpportunity"]]
		let total = 0;
		if (Array.isArray(selectedOpportunities) && selectedOpportunities.length > 0) selectedOpportunities.forEach(o => total += o.cost);
		return total;
	}

	const finalize = (opportunityId) => {
		const parsed = opportunityId.split("_")
		const parsedId = parsed[0]
		const opportunity = opportunities.find(o => o.id === parsedId);
		return updateAccount({ finalize: opportunityId })
			.then(res => {
				getOpportunities(res["SupplementalOpportunity"], res["FinalizeOpportunity"])
				const suppOp = res["FinalizeOpportunity"].filter(opt => opt.id === opportunityId)[0]
				if (opportunity.type === "Supplemental" && suppOp) {
					suppOp.Category = opportunity.Category
					return suppOp
				}
			})
	}

	const handleBulk = (bulkChangeObj) => {
		const reserved = {}
		const released = {}
		const allReservedOps = [...accountData["opportunities"], ...accountData["SupplementalOpportunity"].filter(op => op.properties.status === "pending").map(o => `${o.properties.uuid}_${o.properties.propertyUuid}`)]
		Object.keys(bulkChangeObj).forEach((k) => {
			const [optId, propId] = k.split('_')
			const isAlreadyReserved = allReservedOps.some(op => (
				op.indexOf(optId) !== -1 && op.indexOf(propId) !== -1
			))
			if (bulkChangeObj[k]) {
				if (!isAlreadyReserved) reserved[k] = bulkChangeObj[k]
			} else {
				if (isAlreadyReserved) released[k] = bulkChangeObj[k]
			}
		})
		if (Object.keys(reserved).length === 0 && Object.keys(released).length === 0) return Promise.resolve()
		return updateAccount({ reserved: JSON.stringify(reserved), released: JSON.stringify(released), propertyUuid: null, bulk: true })
			.then(res => {
				getOpportunities(res["SupplementalOpportunity"], res["FinalizeOpportunity"])
			})
	}

	const handleSelect = (opportunityId, propertyUuid) => {
		let opportunity = opportunities.find(o => o.id === opportunityId);
		return updateAccount({ reserved: opportunityId, propertyUuid })
			.then(res => {
				getOpportunities(res["SupplementalOpportunity"], res["FinalizeOpportunity"])
				const suppOp = res["SupplementalOpportunity"].filter(opt => opt.id === opportunityId)[0]
				if (opportunity.type === "Supplemental") {
					suppOp.Category = opportunity.Category
					return suppOp
				}
				if (!localStorage.getItem("z_alerted")) {
					alert("Opportunity has been reserved. Check the My Account section for more information regarding opportunities reserved. For your convenience, you will only receive this message once.")
					localStorage.setItem("z_alerted", "true")
				}
			})
	}

	const selectIsDisabled = (opportunityId) => {
		const lockOutDuration = (72 * 60 * 60 * 1000) // 72 hours
		if (opportunityId) {
			const opportunity = opportunities.find(o => o.id === opportunityId);
			if (!opportunity) return true;
			let expired = false;
			let lockOut = false;
			const overBudget = budgetState.remaining < opportunity.cost;
			if (opportunity.type === "Supplemental") {
				const now = new Date().getTime()
				if (opportunity.latestReservedOpportunity.length !== 0) {
					const opId = opportunity.latestReservedOpportunity[opportunity.latestReservedOpportunity.length - 1].properties.uuid
					const parsed = opId.split("_")
					const avaliable = new Date(Number(parsed[1]) + lockOutDuration).getTime()
					if (avaliable >= now) expired = true;
				}
				if (opportunity.latestFinalizeOpportunity) {
					const expireDate = opportunity.latestFinalizeOpportunity.properties.expireDate
					if (new Date(expireDate).getTime() >= now) lockOut = true
				}
				return overBudget || expired || lockOut;
			}
			return timeHasElapsed() || overBudget;
		}
		return true;
	}

	const handleRemove = (opportunityId, propertyUuid) => {
		return updateAccount({ released: opportunityId, propertyUuid })
			.then(res => {
				getOpportunities(res["SupplementalOpportunity"], res["FinalizeOpportunity"])
			})
	}

	const filterOnlineBannerPackages = (ops) => {
		// EG: Filters out online-banner-package-2-winter from opportunities
		// if online-banner-package-1-winter is already selected
		const onlineBannerPackages = ops.filter(o => o.permalink.includes("online-banner-package"));
		const selectedOnlineBannerPackages = onlineBannerPackages.filter(o => accountData["opportunities"].includes(o.id));

		if (selectedOnlineBannerPackages.length > 0) {
			let filteredOps = [...ops];
			const seasonsAndNumbersSelected = selectedOnlineBannerPackages.map(o => {
				const split = o.permalink.split("-")
				let packageNumber = split[split.length - 2]
				let season = split[split.length - 1]
				if (o.type === "Supplemental") {
					packageNumber = split[split.length - 1]
					season = null
				}
				//eg. { season: "winter", packageNumber: 1 }
				return { season: season, packageNumber: packageNumber, type: o.type };
			})

			// Filter out all the packages by matching seasons
			seasonsAndNumbersSelected.forEach(s => {
				filteredOps = filteredOps.filter(o => {
					if (o.permalink.startsWith("online-banner-package")) {
						return !o.permalink.endsWith(s.season)
					} else {
						return true;
					}
				})
			})

			// But add back the ones the user selected
			return [...filteredOps, ...selectedOnlineBannerPackages];
		}

		return ops;
	}

	const filterOpsByAccount = (ops, latestSuppData, latestFinalizeData) => {
		let filteredOps = [...ops];

		const latestSupps = latestSuppData || accountData["SupplementalOpportunity"]

		const latestFinalize = latestFinalizeData || accountData["FinalizeOpportunity"]

		const hasLocationBTC_SW = accountData.properties.some(p => p.isBedTaxCollector && p.bedTaxLocation === 'south-walton');
		const hasLocationBTC_SW_Meetings = accountData.properties.some(p => p.isBedTaxCollector && p.bedTaxLocation === 'south-walton' && p.isMeetingFacility);
		const hasLocationBTC_SW_Weddings = accountData.properties.some(p => p.isBedTaxCollector && p.bedTaxLocation === 'south-walton' && p.isWeddingFacility);
		const hasLocationBTC_FDSP = accountData.properties.some(p => p.isBedTaxCollector && p.bedTaxLocation === 'f-ds-p');
		const hasLocationSW = accountData.properties.some(p => !p.isBedTaxCollector && p.bedTaxLocation === 'south-walton');
		const hasLocationFDSP = accountData.properties.some(p => !p.isBedTaxCollector && p.bedTaxLocation === 'f-ds-p');

		if (!hasLocationBTC_SW && !hasLocationBTC_FDSP && !hasLocationSW && hasLocationFDSP) {
			filteredOps = []
		}

		if (!hasLocationSW && !hasLocationBTC_SW) {
			filteredOps = filteredOps
				.filter(o =>
					!o.Category[0].object.permalink.startsWith('online-display') &&
					!o.Category[0].object.permalink.startsWith('facebook-instagram') &&
					!o.Category[0].object.permalink.startsWith('vrbo-banner') &&
					!o.Category[0].object.permalink.startsWith('tripadvisor') &&
					!o.Category[0].object.permalink.startsWith('connected-tv') &&
					!o.Category[0].object.permalink.startsWith('meetings') &&
					!o.Category[0].object.permalink.startsWith('weddings') &&
					o.type !== "Supplemental"
				)
		}

		if (!hasLocationBTC_SW) {
			filteredOps = filteredOps
				.filter(o =>
					!o.Category[0].object.permalink.startsWith('leisure-print')
				)
		}

		if (!hasLocationBTC_SW_Meetings) {
			filteredOps = filteredOps
				.filter(o =>
					!o.Category[0].object.permalink.startsWith('meetings')
				)
		}

		if (!hasLocationBTC_SW_Weddings) {
			filteredOps = filteredOps
				.filter(o =>
					!o.Category[0].object.permalink.startsWith('weddings')
				)
		}

		if (!hasLocationBTC_FDSP) {
			filteredOps = filteredOps
				.filter(o =>
					!o.Category[0].object.permalink.startsWith('walton-county-print')
				)
		}

		if (accountData['opportunities'].length > 0) {
			filteredOps = filterOnlineBannerPackages(filteredOps);
		}

		if (latestSupps) {
			filteredOps.forEach(o => {
				const clones = latestSupps.filter(opt => opt.id === o.id && new Date(opt.properties.expireDate).getTime() >= new Date().getTime())
				o.latestReservedOpportunity = clones
			});
		}

		if (latestFinalize) {
			filteredOps.forEach(o => {
				const clones = latestFinalize.filter(opt => opt.id === o.id)
				let clone = clones[clones.length - 1];
				if (clone && new Date(clone.properties.expireDate).getTime() >= new Date().getTime()) o.latestFinalizeOpportunity = clone
			});
		}

		return filteredOps;
	}

	const getOpportunities = (...rest) => {
		getFetch(ALL_OPPORTUNITIES_FETCH_URL)
			.then(response => {
				if (response) {
					const filteredOps = filterOpsByAccount(response.data, rest[0], rest[1]);
					setOpportunities(filteredOps);
				}
			})
	}

	const filterCatsByAccount = (cats) => {
		let filteredCats = [...cats];

		const hasLocationBTC_SW = accountData.properties.some(p => p.isBedTaxCollector && p.bedTaxLocation === 'south-walton');
		const hasLocationBTC_SW_Meetings = accountData.properties.some(p => p.isBedTaxCollector && p.bedTaxLocation === 'south-walton' && p.isMeetingFacility);
		const hasLocationBTC_SW_Weddings = accountData.properties.some(p => p.isBedTaxCollector && p.bedTaxLocation === 'south-walton' && p.isWeddingFacility);
		const hasLocationBTC_FDSP = accountData.properties.some(p => p.isBedTaxCollector && p.bedTaxLocation === 'f-ds-p');
		const hasLocationSW = accountData.properties.some(p => !p.isBedTaxCollector && p.bedTaxLocation === 'south-walton');
		const hasLocationFDSP = accountData.properties.some(p => !p.isBedTaxCollector && p.bedTaxLocation === 'f-ds-p');

		if (!hasLocationBTC_SW && !hasLocationBTC_FDSP && !hasLocationSW && hasLocationFDSP) {
			filteredCats = []
		}

		if (!hasLocationSW && !hasLocationBTC_SW) {
			filteredCats = filteredCats
				.filter(c =>
					!c.permalink.startsWith('online-display') &&
					!c.permalink.startsWith('facebook-instagram') &&
					!c.permalink.startsWith('vrbo-banner') &&
					!c.permalink.startsWith('tripadvisor') &&
					!c.permalink.startsWith('connected-tv') &&
					!c.permalink.startsWith('meetings') &&
					!c.permalink.startsWith('weddings')
				)
		}

		if (!hasLocationBTC_SW) {
			filteredCats = filteredCats
				.filter(c =>
					!c.permalink.startsWith('leisure-print') &&
					!c.permalink.startsWith('meetings') &&
					!c.permalink.startsWith('weddings')
				)
		}

		if (!hasLocationBTC_SW_Meetings) {
			filteredCats = filteredCats
				.filter(c =>
					!c.permalink.startsWith('meetings')
				)
		}

		if (!hasLocationBTC_SW_Weddings) {
			filteredCats = filteredCats
				.filter(c =>
					!c.permalink.startsWith('weddings')
				)
		}

		if (!hasLocationBTC_FDSP) {
			filteredCats = filteredCats
				.filter(c =>
					!c.permalink.startsWith('walton-county-print')
				)
		}
		
		return filteredCats;
	}

	const filterSuppCatsByAccount = (cats) => {
		let filteredCats = [...cats];

		const hasMeetingFacility = accountData.properties.some(p => p.isMeetingFacility);
		if (!hasMeetingFacility) {
			filteredCats = filteredCats.filter(c => !c.permalink.startsWith('meetings'))
		}

		return filteredCats;
	}

	const getCategories = () => {
		const suppOpportunities = opportunities.filter(o => o.type === "Supplemental")
		getFetch(ALL_CATEGORIES_FETCH_URL)
			.then(response => {
				if (response) {
					const filteredCats = filterCatsByAccount(response.data);
					let suppCats = suppOpportunities.map(o => o.Category[0].object)
					const filteredSuppCats = filterCatsByAccount(suppCats);
					let suppOptions = filteredSuppCats.map(cat => {
						return { value: cat.name, label: cat.name }
					})
					let options = filteredCats.map(cat => {
						return { value: cat.name, label: cat.name }
					})
						.filter(opt => opt.value !== "Hulu")
						.filter(opt => !opt.value.startsWith('Leisure - Online Banner'))
					setCategories(options)
					setSuppCategories(suppOptions)
				}
			})
	}

	useEffect(() => {
		getOpportunities(accountData.SupplementalOpportunity, accountData.FinalizeOpportunity);
		getCategories();
	}, [accountData.properties, accountData.Opportunity, accountData.SupplementalOpportunity, accountData.FinalizeOpportunity])

	useEffect(() => {
		const current = accountData["budget"];
		let used = 0;
		if (current) {
			used = calculateUsedBudget();
		}
		const remaining = current - used;

		setBudgetState({
			budget: current,
			used: used,
			remaining: remaining,
		})

	}, [accountData, opportunities])

	return {
		suppCategories,
		categories,
		opportunities,
		getOpportunities,
		setOpportunities,
		handleSelect,
		selectIsDisabled,
		handleRemove,
		handleBulk,
		finalize,
		budgetState,
		handleBudgetChange,
		saveBudget,
		isLoading,
		hasError,
		errorMessage
	}
}

export default useOpportunities;